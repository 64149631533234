import React, { useContext,useState, useEffect}  from 'react';
import Sidebar from "./components/Sidebar";
import './App.css';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  useLocation,
  useHistory,
} from "react-router-dom"

//LOGIN
import Login from './components/login_url/Login';
import Register from './components/login_url/Register';


//BUDGET
import { AppProvider } from './context/AppContext';
import Budget from './components/budget_url/Budget';
import ExpenseTotal from './components/budget_url/ExpenseTotal';
import ExpenseList from './components/budget_url/ExpenseList';
import AddExpenseForm from './components/budget_url/AddExpenseForm';
import RemainingBudget from './components/budget_url/Remaining';
import Progress from './components/budget_url/Progress'


//  STOCK
import EcoCalender from './components/stock_url/EcoCalender';
import StockMarketPrice from './components/stock_url/StockMarketPrice';
import NewsScrape from './components/stock_url/NewsScrape';


//PORTFOLIO
import Stock from './components/Research/Stock'
import StockNews from './components/research_url/StockNews'
import TradingChart from './components/research_url/TradingChart'
import Fundamentals from './components/research_url/Fundamentals'
import Analyst from './components/research_url/Analyst'
import Calender from './components/research_url/Calender'
import Comparison from './components/research_url/Comparison'
import KeyStats from './components/research_url/KeyStats'
import Profile from './components/research_url/Profile'
import InvestmentList from './components/research_url/InvestmentList';
import StockSearchBar from './components/research_url/StockSearchBar'
import BuyStock from './components/research_url/BuyStock'
import SellStock from './components/research_url/SellStock'

//PORTFOLIO
import Portfolio from './components/Portfolio/Portfolio';
import Position from './components/portfolio_url/Position'
import MarketNews from './components/portfolio_url/MarketNews';


//EDUCATION
import Education from './components/Education/Education';

//RESEARCH
import Research from './components/Research/Research';

//AI
import AI from './components/AI/AI';

//LANDING PAGE
import Overview from './components/landing_page/overview';

const BudgetURL = () => {
  const [, forceUpdate] = useState();

  return (
    <div className="layout">
      <div className="dashboard">
        <Sidebar/>
      </div>
      <div className="MainLayout">
        <div className = "Fund">
          <div className="Fund_Number">
            <Budget />
            <RemainingBudget />
            <ExpenseTotal />
          </div>
          <div className="Fund_Stat">
            <Progress />
          </div>
        </div>
        <div className="Expense">
          <ExpenseList />
          <AddExpenseForm/>
        </div>
      </div>
    </div>
  );
}

const StockURL = () => {
  const [, forceUpdate] = useState();

  return (
    <div className="layout">
      <div className="dashboard">
        <Sidebar/>
      </div>
      <div className="MainLayout">
        <StockMarketPrice/>
        <div className="Stock_Content">
          <NewsScrape/>
          <EcoCalender/>
        </div>
      </div>
    </div>
  );
}

const RiskURL = () => {
  const [, forceUpdate] = useState();

  return (
      <div className="layout">
        <div className="dashboard">
          <Sidebar/>
        </div>
      </div>
  )
}

const CovidURL = () => {
  const [, forceUpdate] = useState();

  return (
      <div className="layout">
        <div className="dashboard">
          <Sidebar/>
        </div>
      </div>
  )
}

const PortfolioURL = () => {
  const [, forceUpdate] = useState();

  const { path, url } = useRouteMatch();
  let [search, setSearch] = useState('')
  return (
      <Router>
        <Switch>

          <Route exact path={`${path}`}>
            <div className="layout">
              <div className="dashboard">
                <Sidebar/>
              </div>
              <div className="MainLayout">
              </div>
            </div>
          </Route>

          <Route path={`${path}/${search}`}>
            <div className="layout">
              <div className="dashboard">
                <Sidebar/>
              </div>
              <div className="MainLayout">
              </div>
            </div>
          </Route>

        </Switch>
      </Router>
  )
}
/*
const PortfolioURL = () => {
  return (
    <div className="layout">
      <div className="dashboard">
        <Sidebar/>
      </div>
      <div className="MainLayout">
        <StockSearch />
      </div>
    </div>
  )
}
*/



const App = () => {
  let [search, setSearch] = useState('')
  var symbolURL;
  const a = 1;

  if (a == 0) {return (
    <Router>
        <Switch>
          <Route exact path="/" element={<Overview />}>
            <Overview/>
          </Route>
        </Switch>
    </Router>
  )}
  if (a == 1) {return (
    <AppProvider>
      <Router>
        <Switch>
          <Route exact path="/" element={<Overview />}>
            <Overview/>
          </Route>

          <Route path="/login">
            <Login/>
          </Route>

          <Route path="/register">
            <Register/>
          </Route>

          <Route path="/budget">
            <BudgetURL/>
          </Route>

          <Route exact path="/stock">
            <StockURL/>
          </Route>

          <Route exact path={`/stock/:symbolURL/overview`}>
            <Stock/>
          </Route>

          <Route exact path={`/stock/:symbolURL/news`}>
            <StockNews/>
          </Route>

          <Route exact path={`/stock/:symbolURL/chart`}>
            <TradingChart/>
          </Route>

          <Route exact path={`/stock/:symbolURL/fundamentals`}>
            <Fundamentals/>
          </Route>

          <Route exact path={`/stock/:symbolURL/keystats`}>
            <KeyStats/>
          </Route>

          <Route exact path={`/stock/:symbolURL/profile`}>
            <MarketNews/>
          </Route>

          <Route exact path={`/stock/:symbolURL/comparison`}>
            <Comparison/>
          </Route>

          <Route exact path={`/stock/:symbolURL/calender`}>
            <Calender/>
          </Route>

          <Route exact path={`/stock/:symbolURL/analyst`}>
            <Analyst/>
          </Route>

          <Route exact path="/portfolio/overview">
            <Portfolio/>
          </Route>

          <Route exact path="/research">
            <Research/>
          </Route>

          <Route exact path="/ai">
            <AI/>
          </Route>

          <Route exact path="/portfolio/position">
            <Position/>
          </Route>

          <Route path={`/portfolio/${search}`}>
            <div className="layout">
              <div className="dashboard">
                <Sidebar/>
              </div>
              <div className="MainLayout">
                <StockSearchBar/>
              </div>
            </div>
          </Route>

          <Route path="/education">
            <Education/>
          </Route>

          <Route path="/covid">
            <CovidURL/>
          </Route>

        </Switch>
      </Router>
    </AppProvider>
  );}
}

export default App;

/*
<InvestmentList />
<StockSearch />
<InvestmentItem />
<WatchlistItem />
*/

/*
const StockSearchBar = () =>{
  let [symbol, setSymbol] = useState('');
  let [symbolType, setSymbolType] = useState('')
  let [result ,setResult] = useState()
  let [numnum, setNumNum] = useState(0)
  let [showStock, setShowStock] = useState(false)

  const onClick = () => {
      setSymbol(symbolType)
  }

  let getData = async () => {
      setResult()
      setSymbol(symbolType)
      let response = await fetch(`http://127.0.0.1:8000/api/stock/${symbolType}`)
      let data = await response.json()
      setResult(data)
  }

  return (
    <div className='Input'>
      <div className=''>
          <div className=''>
              <input
                  required='required'
                  type='text'
                  className='form-control'
                  id='name'
                  value={symbolType}
                  onChange={(event) => setSymbolType(event.target.value)}
              />
          </div>
      </div>
      <div className='row mt-3'>
          <div className='col-sm'>
            <Link to={`/portfolio/${symbolType}`}>
              <button onClick={onClick} type='submit' className='btn btn-primary'>
                  Save
              </button>
            </Link>
          </div>
      </div>
  </div>
  )
}
*/


/*
const App = () => {
  return (
    <AppProvider>
      <div className="layout">
        <div className="dashboard">
          <Sidebar/>
        </div>
        <div className="MainLayout">
          <div className = "Fund">
            <div className="Fund_Number">
              <Budget />
              <RemainingBudget />
              <ExpenseTotal />
            </div>
            <div className="Fund_Stat">
              <Progress />
            </div>
          </div>
          <div className="Expense">
            <ExpenseList />
            <AddExpenseForm/>
          </div>
          <div>
            <Test/>
          </div>
        </div>
      </div>
    </AppProvider>
  );
}
*/

/*
const App = () => {
  return (
    <AppProvider>
      <Router>
      <div className="layout">
        <div className="dashboard">
          <div className="sidebar">
            <div className="brand">
              <h2>
                <span>MA</span>
              </h2>
            </div>
            <ul className="links">
                <li>
                    <FaMoneyBill />
                    <Link to="/budget">Budget</Link>
                </li>
                <li>  
                    <AiOutlineStock />
                    Stock
                </li>
                <li>
                    <TiWarningOutline />
                    Risk
                </li>
                <li>
                    <RiVirusFill />
                    Covid
                </li>
                <Switch>
                  <Route exact path="/budget">
                    <div className="MainLayout">
                      <BudgetURL />
                    </div>
                  </Route>
                </Switch>
            </ul>
          </div>
        </div>
      </div>
      </Router>
    </AppProvider>
  );
}

const BudgetURL = () => {
  return (
    <div className="MainLayout">
      <div className = "Fund">
        <div className="Fund_Number">
          <Budget />
          <RemainingBudget />
          <ExpenseTotal />
        </div>
        <div className="Fund_Stat">
          <Progress />
        </div>
      </div>
      <div className="Expense">
        <ExpenseList />
        <AddExpenseForm/>
      </div>
      <div>
        <Test/>
      </div>
    </div>
  )
}
*/