import React, { useContext, useState, useEffect } from 'react';

import './overview.css'

import Main from './main';
import TOC from './toc';
import Intro from './intro';

const Overview = () => {

    return (
        <div className='Layout'>
            <Intro/>
            <Main/>
            <TOC/>
        </div>
    )
}

export default Overview;