import React, { useContext, useState, useEffect, useRef } from 'react';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import './StockChart.css'
import { AppContext } from '../../context/AppContext';

const StockChart = (props) => {
    var LastPrice = props.data[props.len-1]
    const value = useRef(LastPrice)
    const [val, setVal]  = useState()
    var [onHover, setHover] = useState(false)
    var [HoverIndex, setHoverIndex] = useState()
    const [Colour, setColour] = useState()

    useEffect( () => {
        var close = props.ClosePrice
        value.current = (LastPrice.toFixed(2))
        setVal(LastPrice.toFixed(2))
        if (LastPrice >= close[0]){
            setColour('rgba(0, 203, 57, .8)')
        }
        else{
            setColour('red')
        }

        if(onHover === false || HoverIndex === props.len-1){
            value.current = (LastPrice.toFixed(2))
            setVal(LastPrice.toFixed(2))
            props.setV(LastPrice.toFixed(2))
            props.setR((LastPrice.toFixed(2) - props.ClosePrice[0]).toFixed(2))
            props.setP(((LastPrice.toFixed(2) - props.ClosePrice[0]) / props.ClosePrice[0] * 100).toFixed(2))
        }
    }, [props.data])

    const handleMouseOver = (point) => {
        //value.current = (point.y.toFixed(2))
        setVal(point.y.toFixed(2))
        setHover(true)
        setHoverIndex(point.x)
        props.setV(point.y.toFixed(2))
        props.setR((point.y.toFixed(2) - props.ClosePrice[0]).toFixed(2))
        props.setP(((point.y.toFixed(2) - props.ClosePrice[0]) / props.ClosePrice[0] * 100).toFixed(2))
    };
      
    const handleMouseOut = () => {
        setHover(false)
        setHoverIndex()
        props.setV(value.current)
        props.setR((value.current- props.ClosePrice[0]).toFixed(2))
        props.setP(((value.current - props.ClosePrice[0]) / props.ClosePrice[0] * 100).toFixed(2))
    };

    const options_2 = {
        title: {
            text: '',
        },
        chart: {
            type: 'spline',
            animation: Highcharts.svg,
            width:900,
            height:300,
        },
      
        series: [
            {
                name: '',
                data: props.data,
                color: Colour,
                showInLegend: false,
                states: {
                    hover: {
                        enabled: false,
                        lineWidth: 2,
                    }
                },
            },
            {
                name: '',
                data: props.ClosePrice,
                color: 'black',
                showInLegend: false,
                enableMouseTracking: false,
                opacity: 0.2,
                states: {
                    hover: {
                        enabled: false,
                        lineWidth: 2,
                    }
                },
                dashStyle: 'Dash'
            },
            
        ],
        xAxis:{
            labels: {
                enabled: false,
            },
            title:'',      
        },
        yAxis:{
            labels: {
                format: '{value:.2f}',
            },
            opposite: true,
            title:'',      
        },
        credits: {
            enabled: false
        },
        tooltip: {
            crosshairs: [true],
            split: true,
            shadow: false,
            borderWidth: 0,
            formatter: function(e){
                const {x,y} = this;
                return ''
            },
        },
        plotOptions: {
            series: {
                point: {
                    events: {
                        load() {
                            setTimeout(this.reflow.bind(), 0);
                        },
                        mouseOver: (function() {
                            return function() {
                                var point = { x: this.x, y: this.y };
                                handleMouseOver(point);
                            };
                        })(this),
                        mouseOut: (function() {
                            return function(){
                                handleMouseOut();
                            };
                        })(this)
                    },
                },
                dataLabels: {
            		enabled: false,
                    x: 0,
                },
            }
        }
    }

    return (
        <div className="AI_StockChart">
            <HighchartsReact
                highcharts={Highcharts}
                options={options_2}
            />
        </div>
    )
}

export default StockChart

/*


    const options_3 = {
        plugins: {
            legend: {
                display: false,
                labels:{
                    boxWidth: 0,
                }
             } },
        scales: {
            x: {
              grid: {
                display: false
              },
              ticks: {
                display: false 
                }
            },
            y: {
              grid: {
                display: false
              },
              ticks: {
                display: false 
                }
            }
          }
    };  


    const options = {
        chart:{
            type: 'spline',
            animation: Highcharts.svg,
        },
        yAxis:{
            labels: {
                format: '{value:.2f}',
                enabled: false
            },
            opposite: true,
            title:'',      
        },
        credits: {
            enabled: false
        },
        series: [
            {
                name: '',
                data: props.data,
                color: 'green',
                showInLegend: false,
                states: {
                    hover: {
                        enabled: false,
                        lineWidth: 2,
                    }
                },
            },
            {
                name: '',
                data: props.ClosePrice,
                color: 'black',
                opacity: 0.5,
                showInLegend: false,
                enableMouseTracking: false,
                dashStyle: 'Dash'
            },

        ],
        title:{
            text: ''
        },
        tooltip: {
            crosshairs: [true],
            split: true,
        },
        plotOptions: {
            series: {
                point: {
                    events: {
                        load() {
                            setTimeout(this.reflow.bind(), 0);
                        },
                        mouseOver: (function() {
                            return function() {
                                var point = { x: this.x, y: this.y };
                                handleMouseOver(point);
                            };
                        })(this),
                        mouseOut: (function() {
                            return function(){
                                handleMouseOut();
                            };
                        })(this)
                    },
                },
            }
        },
    }


*/