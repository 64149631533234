import React, { useState, useEffect} from 'react';
import './EcoCalender.css'
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

import { Line } from "react-chartjs-2";
import './StockMarketPrice.css'

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

const StockMarketPrice = (props) => {
    const [result, setResult] = useState([]);

    useEffect(() => {
        getData()
    }, [])

    let getData = async () => {
        let response = await fetch('http://193.31.27.5:8000/api/stockmarketprice/')
        let data = await response.json()
        setResult(data)
    }
    var a = {
        "SPY": {
            "2022-02-02": 457.3500061035156,
            "2022-02-03": 446.6000061035156
        },
        "^IXIC": {
            "2021-09-01": 15309.3798828125,
            "2021-09-02": 15331.1796875,
            "2021-09-07": 15374.330078125,
        }
    }

    return (
        <div className="Inhalt">
            {Object.keys(result).map((res, idx) => {
                return (
                    <div className='Graph' key={idx} >
                        {res}
                        <GraphOverview datasets={result[res]}/>
                    </div>
                );
            })}
        </div>
    );
};

const GraphOverview = (props) => {
    let datasets = props.datasets
    let [Xvalue, setXvalue] = useState([])
    let [Yvalue, setYvalue] = useState([])

    useEffect(() => {
        getData()
    }, [])


    let getData = async () => {
        let XvalueFunction = [];
        let YvalueFunction = [];
        
        for (var date in datasets) {
            XvalueFunction.push(date);
            YvalueFunction.push(datasets[date])
        }
        setYvalue(YvalueFunction);
        setXvalue(XvalueFunction);
    }

    let dataset = []
    dataset.push({x: Xvalue, y: Yvalue})

   
    const dataA = {
        labels: Xvalue,
        datasets: [{
            type: 'line',
            borderColor: "black",
            borderWidth: 2,
            pointBorderColor: 'rgba(0, 0, 0, 0)',
            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
            data: Yvalue
        }]
    }

    return (
        <div className='Graph'>
            <Line data={dataA} options={options}/>       
        </div>
    );
};


const options = {
    plugins: {
        legend: {
            display: false,
            labels:{
                boxWidth: 0,
            }
         } },
    scales: {
        x: {
          grid: {
            display: false
          },
          ticks: {
            display: false 
            }
        },
        y: {
          grid: {
            display: false
          },
          ticks: {
            display: false 
            }
        }
      }
};  

export default StockMarketPrice;