import React, { useContext, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { v4 as uuidv4 } from 'uuid';
import { v1 as uuidv1 } from 'uuid';
import './BuyStock.css'

const BuyStock = (props) => {
    const [amount, setAmount] = useState()
    const [Type, setType] = useState('share')

	const ClickShare = (e) => {
        e.preventDefault()
        setType('share')
    }

    const ClickDollar = (e) => {
        e.preventDefault()
        setType('dollar')
    }

	return (
        <form>
            <div className='BuyStock_Box'>
                <div className='OrderLayout'>
                    <dl className='OrderForm'>
                        <dt>Type</dt>
                        <dd>
                            <div className='OrderType'>
                                <button 
                                onClick={ClickShare} 
                                style={{"backgroundColor":(Type=='share')?'rgb(200, 200, 200)':'rgba(38, 49, 60, .05)'}}
                                >Share</button>
                                <button 
                                onClick={ClickDollar}
                                style={{"backgroundColor":(Type=='dollar')?'rgb(200, 200, 200)':'rgba(38, 49, 60, .05)'}}
                                >Dollar</button>
                            </div>
                        </dd>
                        <dt>Amount</dt>
                        <dd>
                            <div className='OrderInput'>
                                <input
                                placeholder='0'
                                value = {amount}
                                onChange = {(e) => setAmount(e.target.value)}/>
                            </div>
                        </dd>
                        <dt>Market Price</dt>
                        <dd></dd>
                        <dd>{props.Price.toFixed(2)}</dd>
                        <dt>{(Type=='share')?'Cost':'Quantity'}</dt>
                        <dd></dd>
                        <dd>
                            {(Type=='share')
                            ?
                            ((amount == undefined) ? 0 : (amount * props.Price).toFixed(2))
                            :
                            (amount/props.Price).toFixed(2)}
                        </dd>
                    </dl>
                </div>
                <div className='Order'>
                    <button>Buy</button>
                </div>
            </div>
        </form>
	);
};

export default BuyStock;

/*
import React, { useContext, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { v4 as uuidv4 } from 'uuid';
import { v1 as uuidv1 } from 'uuid';
import './BuyStock.css'

const BuyStock = (props) => {
	const { expenses, dispatch } = useContext(AppContext);

	const [buyDate, setBuyDate] = useState('2022-02-01');
    const [amount, setAmount] = useState('');

	const onSubmit = async (event) => {
		const stock = {
			symbol: props.symbol,
			buyDate,
		};
        for (var x=0;x<amount;x++)
            {
                let response = await fetch(`http://193.31.27.5:8000/api/investment/create`, {
                method: 'POST',
                'headers': {
                    'Content-Type': 'application/json'
                },
                'body': JSON.stringify(stock)
                })
                let data = await response.json()

                dispatch({
                    type: 'ADD_INVEST',
                    payload: stock,
                });
            }

        setAmount(0)
	};

	return (
        <form>
            <div className='BuyStock_Box'>
            
                <div className='BuyStock_Input'>
                    <div className='BuyStock_Input_Amount'>
                        <label htmlFor='name'>Amount</label>
                        <input
                            required='required'
                            type='number'
                            className='form-control'
                            id='amount'
                            value={amount}
                            onChange={(event) => setAmount(event.target.value)}
                        />
                    </div>
                    <div className='BuyStock_Input_Date'>
                        <label htmlFor='cost'>Date</label>
                        <input
                            type='text'
                            className='form-control'
                            id='date'
                            value={buyDate}
                            onChange={(event) => setBuyDate(event.target.value)}
                        />
                    </div>
                </div>
                <div className='BuyStock_Button'>
                    <button type='submit' className='btn btn-primary'>
                        Buy
                    </button>
                </div>
            
            </div>
        </form>
	);
};

export default BuyStock;
*/

