import React, { useState, useEffect} from 'react';
import {
    BrowserRouter as Router,
    Link,
    useParams,
    useLocation,
    useHistory
  } from "react-router-dom"

import './AllNews.css'

const AllNews = () => {
    const [result, setResult] = useState([]);

    useEffect(() => {
        getData()
    }, [])

    let getData = async () => {
        let response = await fetch('http://193.31.27.5:8000/api/stockmarketprice/SPY/news')
        let data = await response.json()
        setResult(data)
        console.log(Object.keys(data))
    }

    if(result){
        return (
            <div className='News'>
                {Object.keys(result).map((week, idx) => {
                    return(
                        <div className="News_Day" key={`p-${idx}`}>
                            <div className='News_WeekName'>
                                <h3>{week}</h3>
                            </div>
                            <ItemNews value={result[week]}/>
                        </div>
                    )
                }
                )}
            </div>
        );
    }
    else{
        return (
        <>
        </>)
    }
    
};

const ItemNews = (props) => {
    var a = props.value
    return (
        <div className="">
            {Object.keys(a['News']).map(
                (item, idx) => {
                    return(
                        <div className="NewsItem" key={idx}>
                            <div className="News_Title">
                                <Link to={{ pathname: `https://${a['Link'][item].slice(7)}` }} target="_blank">
                                    {a['News'][item]}
                                </Link>
                            </div>
                            <div className="News_Desc">{a['Desc'][item]}</div>
                        </div>
                    )
                }
            )}
        </div>
    );
};

/*
const Report = (props) => {
    var a = props.value
    return (
        <div className="report">
            {a['Report'].map(
                (rep, idx) => {
                    return (
                        <div className={"rep"} key={`k=${idx}`}>{rep}</div>
                    )
                }
            )}
        </div>
    );
};
*/

export default AllNews;
/*

<table>
                            <thead>
                                <tr>
                                <th>Time (ET)</th>
                                <th>Report</th>
                                <th>Period</th>
                                <th>Actual</th>
                                <th>Median Forecast</th>
                                <th>Previous</th>
                                </tr>
                            </thead>
                        </table>

<tbody>
                    {Object.keys(a).map(
                        (item, idx) => {
                            return (
                                <tr>
                                <th>{a[{item}]['Time (ET)']}</th>
                                <th>{a[{item}]['Report']}</th>
                                <th>{a[{item}]['Period']}</th>
                                <th>{a[{item}]['Actual']}</th>
                                <th>{a[{item}]['Median Forecast']}</th>
                                <th>{a[{item}]['Previous']}</th>
                                </tr>
                            )
                        }
                    )}
                </tbody>
                */