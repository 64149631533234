import React, { useContext, useState } from 'react';
import { useEffect } from 'react/cjs/react.development';
import { AppContext } from '../../context/AppContext';
import './AddWatchlist.css'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    useLocation
  } from "react-router-dom"

const AddWatchlist= (props) => {
    const { watchlist, dispatch } = useContext(AppContext);
    const location = useLocation();
    const [Add, SetAdd] = useState(true)
    const [, forceUpdate] = useState();


    useEffect( () => {
        for (var item in watchlist){
            if (watchlist[item]['symbol'] == location.pathname.slice(11).toUpperCase()){
                SetAdd(false)
                break
            }
        }
    }, [])

    const onSubmit = async (event) => {
        if (Add == true){
            var addItem = {
                symbol: location.pathname.slice(11).toUpperCase(),
            };
            let response = await fetch(`http://193.31.27.5:8000/api/watchlist/create`, {
                method: 'POST',
                'headers': {
                    'Content-Type': 'application/json'
                },
                'body': JSON.stringify(addItem)
                })
            let data = await response.json()
        }
        else{
            for (var item in watchlist) {
                if (watchlist[item]['symbol'] == location.pathname.slice(11).toUpperCase()){
                    var stock = {
                        symbol: location.pathname.slice(11).toUpperCase(),
                    };
                    console.log(stock)
                    console.log(`http://193.31.27.5:8000/api/watchlist/${watchlist[item]['id']}/delete`)

                    let response = await fetch(`http://193.31.27.5:8000/api/watchlist/${watchlist[item]['id']}/delete`, {
                    method: 'DELETE',
                    'headers': {
                        'Content-Type': 'application/json'
                    },
                    'body': JSON.stringify(stock)
                    })
                    let data = await response.json()
                    dispatch({
                        type: 'DELETE_EXPENSE',
                        payload: watchlist[item]['id'],
                    });
                    break
                }
            }
        }
    }

	return (
        <form onSubmit={onSubmit}>
            <div className='SellStock_Button'>
                <button type='submit'className='btn btn-primary'>
                    {(Add == true) && "Add Watchlist"}
                    {(Add == false) && "Remove Watchlist"}
                </button>
            </div>
        </form>
	);
};

export default AddWatchlist;