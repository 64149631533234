import React, { useContext, useEffect, useState, useRef } from 'react';
import {
    BrowserRouter as Router,
    Link,
    useParams,
    useLocation,
    useHistory
  } from "react-router-dom"

import SearchBar from '../SearchBar/SearchBar';
import './StockPrice.css'
import StockNavbar from './StockNavbar'


const StockPrice = (props) => {
    const [symbol, setSymbol] = useState('')
    const history = useHistory();
    const location = useLocation();
    const [, forceUpdate] = useState();
    const { symbolURL } = useParams();
    const [result ,setResult] = useState('')
    const [isLoading, setLoading] = useState(true)
    const [V, setV] = useState('')
    const [R, setR] = useState('')
    const [P, setP] = useState('')
    

    useEffect( async () => {
        setSymbol(location.pathname.split('/')[2])
        if (symbol != ''){
            history.go(0)
        }

            let response1 = await fetch(`http://193.31.27.5:8000/api/stockprice/${symbolURL}`)
            let dataStock = await response1.json()

            var StockPrice = dataStock['daily']['Price']
            var index = Object.keys(StockPrice).length
            index = Object.keys(StockPrice)[index - 1]

            var index_2 = Object.keys(dataStock['daily']['Close'])
            var ClosePrice = dataStock['daily']['Close'][index_2[0]]

            setV(StockPrice[index].toFixed(2))
            setR((StockPrice[index].toFixed(2) - ClosePrice).toFixed(2))
            setP(((StockPrice[index].toFixed(2) - ClosePrice) / ClosePrice * 100).toFixed(2))
    }, [])

    if (P != ''){
        return (
            <h1 className='Stock_Price'>
                <span>{symbol}</span>
                <div style={{ "font-weight":"normal" }}>{V}</div>
                {(P >= 0) ? 
                <span style={{ color: 'green', "font-weight":"normal"}}>{R}(+{P}%)</span>
                :
                <span style={{ color: 'red', "font-weight":"normal" }}>{R}({P}%)</span>
                }
            </h1>
            );
    }
    else{
        return <h1>{symbol}</h1>
    }
    
};

export default StockPrice;

//{symbol && <StockChart symbol={symbol}/>}
//{count &&<ChartTest3 data={count} len={len} ClosePrice={ClosePrice}/>}
/*
{Object.keys(count)
            .map((item, idx) => {
                return(
                    <h5 key={idx}>{count[item]}</h5>
                )
            })}
            */
