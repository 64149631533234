import React, { useContext,useState, useEffect}  from 'react';
import { AppContext } from '../../context/AppContext';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  useLocation
} from "react-router-dom"

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

import { Line, Chart } from "react-chartjs-2";
import { FaBlackberry } from 'react-icons/fa';
import ChartTest from "./ChartTest";
import ChartTest2 from "./ChartTest2";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import BuyStock from './BuyStock'
import SellStock from './SellStock'
import InvestmentList from './InvestmentList'
import AddWatchlist from './AddWatchlist'
import './StockSearchBar.css'

const StockSearchBar = (props) =>{
    let [symbol, setSymbol] = useState('');
    let [symbolType, setSymbolType] = useState('')
    let [result ,setResult] = useState()
    const [, forceUpdate] = useState();
    const location = useLocation();
    const [isLoading, setLoading] = useState(true)
    const {porto} = useContext(AppContext);
    const [count, setCount] = useState(0);
    let [canSell, SetCanSell] = useState(0)
    
    
    const onClick = () => {
        clearInterval(window.interval);
        setSymbol(symbolType)
        if (symbolType === ''){
            setSymbol('')
        }
    }

    useEffect(() => {
        forceUpdate()
        clearInterval(window.interval);
        if (location.pathname.length > 11){
            getData()
        }
        else {
            getDataPorto()
        }
    }, [location.pathname.slice(11)])


    let getData = async () => {
        var canSell = false;
        setResult()
        setSymbol(symbolType)
        let response = await fetch(`http://193.31.27.5:8000/api/stock/${location.pathname.slice(11)}`)
        let data = await response.json()
        setResult(data)
        setLoading(false)

        for (var item in porto){
            if ((porto[item]['symbol'] == location.pathname.slice(11).toUpperCase()) && porto[item]['sellDate'] == ''){
                SetCanSell(1)
                break
            }
            SetCanSell(0)
        }

        
    }
    
    useEffect(() => {
        clearInterval(window.interval);
        if (location.pathname.length > 11){
            getData()
        }
        else {
            getDataPorto()
        }
    }, [symbol])

    let getDataPorto = async () => {
        setResult()
        setSymbol(symbolType)
        let response = await fetch(`http://193.31.27.5:8000/api/porto/`)
        let data = await response.json()
        setResult(data)
        setLoading(false)
    }
    
    return (
    <div className='PortfolioLayout'>
        <div className="SearchBox">
            <div className='Input'>
                <div className=''>
                    <input
                        type='text'
                        value={symbolType}
                        onChange={(event) => setSymbolType(event.target.value)}
                    />
                </div>
            </div>
            <div className='row mt-3'>
                <div className='col-sm'>
                <Link to={`/portfolio/${symbolType}`} key={symbol}>
                    <button onClick={onClick} type='submit' className='btn btn-primary'>
                        Search
                    </button>
                </Link>
                </div>
            </div>
        </div>
        <div className='PortGraph'>
            <div className='MainGraph'>
                <div className="StockSearch_Sym">
                    <h1>{(location.pathname.slice(11) != '') && location.pathname.slice(11).toUpperCase()}</h1>
                    <h1>{(location.pathname.slice(11) == '') &&  "Portfolio"}</h1>
                    {(location.pathname.slice(11) != '') && <AddWatchlist/>}
                </div>
                <div className="StockSearch_Chart">
                    {result && (props.value === undefined) && <GraphOverview url = {`http://193.31.27.5:8000/api/stock/${location.pathname.slice(11)}`} datasets = {result.chart}/>} 
                    {result && props.value && <GraphOverview datasets = {result['Change, 1mo, 1h']}/>}
                </div>
            </div>
            <div className="SecondGraph">
                {result && props.value && <InvestmentList data = {result['highlights']}/>}
            </div>
            <div className="BuySell">
                {result && (props.value == undefined) && <BuyStock symbol={location.pathname.slice(11)}/>}
                {result && (props.value == undefined) && (canSell == true) && <SellStock symbol={location.pathname.slice(11)}/>}
            </div>
        </div>
    </div>
    )
  }

  const GraphOverview = (props) => {
    let datasets = props.datasets
    let [Xvalue, setXvalue] = useState([])
    let [Yvalue, setYvalue] = useState([])
    let [data_highchart, setData_highchart] = useState([]);
    let [max_data, setMax_data] = useState(0);
    let [min_data, setMin_data] = useState(0);
    
    useEffect(() => {
        getData()
    }, [])

    let getData = () => {
        let XvalueFunction = [];
        let YvalueFunction = [];
        let aaaaa = [];
        var maxx = -99999;
        var minn = 99999;
        
        for (var date in datasets) {
            var data_einzeln = [];
            XvalueFunction.push(date);
            YvalueFunction.push(datasets[date])
            data_einzeln.push(date, datasets[date])
            aaaaa.push(data_einzeln)
            if (datasets[date] >= maxx){
                maxx = datasets[date]
            }
            else if (datasets[date] <= minn){
                minn = datasets[date]
            }
        }
        setYvalue(YvalueFunction);
        setXvalue(XvalueFunction);
        setData_highchart(aaaaa)
        setMax_data(maxx)
        setMin_data(minn)
    }

    let dataset = []
    dataset.push({x: Xvalue, y: Yvalue})

    return (
        <div className=''>
<ChartTest url = {props.url} highcharts={Highcharts} dataset_highchart={data_highchart} maxx={max_data} minn={min_data}/>    
        </div>
    );
};

//            <Line data={dataA} options={options} plugins={plugins}/>   
//<ChartTest url = {props.url} highcharts={Highcharts} dataset_highchart={data_highchart} maxx={max_data} minn={min_data}/>    
//<ChartTest2 url={props.url}/>
/*
const plugins = [
    {
      afterDraw: (chart) => {
        // eslint-disable-next-line no-underscore-dangle
        if (chart.tooltip._active && chart.tooltip._active.length) {
          // find coordinates of tooltip
          const activePoint = chart.tooltip._active[0];
          const { ctx } = chart;
          const { x } = activePoint.element;
          const topY = chart.scales.y.top;
          const bottomY = chart.scales.y.bottom;

          // draw vertical line
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = '#1C2128';
          ctx.stroke();
          ctx.restore();
        }
      },
    },
  ];
  */
  
  
  export default StockSearchBar;

  /*
  import React, { useContext,useState, useEffect}  from 'react';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams,
  useLocation
} from "react-router-dom"

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

import { Line, Chart } from "react-chartjs-2";
import { FaBlackberry } from 'react-icons/fa';
import ChartTest from "./ChartTest";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import BuyStock from './BuyStock'
import SellStock from './SellStock'

const StockSearchBar = () =>{
    let [symbol, setSymbol] = useState('');
    let [symbolType, setSymbolType] = useState('')
    let [result ,setResult] = useState()
    let [numnum, setNumNum] = useState(0)
    let [showStock, setShowStock] = useState(false)
    const [, forceUpdate] = useState();
    const location = useLocation();
    const [isLoading, setLoading] = useState(true)
    
    
    const onClick = () => {
        setSymbol(symbolType)
        if (symbolType === ''){
            setSymbol('')
        }
    }
  
    let getData = async () => {
        setResult()
        setSymbol(symbolType)
        let response = await fetch(`http://193.31.27.5:8000/api/stock/${location.pathname.slice(11)}`)
        let data = await response.json()
        setResult(data)
    }
    
    useEffect(() => {
        forceUpdate()
        if (location.pathname.length > 11){
            getData()
        }
        else {
            getDataPorto()
        }
    }, [symbol])

    let getDataPorto = async () => {
        setResult()
        setSymbol(symbolType)
        let response = await fetch(`http://193.31.27.5:8000/api/porto/`)
        let data = await response.json()
        setResult(data['Change, 1mo, 1h'])
        setLoading(false)
    }
    
    return (
      <div className='Input'>
        <div className=''>
            <div className=''>
                <input
                    required='required'
                    type='text'
                    className='form-control'
                    id='name'
                    value={symbolType}
                    onChange={(event) => setSymbolType(event.target.value)}
                />
            </div>
        </div>
        <div className='row mt-3'>
            <div className='col-sm'>
              <Link to={`/portfolio/${symbolType}`} key={symbol}>
                <button onClick={onClick} type='submit' className='btn btn-primary'>
                    Save
                </button>
              </Link>
            </div>
        </div>
        <div className='PortGraph'>
                <div className="StockSearch_Sym">
                    {symbol}
                </div>
                <div className="StockSearch_Chart">
                    {result && <GraphOverview datasets = {result}/>} 
                </div>

            </div>
        </div>
    )
  }

  const GraphOverview = (props) => {
    const options = {
        plugins: {
            tooltips: {
                mode: 'index',
                intersect: false
            },
            hover: {
                mode: 'index',
                intersect: false
            },
            legend: {
                display: true,
                legend: {
                    onHover: function(e) {
                       e.target.style.cursor = 'pointer';
                    }
                 },
                 hover: {
                    onHover: function(e) {
                       var point = this.getElementAtEvent(e);
                       if (point.length) {console.log("asdjnajskd")};
                    }
                 },
                onLeave: function (e) {
                  e.target.style.cursor = 'default'
                }
              }
        },
        scales: {
            x: {
              grid: {
                display: false
              },
              ticks: {
                display: false 
                }
            },
            y: {
              grid: {
                display: false
              },
              ticks: {
                display: false 
                }
            }
          },
        plotOptions: {
            series: {
              point: {
                events: {
                  mouseOver: (function(self) {
                    return function() {
                      const point = { x: this.x, y: this.y };
                      {handleMouseOver(point)};
                    };
                  })(this)
                }
              }
            }
          }
    };  

    let datasets = props.datasets
    let [Xvalue, setXvalue] = useState([])
    let [Yvalue, setYvalue] = useState([])
    let [data_highchart, setData_highchart] = useState([]);
    let [max_data, setMax_data] = useState(0);
    let [min_data, setMin_data] = useState(0);
    useEffect(() => {
        getData()
    }, [])

    let handleMouseOver = point => {
        console.log(`handleMouseOver gets: `, point);
    };
    

    let getData = () => {
        let XvalueFunction = [];
        let YvalueFunction = [];
        let aaaaa = [];
        var maxx = -99999;
        var minn = 99999;
        
        for (var date in datasets) {
            var data_einzeln = [];
            XvalueFunction.push(date);
            YvalueFunction.push(datasets[date])
            data_einzeln.push(date, datasets[date])
            aaaaa.push(data_einzeln)
            if (datasets[date] >= maxx){
                maxx = datasets[date]
            }
            else if (datasets[date] <= minn){
                minn = datasets[date]
            }
        }
        setYvalue(YvalueFunction);
        setXvalue(XvalueFunction);
        setData_highchart(aaaaa)
        setMax_data(maxx)
        setMin_data(minn)
    }

    useEffect( () => {
        console.log(data_highchart)
    }, [data_highchart])

    let dataset = []
    dataset.push({x: Xvalue, y: Yvalue})

   
    const dataA = {
        labels: Xvalue,
        datasets: [{
            type: 'line',
            borderColor: "black",
            borderWidth: 2,
            pointBorderColor: 'rgba(0, 0, 0, 0)',
            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
            data: Yvalue
        }]
    }

    return (
        <div className='PortGraph'>
            <ChartTest highcharts={Highcharts} dataset_highchart={data_highchart} maxx={max_data} minn={min_data}/>    
        </div>
    );
};

//            <Line data={dataA} options={options} plugins={plugins}/>   


const plugins = [
    {
      afterDraw: (chart) => {
        // eslint-disable-next-line no-underscore-dangle
        if (chart.tooltip._active && chart.tooltip._active.length) {
          // find coordinates of tooltip
          const activePoint = chart.tooltip._active[0];
          const { ctx } = chart;
          const { x } = activePoint.element;
          const topY = chart.scales.y.top;
          const bottomY = chart.scales.y.bottom;

          // draw vertical line
          ctx.save();
          ctx.beginPath();
          ctx.moveTo(x, topY);
          ctx.lineTo(x, bottomY);
          ctx.lineWidth = 1;
          ctx.strokeStyle = '#1C2128';
          ctx.stroke();
          ctx.restore();
        }
      },
    },
  ];
  
  
  export default StockSearchBar;
  */