import React, { useState, useContext } from 'react';
import { FaLess } from 'react-icons/fa';
import { AppContext } from '../../context/AppContext';
import './Login.css'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    useLocation,
    Redirect,
    useHistory
  } from "react-router-dom"

const Login = () => {
    const { user, dispatch } = useContext(AppContext);

    const [email, setEmail] = useState('admin');
    const [password, setPassword] = useState('admin');
    const [isDisabled, setIsDisabled] = useState(true)
    const [emailValid, setEmailValid] = useState(false)
    const [passwordValid, setPasswordValid] = useState(false)

    let history = useHistory();

    const signIn = e => {
        e.preventDefault();
        console.log(email)
    }

    const handleChangeUsername = e => {
        setEmail(e.target.value)
    }

    React.useEffect( async () => {
        let answer = await validateEmail(email)
        setEmailValid(answer);
    }, [email]);

    React.useEffect(() => {
        setPasswordValid(validatePassword(password));
    }, [password]);

    React.useEffect(() => {
        if (emailValid === true && passwordValid === true){
            setIsDisabled(false)
        }
        else{
            setIsDisabled(true)
        }
    })

    const validateEmail = async () => {
        let response = await fetch('http://193.31.27.5:8000/api/users/')
		let data = await response.json()
        let EmailExists = await data.map(({email})=>email);
        if (EmailExists.includes(email) == true){
            let item = data.filter((obj) => obj.email == email)
            if (item[0].password === password){
                return true
            }
            return false
        }
        return false
    }

    const validatePassword = password => password.length >= 1

    const onClickLogin = async (event) => {
        event.preventDefault()
        let answer = await validateEmail()
        if (answer){

            dispatch({
                type: 'SIGN_IN',
                payload: email,
            });

            let response = await fetch('http://193.31.27.5:8000/api/expense/')
            let data = await response.json()
            {data.map((exp, idx) => { 
                dispatch({
                    type: 'ADD_EXPENSE',
                    payload: exp,
                })
            })}

            history.push('/')
        }
    }
    
	return (
		<div className='login'>
            <div className='login_container'>
                <h1>
                    Sign In
                </h1>
                <form>
                    <div className='Login_Text'>
                        E-Mail
                    </div>

                    <input type='text' 
                        value={email} 
                        onChange={e => setEmail(e.target.value)}
                    >

                    </input>

                    <div className='Login_Text'>
                        Password
                    </div>

                    <input type='password' value={password} onChange={e => setPassword(e.target.value)}>

                    </input>

                    <button onClick={onClickLogin} className='SignIn_Button' >Sign In</button>
                    <Link to={`/register`}>
                        <button  className='Register_Button'>Register</button>
                    </Link>
                </form>
            </div>
		</div>
	);
};

export default Login;