import React, { useContext, useEffect, useState, useRef } from 'react';
import { AppContext } from '../../context/AppContext';

import {
    BrowserRouter as Router,
    Link,
    useParams,
    useLocation,
    useHistory
  } from "react-router-dom"

import SearchBar from '../SearchBar/SearchBar';
import './Research.css'
import EcoCalender from '../portfolio_url/EcoCalender';
import AllNews from './AllNews';


const Research = (props) => {

    return (
    <>
        <SearchBar/>
        <div className="Port_Layout">
            <EcoCalender/>
            <AllNews/>
        </div>
    </>
    );
};

export default Research;
