import React, { useContext, useEffect, useState, useRef } from 'react';
import { AppContext } from '../../context/AppContext';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    useLocation,
    useHistory
  } from "react-router-dom"
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

import SearchBar from '../SearchBar/SearchBar';
import ChartTest3 from './ChartTest3'

import BuyStock from './BuyStock'
import BuySell from './BuySell'
import SellStock from './SellStock'
import Metric from './Metric'
import StockNavbar from './StockNavbar'
import './Stock.css'
import { BsBookmarkStar } from 'react-icons/bs';
import { BsBookmarkDash } from 'react-icons/bs';


const Stock = (props) => {
    const [symbol, setSymbol] = useState('')
    const history = useHistory();
    const location = useLocation();
    const [, forceUpdate] = useState();
    const { symbolURL } = useParams();
    var [count, setCount] = useState([]);
    const [len, setLen] = useState([])
    const [AllData, setAllData] = useState()
    const TimeFrame = useRef('daily');
    const [ClosePrice, setClosePrice] = useState([])
    const Index = useRef(78)
    const [V, setV] = useState()
    const [R, setR] = useState()
    const [P, setP] = useState()
    const [MP, setMP] = useState()
    const [info, setInfo] = useState()
    const [Watchlist, setWatchList] = useState()
    const [ListWL, setListWL] = useState()
    const {user} = useContext(AppContext);
    const [ListAcc, setListAcc] = useState()
    const [AllAcc, setAllAcc] = useState()
    const [Pos, setPos] = useState([])
    const [AllPos, setAllPos] = useState()



    useEffect( () => {
        setSymbol(location.pathname.split('/')[2])
        if (symbol != ''){
            history.go(0)
        }
    }, [symbolURL])

    const IntervalFunction = async () => {
        var cc = 0
        
        window.asd = setInterval( async function () {
            console.log("stock")

            let response = await fetch(`http://193.31.27.5:8000/api/stockprice/${symbolURL}`)
            let dataStock = await response.json()
            //let StockPrice = dataStock["Change, 1d, 5m"]
            setAllData(dataStock)
            
            var StockChart = dataStock[TimeFrame.current]
            let StockPrice = dataStock[TimeFrame.current]['Price']
            var data = []
            let index = Object.keys(StockPrice)
            var dataClose = []

            var index3 = Object.keys(StockPrice).length
            index3 = Object.keys(StockPrice)[index3 - 1]
            setMP(StockPrice[index3])

            for (var i = 0; i <= Index.current; i += 1){
                var index_2 = Object.keys(StockChart['Close'])
                dataClose.push(
                    StockChart['Close'][index_2[0]]
                )
                if (i==0){
                    data.push(
                        StockPrice[index[i]]
                    );
                }
                else{
                    if (StockPrice[index[i]]===undefined){
                        data.push(null)
                    }
                    else{
                        data.push(
                            StockPrice[index[i]]
                        );
                    }
                    
                }
            }
            setLen(index.length)
            setCount(data);
            setClosePrice(dataClose)
       },3000)
    }

    const getWatchlist = async () => {
        let response3 = await fetch(`http://193.31.27.5:8000/api/watchlist/${{user}['user']}`)
		let data3 = await response3.json()
        let res = []

        data3.map((p, idx) => { 
            res.push(p.symbol)
        })
        setListWL(data3)

        if (res.includes(location.pathname.split('/')[2])){
            setWatchList(true)
        }
            
        else{
            setWatchList(false)
        }
    }
    
    useEffect( () => {
        getWatchlist()
        IntervalFunction()
        getAccount()
        getPosition('All Accounts')
    }, [])

    const onClick1d = () => {
        TimeFrame.current = 'daily'
        var StockChart = AllData['daily']
        let StockPrice = AllData["daily"]['Price']
        var data = []
        let index = Object.keys(StockPrice)
        var dataClose = []

        
        Index.current = 78
        for (var i = 0; i <= 78; i += 1){
            var index_2 = Object.keys(StockChart['Close'])
            dataClose.push(
                StockChart['Close'][index_2[0]]
            )
            if (i==0){
                data.push(
                    StockPrice[index[i]]
                );
            }
            else{
                if (StockPrice[index[i]]===undefined){
                    data.push(null)
                }
                else{
                    data.push(
                        StockPrice[index[i]]
                    );
                }
                
            }
        }
        setLen(index.length)
        setCount(data);
        setClosePrice(dataClose)
    }

    const onClick1w = () => {
        TimeFrame.current = 'weekly'
        var StockChart = AllData['weekly']
        let StockPrice = AllData["weekly"]['Price']
        var data = []
        let index = Object.keys(StockPrice)
        Index.current = 66
        var dataClose = []
        for (var i = 0; i <= 65; i += 1){
            var index_2 = Object.keys(StockChart['Close'])
            dataClose.push(
                StockChart['Close'][index_2[0]]
            )
            if (i==0){
                data.push(
                    StockPrice[index[i]]
                );
            }
            else{
                if (StockPrice[index[i]]===undefined){
                    data.push(null)
                }
                else{
                    data.push(
                        StockPrice[index[i]]
                    );
                }
                
            }
        }
        setLen(index.length)
        setCount(data);
        setClosePrice(dataClose)
    }

    const onClick1m = () => {
        TimeFrame.current = 'monthly'
        let StockPrice = AllData["monthly"]['Price']
        var StockChart = AllData['monthly']
        var data = []
        let index = Object.keys(StockPrice)
        var dataClose = []
        Index.current = 98
        for (var i = 0; i <= 97; i += 1){
            var index_2 = Object.keys(StockChart['Close'])
            dataClose.push(
                StockChart['Close'][index_2[0]]
            )
            if (i==0){
                data.push(
                    StockPrice[index[i]]
                );
            }
            else{
                if (StockPrice[index[i]]===undefined){
                    data.push(null)
                }
                else{
                    data.push(
                        StockPrice[index[i]]
                    );
                }
                
            }
        }
        setLen(index.length)
        setCount(data);
        setClosePrice(dataClose)
    }

    const onClick1y = () => {
        TimeFrame.current = 'yearly'
        let StockPrice = AllData["yearly"]['Price']
        var StockChart = AllData['yearly']
        var data = []
        let index = Object.keys(StockPrice)
        var dataClose = []
        Index.current = 253
        for (var i = 0; i <= 253; i += 1){
            var index_2 = Object.keys(StockChart['Close'])
            dataClose.push(
                StockChart['Close'][index_2[0]]
            )
            if (i==0){
                data.push(
                    StockPrice[index[i]]
                );
            }
            else{
                if (StockPrice[index[i]]===undefined){
                    data.push(null)
                }
                else{
                    data.push(
                        StockPrice[index[i]]
                    );
                }
                
            }
        }
        setLen(index.length)
        setCount(data);
        setClosePrice(dataClose)
    }

    const ClickAdd = async (e) =>{
        var addItem = {
            symbol: {symbol}['symbol'],
        };
        let response = await fetch(`http://193.31.27.5:8000/api/watchlist/${{user}['user']}/create`, {
        method: 'POST',
        'headers': {
            'Content-Type': 'application/json'
        },
        'body': JSON.stringify(addItem)
        })
        getWatchlist()
    }

    const ClickRemove = async (e) =>{
        var id
        for (var i in ListWL){
            if (ListWL[i]['symbol'] == {symbol}['symbol'] ){
                id = ListWL[i]['id']
            }
        }

        let response = await fetch(`http://193.31.27.5:8000/api/watchlist/${id}/${{user}['user']}/delete`, {
        method: 'DELETE',
        'headers': {
            'Content-Type': 'application/json'
        },
        })
        getWatchlist()

    }

    const getAccount = async () => {
        let response3 = await fetch(`http://193.31.27.5:8000/api/account/${{user}['user']}`)
		let data3 = await response3.json()
        let res = []

        data3.map((p, idx) => { 
            res.push(p.portfolio)
        })
        setListAcc(res)
        setAllAcc(data3)
    }

    const getPosition = async ( Account ) => {
        var response
        if (Account == 'All Accounts'){
            response = await fetch(`http://193.31.27.5:8000/api/position/${{user}['user']}`)
        }
        else{
            var AccId = props.Acc.filter(item => item.portfolio == Account)
            response = await fetch(`http://193.31.27.5:8000/api/position/${{user}['user']}/${AccId[0].id}`)
        }
        let data = await response.json()
        setAllPos(data)
        setPos(Object.keys(data))
    }

    if (count.length != 0 && AllPos.length != 0 && ClosePrice.length != 0 && len.length != 0){return (
        <div className='Stock_MainLayout'>
        <SearchBar/>
        <div className="Stock_Layout">
            <h1>{symbol.toUpperCase()}</h1>
            <StockNavbar/>
            <div className='Chart_BuySell'>
                <div className='Chart'>
                    
                    <div className='Header'>
                        <div>
                        <h2>{V}</h2>
                            {(P > 0) ? 
                            <h4 style={{ color: 'rgba(0, 203, 57, .8)' }}>{R}(+{P}%)</h4>
                            :
                            <h4 style={{ color: 'red' }}>{R}({P}%)</h4>
                            }
                        </div>

                        <div className='Watchlist'>
                            {(Watchlist == true)
                            ?
                            <button onClick={ClickRemove}><h3><BsBookmarkDash/>  Remove from Watchlist</h3></button>
                            :
                            <button onClick={ClickAdd}><h3><BsBookmarkStar/>  Add to Watchlist</h3></button>
                        }
                        </div>
                    </div>
                    
                    <div className="Stock_TimeFrame">
                        <button onClick={onClick1d}>1d</button>
                        <button onClick={onClick1w}>1w</button>
                        <button onClick={onClick1m}>1m</button>
                        <button onClick={onClick1y}>1y</button>
                    </div>
                    {count && <ChartTest3 data={count} len={len} ClosePrice={ClosePrice}
                    setV={setV} setR={setR} setP={setP}/>}
                </div>
                <BuySell symbol={symbol} Price={MP} Acc={ListAcc} Pos={AllPos} AllAcc={AllAcc}/>
            </div>
        </div>
        </div>
	);}
    else{
        return (
            <>
            <SearchBar/>
            <div className="Stock_Layout">
                <h1>{symbol.toUpperCase()}</h1>
                <StockNavbar/>
                <h1>Loading</h1>
            </div>
            </>
        )
    }
	
};

export default Stock;

//{symbol && <StockChart symbol={symbol}/>}
//{count &&<ChartTest3 data={count} len={len} ClosePrice={ClosePrice}/>}
/*
{Object.keys(count)
            .map((item, idx) => {
                return(
                    <h5 key={idx}>{count[item]}</h5>
                )
            })}
            <Metric info={info}/>

            */


