import React, { useContext } from 'react';
import { TiDelete } from 'react-icons/ti';
import { AppContext } from '../../context/AppContext';
import './ExpenseItem.css'

const ExpenseItem = (props) => {
	const { dispatch } = useContext(AppContext);


	let deleteExpense = async () => {
		fetch(`http://127.0.0.1:8000/api/expense/${props.id}/delete`, {
			method: 'DELETE',
			'headers': {
				'Content-Type': 'application/json'
			}
		})
		dispatch({
			type: 'DELETE_EXPENSE',
			payload: props.id,
		});
	}

	return (
		<div className="Expense_Item">
			<div className="Expense_Desc">
				<div className="Expense_Name">
					{props.name}
				</div>
				<div className="Expense_Tags">
					{props.tags}
				</div>
			</div>
			<div className="Expense_Detail">
				<div className="Expense_Cost">
					${props.cost}
					<TiDelete size='1.5em' onClick={deleteExpense} />
				</div>
				<div className="Expense_Date">
					{props.date}
				</div>
			</div>
		</div>
	);
};

export default ExpenseItem;