import React, { useState, useEffect} from 'react';
import './NewsScrape.css'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    useLocation
  } from "react-router-dom"

const NewsScrape = () => {
    let [symbol, setSymbol] = useState('');
    let [symbolType, setSymbolType] = useState('')
    let [result ,setResult] = useState('')
    const [isLoading, setLoading] = useState(true)


    const onClick = () => {
        setSymbol(symbolType)
        getData()  
    }

    let getData = async () => {
        let response = await fetch(`http://193.31.27.5:8000/api/stockmarketprice/${symbolType}/news`)
        let data = await response.json()
        setResult(data)
    }

    return (
        <div className='News'>
            <div className='Input'>
                <div className='Input_Box'>
                    <input
                        required='required'
                        type='text'
                        className='form-control'
                        id='name'
                        value={symbolType}
                        onChange={(event) => setSymbolType(event.target.value)}
                    />
                </div>
                <div className='Input_Button'>
                    <button onClick={onClick} type='submit' className='btn btn-primary'>
                        Search
                    </button>
                </div>
            </div>
            <div className="News_All">
                {result && Object.keys(result['date']).map(
                    (item, idx) => {
                        return(
                            <div className = "NewsItem"key= {idx}>
                                <div className="NewsItem_Title" >
                                    <Link to={{ pathname: `https://${result['link'][item].slice(7)}` }} target="_blank">
                                        {result['title'][item]}
                                    </Link>
                                </div>
                                <div className="NewsItem_Desc">{result['source'][item]}, {result['date'][item]}</div>
                            </div>
                        )
                    }
                )}  
            </div>
        </div>
    );
};

const test_data = {
    "date": {
        "0": "2022-02-06",
        "1": "2022-02-06",
        "2": "2022-02-06",
        "3": "2022-02-06",
        "4": "2022-02-06",
        "5": "2022-02-06",
        "6": "2022-02-06",
        "7": "2022-02-06",
        "8": "2022-02-06",
        "9": "2022-02-06",
        "10": "2022-02-05",
        "11": "2022-02-05",
        "12": "2022-02-05",
        "13": "2022-02-05",
        "14": "2022-02-05",
        "15": "2022-02-05",
        "16": "2022-02-05",
        "17": "2022-02-05",
        "18": "2022-02-05",
        "19": "2022-02-05",
        "20": "2022-02-05",
        "21": "2022-02-05",
        "22": "2022-02-05",
        "23": "2022-02-05",
        "24": "2022-02-05",
        "25": "2022-02-05",
        "26": "2022-02-05",
        "27": "2022-02-05",
        "28": "2022-02-05",
        "29": "2022-02-05",
        "30": "2022-02-05",
        "31": "2022-02-05",
        "32": "2022-02-05",
        "33": "2022-02-05",
        "34": "2022-02-05",
        "35": "2022-02-05",
        "36": "2022-02-05",
        "37": "2022-02-05",
        "38": "2022-02-04",
        "39": "2022-02-04",
        "40": "2022-02-04",
        "41": "2022-02-04",
        "42": "2022-02-04",
        "43": "2022-02-04",
        "44": "2022-02-04",
        "45": "2022-02-04",
        "46": "2022-02-04",
        "47": "2022-02-04",
        "48": "2022-02-04",
        "49": "2022-02-04",
        "50": "2022-02-04",
        "51": "2022-02-04",
        "52": "2022-02-04",
        "53": "2022-02-04",
        "54": "2022-02-04",
        "55": "2022-02-04",
        "56": "2022-02-04",
        "57": "2022-02-04",
        "58": "2022-02-04",
        "59": "2022-02-04",
        "60": "2022-02-04",
        "61": "2022-02-04",
        "62": "2022-02-04",
        "63": "2022-02-04",
        "64": "2022-02-04",
        "65": "2022-02-04",
        "66": "2022-02-04",
        "67": "2022-02-04",
        "68": "2022-02-04",
        "69": "2022-02-04",
        "70": "2022-02-04",
        "71": "2022-02-04",
        "72": "2022-02-04",
        "73": "2022-02-04",
        "74": "2022-02-04",
        "75": "2022-02-04",
        "76": "2022-02-04",
        "77": "2022-02-04",
        "78": "2022-02-04",
        "79": "2022-02-04",
        "80": "2022-02-04",
        "81": "2022-02-04",
        "82": "2022-02-04",
        "83": "2022-02-04",
        "84": "2022-02-04",
        "85": "2022-02-04",
        "86": "2022-02-04",
        "87": "2022-02-04",
        "88": "2022-02-04",
        "89": "2022-02-04",
        "90": "2022-02-04",
        "91": "2022-02-04",
        "92": "2022-02-04",
        "93": "2022-02-04",
        "94": "2022-02-04",
        "95": "2022-02-04",
        "96": "2022-02-04",
        "97": "2022-02-04",
        "98": "2022-02-04",
        "99": "2022-02-04",
        "100": "2022-02-04",
        "101": "2022-02-04",
        "102": "2022-02-04",
        "103": "2022-02-04",
        "104": "2022-02-04",
        "105": "2022-02-04",
        "106": "2022-02-04",
        "107": "2022-02-04",
        "108": "2022-02-04",
        "109": "2022-02-03",
        "110": "2022-02-03",
        "111": "2022-02-03",
        "112": "2022-02-03",
        "113": "2022-02-03",
        "114": "2022-02-03",
        "115": "2022-02-03",
        "116": "2022-02-03",
        "117": "2022-02-03",
        "118": "2022-02-03",
        "119": "2022-02-03",
        "120": "2022-02-03",
        "121": "2022-02-03",
        "122": "2022-02-03",
        "123": "2022-02-03",
        "124": "2022-02-03",
        "125": "2022-02-03",
        "126": "2022-02-03",
        "127": "2022-02-03",
        "128": "2022-02-03",
        "129": "2022-02-03",
        "130": "2022-02-03",
        "131": "2022-02-03",
        "132": "2022-02-03",
        "133": "2022-02-03",
        "134": "2022-02-03",
        "135": "2022-02-03",
        "136": "2022-02-03",
        "137": "2022-02-03",
        "138": "2022-02-03",
        "139": "2022-02-03",
        "140": "2022-02-03",
        "141": "2022-02-03",
        "142": "2022-02-03",
        "143": "2022-02-03",
        "144": "2022-02-03",
        "145": "2022-02-03",
        "146": "2022-02-03",
        "147": "2022-02-03",
        "148": "2022-02-03",
        "149": "2022-02-03",
        "150": "2022-02-03",
        "151": "2022-02-03",
        "152": "2022-02-03",
        "153": "2022-02-03",
        "154": "2022-02-03",
        "155": "2022-02-03",
        "156": "2022-02-03",
        "157": "2022-02-03",
        "158": "2022-02-03",
        "159": "2022-02-03",
        "160": "2022-02-03",
        "161": "2022-02-03",
        "162": "2022-02-03",
        "163": "2022-02-03",
        "164": "2022-02-03",
        "165": "2022-02-03",
        "166": "2022-02-03",
        "167": "2022-02-03",
        "168": "2022-02-03",
        "169": "2022-02-03",
        "170": "2022-02-03",
        "171": "2022-02-03",
        "172": "2022-02-03",
        "173": "2022-02-03",
        "174": "2022-02-03",
        "175": "2022-02-03",
        "176": "2022-02-03",
        "177": "2022-02-03",
        "178": "2022-02-03",
        "179": "2022-02-03",
        "180": "2022-02-03",
        "181": "2022-02-02",
        "182": "2022-02-02",
        "183": "2022-02-02",
        "184": "2022-02-02",
        "185": "2022-02-02",
        "186": "2022-02-01",
        "187": "2022-02-01",
        "188": "2022-02-01",
        "189": "2022-02-01",
        "190": "2022-02-01",
        "191": "2022-02-01",
        "192": "2022-02-01",
        "193": "2022-02-01",
        "194": "2022-02-01",
        "195": "2022-02-01",
        "196": "2022-02-01",
        "197": "2022-02-01",
        "198": "2022-01-31",
        "199": "2022-01-31",
        "200": "2022-01-31",
        "201": "2022-01-31",
        "202": "2022-01-31",
        "203": "2022-01-31",
        "204": "2022-01-31",
        "205": "2022-01-30",
        "206": "2022-01-30",
        "207": "2022-01-28",
        "208": "2022-01-28",
        "209": "2022-01-28",
        "210": "2022-01-28",
        "211": "2022-01-28",
        "212": "2022-01-28",
        "213": "2022-01-28",
        "214": "2022-01-28",
        "215": "2022-01-27",
        "216": "2022-01-27",
        "217": "2022-01-27",
        "218": "2022-01-27",
        "219": "2022-01-26",
        "220": "2022-01-26",
        "221": "2022-01-26",
        "222": "2022-01-25",
        "223": "2022-01-24",
        "224": "2022-01-24",
        "225": "2022-01-24",
        "226": "2022-01-23",
        "227": "2022-01-21",
        "228": "2022-01-20",
        "229": "2022-01-20",
        "230": "2022-01-20",
        "231": "2022-01-18",
        "232": "2022-01-18",
        "233": "2022-01-17",
        "234": "2022-01-16",
        "235": "2022-01-16",
        "236": "2022-01-14",
        "237": "2022-01-12",
        "238": "2022-01-12",
        "239": "2022-01-11",
        "240": "2022-01-10",
        "241": "2022-01-08",
        "242": "2022-01-05",
        "243": "2022-01-03",
        "244": "2021-12-21",
        "245": "2021-12-15",
        "246": "2021-12-02",
        "247": "2021-11-20",
        "248": "2021-11-15",
        "249": "2021-10-28",
        "250": "2021-09-13",
        "251": "2021-09-09",
        "252": "2021-08-17",
        "253": "2021-08-10",
        "254": "2021-07-26",
        "255": "2021-07-12",
        "256": "2021-05-17"
    },
    "title": {
        "0": "$1.4 trillion ? Big Tech’s pandemic year produces mind-boggling financial results",
        "1": "3 Reasons Duolingo Is a Screaming Buy on the Dip",
        "2": "Is Snap Stock a Buy Now?",
        "3": "Is Snap Stock a Buy Now?",
        "4": "Giant Pension Sold Apple, Intel, and Qualcomm Stock. Heres What It Bought.",
        "5": "3 Top Tech Stocks to Buy After the Market Sell-Off",
        "6": "$1.4 trillion ? Big Techs pandemic year produces mind-boggling financial results",
        "7": "10 Stocks to Sell According to Motley Fool",
        "8": "Dow Jones Futures: 5 Strong Stocks In Tricky Market Rally; What Active Investors Should Do",
        "9": "3 Top Tech Stocks to Buy After the Market Sell-Off",
        "10": "Peloton Draws Interest From Amazon, Nike: Papers",
        "11": "Elliott Wave View: Apple (AAPL) resumes higher [Video]",
        "12": "Is Meta Platforms Stock a Buy Now?",
        "13": "AAPL Dividend Announcement $0.0667\\/Share 2\\/5\\/2022",
        "14": "AAPL Dividend Announcement $0.0800\\/Share 2\\/4\\/2022",
        "15": "Apple Leads Five Stocks Near Buy Points Without This Big Risk",
        "16": "3 Reasons to Buy Alphabet Stock Before Its Stock Split",
        "17": "Facebook was on the defensive for yearsuntil the metaverse came along",
        "18": "2 Growth Stocks to Buy and Hold Forever",
        "19": "Apple (AAPL) Stock: $200 Price Target From Jefferies",
        "20": "Peloton Is Said to Draw Takeover Interest After Share Plunge",
        "21": "Capturing A Fibonacci Time & Price Setup In AAPL",
        "22": "Triad company makes World's Most Admired Companies list for fourth time",
        "23": "Top Ten Stock Market Moving Headlines From Last Week: Alphabet, Amazon, Meta Earnings & More",
        "24": "Apple's Spring Event Could Come Earlier Than Anticipated: Here's What You Should Know About The Launch Date And Product Launches",
        "25": "Top Ten Stock Market Moving Headlines From Last Week: Alphabet, Amazon, Meta, Snap, Spotify Earnings And More",
        "26": "2022 Percentage Returns Of Largest U.S. Companies By Market Capitalization - Saturday, Feb. 5",
        "27": "Apple Focuses On Small Businesses",
        "28": "Back-To-Back: Bank of England raises rates again",
        "29": "This Advertising Company Has A Better 5-Year Return Than Tesla, Ford, Microsoft, Apple, Disney, Netflix And Amazon",
        "30": "4 Stocks That Moved the Market in January",
        "31": "Is Meta Platforms Stock a Buy Now?",
        "32": "Cramer Highlights Key Differences Between Meta And Amazon Following Earnings",
        "33": "2 Growth Stocks to Buy and Hold Forever",
        "34": "Apple Leads Five Stocks Near Buy Points Without This Big Risk",
        "35": "3 Reasons to Buy Alphabet Stock Before Its Stock Split",
        "36": "Why Elastic Stock Fell 24.2% in January",
        "37": "3 Facts About Shiba Inu That You Should Know",
        "38": "Amazon stock on track for largest single-day market cap gain ever, bitcoin price pops",
        "39": "Stock Market Rally Closes With Strong Gains; Amazon, Apple, UnitedHealth In Focus",
        "40": "NortonLifeLock (NLOK) Q3 Earnings in Line, Revenues Up Y\\/Y",
        "41": "January jobs report 'was full of surprises,' Opimas CEO explains",
        "42": "Apple Aims to Debut a New Low-Cost 5G iPhone and iPad in Early March",
        "43": "Apple, Nvidia Lead List Of New Buys By The Best Mutual Funds",
        "44": "Apple plans to debut low cost 5G iPhone in March - Bloomberg News",
        "45": "Snap seems more insulated from Apple privacy change, TikTok competition: Strategist",
        "46": "Big Techs Week Featured Alphabet and Amazon Rallies, Meta Crash",
        "47": "Amazon Shares Jump as Cloud Unit Helps Drive Profit Past Estimates",
        "48": "Tech Earnings Impress in the Face of Economic Headwinds",
        "49": "Meta Aside, Large Tech Stocks Put on a Great Earnings Show",
        "50": "Broad Peak Investment Holdings Ltd. Buys International Game Technology PLC, Pershing Square ...",
        "51": "Apple, Broadcom successful in getting patent judgement thrown out",
        "52": "Snap Stock Soars On Q4 Earnings Beat; Ad Sales, User Growth Surprise",
        "53": "Snap and Pinterest Soar After Results Dispel Facebook Fears",
        "54": "Putnam Mutual Fund Champions Sustainability Values, Outperforms",
        "55": "Apple's Holiday Quarter Was Nearly Flawless",
        "56": "Snap stock soars 58%, its biggest daily gain ever",
        "57": "Amazon CEO is the outside insider that can push the company to new heights, professor says",
        "58": "Apple, Broadcom win new damages trial in $1.1 billion CalTech patent case",
        "59": "AAPL Dividend Announcement $0.2500\\/Share 2\\/3\\/2022",
        "60": "AAPL Dividend Announcement $0.7300\\/Share 2\\/3\\/2022",
        "61": "Empirical Finance, LLC Buys Apple Inc, Microsoft Corp, Tesla Inc, Sells Invesco Optimum Yield ...",
        "62": "AAPL Dividend Announcement $0.2650\\/Share 2\\/4\\/2022",
        "63": "Fortive (FTV) Q4 Earnings Beat Estimates, Revenues Miss",
        "64": "Amazon stock soars, Meta falls, Snap skyrockets, Pinterest rises amid flurry of tech earnings",
        "65": "The Best Is Yet to Come for Innovative EV Play Lucid Group",
        "66": "Markets undergoing massive change in rate expectations, strategist says",
        "67": "Analysis-Digital ad minnows Snap, Pinterest soar past giant Meta",
        "68": "Apple-Broadcom Win $1.1B CalTech Patent Trial",
        "69": "Paylocity (PCTY) Q2 Earnings and Revenues Beat Estimates",
        "70": "Amazon surges, eyes Wall Street record gain in value",
        "71": "Facebook's Apple-Induced Face Plant Could Haunt Company A While",
        "72": "Modera Wealth Management, LLC Buys Microsoft Corp, Amazon. ...",
        "73": "Tech Earnings Impress in the Face of Economic Headwinds",
        "74": "Meta’s projections for Facebook advertising point to trouble for the U.S. economy",
        "75": "Why Meta Platforms Stock Is Plummeting This Week",
        "76": "Grab Those Tech Stocks Following Amazon’s Blowout Earnings",
        "77": "Metaverse Cryptos Show Signs of Weakness Amid Meta’s Earnings Plunge",
        "78": "Why Apple, Disney And Robinhood Stocks Look Set For New Highs",
        "79": "Apple Stock Is a Sound Investment in Any Market",
        "80": "Jim Cramer Highlights Key Differences Between Meta And Amazon Following Earnings",
        "81": "Apple Offers 3% Commission Relief In Dutch App Store Case",
        "82": "Apple-Broadcom Win $1.1B CalTech Patent Trial",
        "83": "Weekend reads: Looking ahead after Facebook’s big drop",
        "84": "10 Information Technology Stocks Whale Activity In Today's Session",
        "85": "Most Active Equity Options For Midday - Friday, Feb. 4",
        "86": "Snap earnings spark huge rally for the stock—so big, it’s making people nervous",
        "87": "Facebook's Apple-Induced Face Plant Seems To Be Isolated — For Now",
        "88": "SPYR Inc. (SPYR) Capitalizing on Apple Ecosystem’s Convenience, Capability to Offer Compatible Smart Home Devices",
        "89": "Meta Platforms Stock Crashes: Hit With Several Headwinds in Q4",
        "90": "Will Axie Infinity Win the Race to the Metaverse?",
        "91": "Apple, Nvidia Lead List Of New Buys By The Best Mutual Funds",
        "92": "Apple's Holiday Quarter Was Nearly Flawless",
        "93": "Dow Jones Futures: Five Stocks Showing Strength In Tricky Market Rally; What To Do Now",
        "94": "Apple to unveil new iPhone SE, iPad Air in early March: report",
        "95": "Column: Is this the beginning of Facebook's downfall?",
        "96": "AAPL Dividend Announcement $0.0300\\/Share 2\\/3\\/2022",
        "97": "After Hours Most Active for Feb 4, 2022 : COTY, QQQ, SHY, AAPL, BAC, C, MSFT, NEE, SNAP, PM, CMCSA, ZGNX",
        "98": "Is PTON Stock A Buy Or Sell On Reported Amazon Takeover Interest?",
        "99": "Apple Headsets Could Be A Gamechanger",
        "100": "Read How Analysts Reacted To Snap's Q4 Results",
        "101": "These two S&P 500 stocks had their worst weeks ever",
        "102": "Apple Stock Is a Sound Investment in Any Market",
        "103": "Apple Stock: Jim Cramer’s Ideal Entry Price, And What To Do",
        "104": "Elliott Wave View: Apple (AAPL) Resumes Higher",
        "105": "Every Pullback Is an Opportunity with the SPDR S&P 500 ETF Trust",
        "106": "Jim Cramer Says Facebook-Parent Meta Has Become Undervalued But Here's Why You Should Wait Further To Buy",
        "107": "This way of picking value stocks has actually worked — and Berkshire Hathaway screens the best",
        "108": "Putnam Mutual Fund Champions Sustainability Values, Outperforms",
        "109": "2022 Space Awards winners: Top development projects",
        "110": "Senate Committee Passes Sweeping App Store Reform Bill",
        "111": "Amazon trots out YouTube-sized advertising business",
        "112": "Facebook owner Meta loses record $232 billion in market value after saying the quiet part out loud",
        "113": "Meta stock blowup is an important investing lesson: strategist",
        "114": "Amazon Adds to a Huge Season for Faangs, While Metas Meltdown Is the Outlier",
        "115": "Apples privacy measures cost Facebook $10 billion",
        "116": "Meta Faces Historic Stock Rout After Facebook Growth Stalled",
        "117": "UPDATE 1-U.S. Senate panel votes to approve app store bill aimed at Apple, Google",
        "118": "Meta earnings unveiled a lot of negative surprises, strategist says",
        "119": "U.S. Senate panel votes to approve app store bill aimed at Apple, Google",
        "120": "Apple privacy changes hammer social media stocks beyond Meta",
        "121": "U.S. economic recovery entering strange, tumultuous time, strategist says",
        "122": "Market check: Stocks fall as Meta earnings weigh on tech",
        "123": "Fed tightening does not signify doom for stock market: Investment Strategist",
        "124": "Qualcomm Gives Rosy Forecast as Smartphone Chip Sales Soar",
        "125": "Meta stock presenting very good buying opportunity for long-term investors: Strategist",
        "126": "5 Red Flags for Meta Platforms' Future",
        "127": "Amazon stock soars 15% after earnings, will hike Prime membership fee",
        "128": "Why Facebook parent Metas stock is getting crushed",
        "129": "Why the Winners and Losers of Metas Bombshell Earnings Will Become Clearer Today.",
        "130": "3 Robinhood Stocks to Buy and Hold Forever",
        "131": "US STOCKS-Wall St futures tumble after Facebook sparks another tech rout",
        "132": "Here's Why Apple Is a Great Stock to Hold for Decades",
        "133": "Better Buy: BlackBerry vs. Apple",
        "134": "Exclusive-iPhone flaw exploited by second Israeli spy firm-sources",
        "135": "Meta's miss creates Big Tech divide: who's got the data",
        "136": "Apple Makes Progress in India as iPhone Sales Rise 34% to Record",
        "137": "S.Korea seeks improved compliance plans from Apple, Google on app store law",
        "138": "Why Meta Platforms Stock Cratered on Thursday",
        "139": "8 best key finders to help you track down your stuff",
        "140": "13 Firms Hoard $1 Trillion In Cash (We're Looking At You Big Tech)",
        "141": "Why Disney Plus Holds an Edge Over Other Streamers",
        "142": "Neumann Capital Management, LLC Buys Apple Inc, BTC iShares Core MSCI EAFE ETF, iShares Russell ...",
        "143": "Meta Erases $251 Billion in Value, Biggest Wipeout in History",
        "144": "What to know about the Apple privacy changes that crushed Facebook parent Meta",
        "145": "US STOCKS-Wall St ends winning run as Facebook forecast halts tech-led recovery",
        "146": "Amazon Earnings Will Show If Its Facebook or Alphabet",
        "147": "Amazon competition is getting intense,' but lead remains intact, analyst says",
        "148": "Tech guidance has been more disappointing than earnings: Strategist",
        "149": "Stocks accelerate losses into market close as tech tanks",
        "150": "Snap current quarter outlook beats estimates, shares skyrocket",
        "151": "UPDATE 1-Snap current quarter outlook beats estimates, shares skyrocket",
        "152": "Snap beats revenue estimates in recovery from Apple privacy changes",
        "153": "Meta Platforms' Plummeting Stock: Is It a Buy?",
        "154": "Regulators cant touch Facebookbut the market can",
        "155": "Market strategist: Technical dynamics have become extreme",
        "156": "Apples Grip on App Store Challeged by Senate Bill Action",
        "157": "M.d. Sass Investors Services, Inc. Buys ON Semiconductor Corp, Vertex Pharmaceuticals Inc, APi ...",
        "158": "Facebook: Apple iOS changes costing us billions",
        "159": "Big Tech stocks moving into a structural downtrend for many years, strategist says",
        "160": "Senate committee passes bill aimed at Apple, Google's app stores",
        "161": "Anfield Capital Management, LLC Buys Materials Select Sector SPDR, Apple Inc, PIMCO Dynamic ...",
        "162": "Tech stocks tumble following huge Meta earnings miss",
        "163": "This Apple Supplier Expects a Hefty Hit to Revenue. Blame Covid-19.",
        "164": "S.Korea seeks improved compliance plans from Apple, Google on app store law",
        "165": "AAPL Dividend Announcement $0.1400\\/Share 2\\/2\\/2022",
        "166": "AAPL Dividend Announcement $0.3800\\/Share 2\\/3\\/2022",
        "167": "The Nasdaq Had Its Worse Day Since October 2020 Thanks In Part To Meta Platforms",
        "168": "AAPL Stock Premarket: Why It Will Have A Rough Thursday",
        "169": "AAPL Dividend Announcement $1.0500\\/Share 2\\/2\\/2022",
        "170": "Here's Why Apple Is a Great Stock to Hold for Decades",
        "171": "AAPL Dividend Announcement $1.1200\\/Share 2\\/2\\/2022",
        "172": "AAPL Dividend Announcement $0.0880\\/Share 2\\/2\\/2022",
        "173": "Apple Urges Senate to Reject a Bill That Allows Outside App Stores",
        "174": "AAPL Dividend Announcement $0.3100\\/Share 2\\/2\\/2022",
        "175": "AAPL Dividend Announcement $0.0100\\/Share 2\\/2\\/2022",
        "176": "Anfield Capital Management, LLC Buys Materials Select Sector SPDR, Apple Inc, PIMCO Dynamic ...",
        "177": "Yet Another Israeli Firm Apart From NSO Group Had Hacked iPhones - Benzinga",
        "178": "AAPL Dividend Announcement $0.3000\\/Share 2\\/3\\/2022",
        "179": "How Much Will Apple's iOS Privacy Changes Cost Facebook In 2022? - Benzinga",
        "180": "M.d. Sass Investors Services, Inc. Buys ON Semiconductor Corp, Vertex Pharmaceuticals Inc, APi ...",
        "181": "AAPL Dividend Announcement $0.7300\\/Share 2\\/1\\/2022",
        "182": "Family Firm, Inc. Buys Apple Inc, iShares MSCI Emerging Markets ETF, PIMCO Active Bond ETF, ...",
        "183": "AAPL Dividend Announcement $0.2700\\/Share 2\\/2\\/2022",
        "184": "Warren Buffett Wins in 2022: ETF Lessons to Learn From",
        "185": "What Is Apple Inc.'s (NASDAQ:AAPL) Share Price Doing?",
        "186": "AAPL Dividend Announcement $0.3400\\/Share 2\\/1\\/2022",
        "187": "AAPL: 5G Industry in 2022: The Complete Investors Guide",
        "188": "Is Apple Stock A Buy Or Sell After Recent Earnings? (NASDAQ:AAPL)",
        "189": "Apple 10-Q shows signs of gross margin expansion, improving supply chain",
        "190": "Apple's Cash-Based Sources Of Intrinsic Value Are Amazing!",
        "191": "Occidental Asset Management, LLC Buys SPDR Bloomberg SASB Corporate Bond ESG Select ETF, Apple ...",
        "192": "AAPL Dividend Announcement $0.0225\\/Share 2\\/1\\/2022",
        "193": "AAPL Dividend Announcement $0.3000\\/Share 2\\/1\\/2022",
        "194": "AAPL Dividend Announcement $0.0600\\/Share 2\\/1\\/2022",
        "195": "Defender Capital, LLC. Buys Landec Corp, Mirion Technologies Inc, Altria Group Inc, Sells ...",
        "196": "BCGM Wealth Management, LLC Buys PowerShares QQQ Trust Ser 1, Apple Inc, First Merchants Corp, ...",
        "197": "AAPL Dividend Announcement $0.1800\\/Share 2\\/1\\/2022",
        "198": "Apple (AAPL) tames supply chain hurdles; looks headed for a record year",
        "199": "AAPL Dividend Announcement $0.0145\\/Share 1\\/31\\/2022",
        "200": "Apple sees 25% growth in European smartphones in 2021: Counterpoint Research",
        "201": "Dow Movers: DOW, AAPL",
        "202": "AAPL Dividend Announcement $0.2682\\/Share 1\\/31\\/2022",
        "203": "AAPL Dividend Announcement $0.0400\\/Share 1\\/31\\/2022",
        "204": "Spotify-Joe Rogan backlash widens, but economics back streaming giant's clout",
        "205": "Apple Looks Likely To Raise Its Dividend And Buyback This Spring",
        "206": "Will Apple (AAPL) Buy Peloton? Probably Not",
        "207": "ACWI, AAPL, AMZN, GOOG: Large Inflows Detected at ETF",
        "208": "Apple revenue pops 11% to $123.9 billion, Cook says supply chain improving",
        "209": "Daily Dividend Report: AAPL,LMT,WBA,FNV,CMS",
        "210": "Apple (AAPL Stock) up 5% in afterhours, Tesla (TSLA Stock) down 11%, mood: Wobbly [Video]",
        "211": "Metaverse News: 10 Things for AAPL Stock Investors to Know About Apple's Big AR Plans",
        "212": "Apple (AAPL) and Microsoft (MSFT) Lead Tech Stock Comeback",
        "213": "New Street Research Upgrades Apple to Neutral, Announces $165 Price Target",
        "214": "What Does #CancelSpotify Really Mean for AAPL Stock?",
        "215": "Now It's Apple's Turn to Try and Boost Tech Stocks After Rout",
        "216": "Apple working on software to let SMBs take payments direct from iPhone: report",
        "217": "After-Hours Earnings Report for January 27, 2022 : AAPL, V, SYK, MDLZ, KLAC, CP, TEAM, RMD, AJG, CE, WDC, EMN",
        "218": "4 Top Stock Trades for Friday: AAPL, MCD, NOW, LCID",
        "219": "Apple Earnings: What to Look for from AAPL",
        "220": "Apple Gets a Restraining Order Against Woman Stalking CEO Tim Cook",
        "221": "Apple Tops China for First Time in Six Years After Huawei Fades",
        "222": "AAPL stock still falling, but Wall Street expecting another record quarter",
        "223": "Tech Stocks Alert: Why Are TSLA, MSFT, AAPL, NVDA, SNAP Plunging Again?",
        "224": "Apple Is Sinking, and a Weaker Dollar May Be the Savior It Needs",
        "225": "Noteworthy Monday Option Activity: BA, AAPL, ULTA",
        "226": "Weekly Preview: Earnings To Watch this Week (AAPL, AMD, INTC, MSFT, TSLA)",
        "227": "Apple Stock: It Could Reach $5 Trillion By 2025",
        "228": "Pinnacle Wealth Management Advisory Group, LLC Buys Microsoft Corp, Apple Inc, Berkshire ...",
        "229": "Radnor Capital Management, LLC Buys Microsoft Corp, Apple Inc, International Business Machines ...",
        "230": "Apple (AAPL) PR Head Leaves After 8 Months, Replaced by Company Veteran",
        "231": "Ritholtz Wealth Management Buys Apple Inc, Prologis Inc, Microsoft Corp, Sells iShares iBonds ...",
        "232": "Ericsson Escalates 5G Patent Dispute With Apple (AAPL) in New Lawsuits",
        "233": "Apple Stock Price and Forecast: Can AAPL earnings provide the catalyst for another rally?",
        "234": "Apple vs. Tesla: Can the world's most valuable company compete against Elon Musk?",
        "235": "Apple Vision? Apple Reality? What Apple Could Name Its AR\\/VR Headset",
        "236": "Apple (AAPL) AV\\/VR Headset Risks Being Delayed Until 2023",
        "237": "AAPL vs. AMZN: Jim Cramer Picked The Right One",
        "238": "Microsoft Hires Key Apple Engineer to Work on Custom Chips",
        "239": "Apple (AAPL) Reportedly Discussing Streaming Deal with MLB",
        "240": "FB, AMZN, NVDA, AAPL, COIN: Why Are Tech Stocks Down Today?",
        "241": "Here's How Apple Stock Can Soar Above $200 a Share in 2022",
        "242": "Apple: Do Not Buy, Do Not Sell",
        "243": "Is Apple Stock Likely To Reach $200 In 2022?",
        "244": "Apple: State Of Uncertainty",
        "245": "Apple Stock Is Now A Bubble",
        "246": "AAPL set to lose recent gains, following iPhone 13 report",
        "247": "Apple: A Sell Before December",
        "248": "Sell AAPL? Why This Expert Sees Apple Stock Dipping 12%",
        "249": "Apple Earnings: What Happened With AAPL",
        "250": "Apple - AAPL - Stock Price & News",
        "251": "How To Buy Apple (AAPL) Stock – Forbes Advisor",
        "252": "New AAPL all-time high as it finally breaks $150",
        "253": "Bull of the Day: Apple (AAPL)",
        "254": "AAPL Option Traders Enthusiastic Ahead of Earnings",
        "255": "Apple (AAPL) Stock Sinks As Market Gains: What You Should Know",
        "256": "AAPL After Hours: Why Apple Stock Dipped Again"
    },
    "source": {
        "0": "MarketWatch",
        "1": "Motley",
        "2": "Motley",
        "3": " Motley Fool",
        "4": " Barrons.com",
        "5": " Motley Fool",
        "6": " MarketWatch",
        "7": " Insider Monkey",
        "8": " Investor's Business Daily",
        "9": "Motley",
        "10": " Bloomberg",
        "11": "FXStreet",
        "12": " Motley Fool",
        "13": "Dividend Investor",
        "14": "Dividend Investor",
        "15": " Investor's Business Daily",
        "16": " Motley Fool",
        "17": " Quartz",
        "18": " Motley Fool",
        "19": "Pulse 2.0",
        "20": " Bloomberg",
        "21": "ElliottWaveTrader",
        "22": " American City Business Journals",
        "23": "TalkMarkets",
        "24": "Benzinga",
        "25": "Benzinga",
        "26": "TalkMarkets",
        "27": "TalkMarkets",
        "28": "TalkMarkets",
        "29": "Benzinga",
        "30": "Motley",
        "31": "Motley",
        "32": "TalkMarkets",
        "33": "Motley",
        "34": "Investor's",
        "35": "Motley",
        "36": "Motley",
        "37": "Motley",
        "38": " Yahoo Finance Video",
        "39": "Investor's",
        "40": " Zacks",
        "41": " Yahoo Finance Video",
        "42": " Bloomberg",
        "43": " Investor's Business Daily",
        "44": " Reuters",
        "45": " Yahoo Finance Video",
        "46": " Bloomberg",
        "47": " Bloomberg",
        "48": "TalkMarkets",
        "49": "Bloomberg",
        "50": " GuruFocus.com",
        "51": "Seeking Alpha",
        "52": " TheStreet.com",
        "53": " Bloomberg",
        "54": " Investor's Business Daily",
        "55": " Motley Fool",
        "56": " Yahoo Finance",
        "57": " Yahoo Finance Video",
        "58": " Reuters",
        "59": "Dividend Investor",
        "60": "Dividend Investor",
        "61": " GuruFocus.com",
        "62": "Dividend Investor",
        "63": " Zacks",
        "64": " Yahoo Finance Video",
        "65": "InvestorPlace",
        "66": " Yahoo Finance Video",
        "67": " Reuters",
        "68": " Benzinga",
        "69": " Zacks",
        "70": " Reuters",
        "71": " Investor's Business Daily",
        "72": " GuruFocus.com",
        "73": " Zacks",
        "74": "MarketWatch",
        "75": "Motley",
        "76": "InvestorPlace",
        "77": "InvestorPlace",
        "78": "Benzinga",
        "79": "InvestorPlace",
        "80": "Benzinga",
        "81": "Benzinga",
        "82": "Benzinga",
        "83": "MarketWatch",
        "84": "Benzinga",
        "85": "TalkMarkets",
        "86": "MarketWatch",
        "87": "Investor's",
        "88": "InvestorBrandNetwork",
        "89": "Motley",
        "90": "InvestorPlace",
        "91": "Investor's",
        "92": "Motley",
        "93": "Investor's",
        "94": "Seeking Alpha",
        "95": " LA Times",
        "96": "Dividend Investor",
        "97": "Nasdaq",
        "98": " Investor's Business Daily",
        "99": "Seeking Alpha",
        "100": "Benzinga",
        "101": "MarketWatch",
        "102": "InvestorPlace",
        "103": "TheStreet",
        "104": "TalkMarkets",
        "105": "InvestorPlace",
        "106": "Benzinga",
        "107": "MarketWatch",
        "108": "Investor's",
        "109": " American City Business Journals",
        "110": " Motley Fool",
        "111": " Reuters",
        "112": " MarketWatch",
        "113": " Yahoo Finance",
        "114": " Bloomberg",
        "115": " Yahoo Finance Video",
        "116": " Bloomberg",
        "117": " Reuters",
        "118": " Yahoo Finance Video",
        "119": " Reuters",
        "120": " Yahoo Finance",
        "121": " Yahoo Finance Video",
        "122": " Yahoo Finance Video",
        "123": " Yahoo Finance Video",
        "124": " Bloomberg",
        "125": " Yahoo Finance Video",
        "126": " Motley Fool",
        "127": " Yahoo Finance",
        "128": " Yahoo Finance",
        "129": " Barrons.com",
        "130": " Motley Fool",
        "131": " Reuters",
        "132": " Motley Fool",
        "133": " Motley Fool",
        "134": " Reuters",
        "135": " Reuters",
        "136": " Bloomberg",
        "137": " Reuters",
        "138": " Motley Fool",
        "139": " The Independent",
        "140": " Investor's Business Daily",
        "141": " Investopedia",
        "142": " GuruFocus.com",
        "143": " Bloomberg",
        "144": " Yahoo Finance",
        "145": " Reuters",
        "146": " Barrons.com",
        "147": " Yahoo Finance Video",
        "148": " Yahoo Finance Video",
        "149": " Yahoo Finance Video",
        "150": " Reuters",
        "151": " Reuters",
        "152": " Reuters",
        "153": " Motley Fool",
        "154": " Yahoo Finance",
        "155": " Yahoo Finance Video",
        "156": " Bloomberg",
        "157": " GuruFocus.com",
        "158": " American City Business Journals",
        "159": " Yahoo Finance Video",
        "160": " MarketWatch",
        "161": " GuruFocus.com",
        "162": " Yahoo Finance Video",
        "163": " Barrons.com",
        "164": " Reuters",
        "165": "Dividend Investor",
        "166": "Dividend Investor",
        "167": "Benzinga",
        "168": "TheStreet",
        "169": "Dividend Investor",
        "170": "Motley Fool",
        "171": "Dividend Investor",
        "172": "Dividend Investor",
        "173": "Bloomberg",
        "174": "Dividend Investor",
        "175": "Dividend Investor",
        "176": "Yahoo Finance",
        "177": "Benzinga",
        "178": "Dividend Investor",
        "179": "Benzinga",
        "180": "Yahoo Finance",
        "181": "Dividend Investor",
        "182": "Yahoo Finance",
        "183": "Dividend Investor",
        "184": "Yahoo Finance",
        "185": "Nasdaq",
        "186": "Dividend Investor",
        "187": "StockNews.com",
        "188": "Seeking Alpha",
        "189": "Seeking Alpha",
        "190": "Seeking Alpha",
        "191": "Yahoo Finance",
        "192": "Dividend Investor",
        "193": "Dividend Investor",
        "194": "Dividend Investor",
        "195": "Yahoo Finance",
        "196": "Yahoo Finance",
        "197": "Dividend Investor",
        "198": "AlphaStreet",
        "199": "Dividend Investor",
        "200": "Seeking Alpha",
        "201": "Nasdaq",
        "202": "Dividend Investor",
        "203": "Dividend Investor",
        "204": "Yahoo Finance",
        "205": "Seeking Alpha",
        "206": "Bloomberg",
        "207": "Nasdaq",
        "208": "CNBC",
        "209": "Nasdaq",
        "210": "FXStreet",
        "211": "InvestorPlace",
        "212": "Bloomberg",
        "213": "Benzinga",
        "214": "InvestorPlace",
        "215": "Bloomberg",
        "216": "Seeking Alpha",
        "217": "Nasdaq",
        "218": "Nasdaq",
        "219": "Investopedia",
        "220": "Bloomberg",
        "221": "Bloomberg",
        "222": "9to5Mac",
        "223": "Investorplace.com",
        "224": "Bloomberg",
        "225": "Nasdaq",
        "226": "Nasdaq",
        "227": "TheStreet",
        "228": "Yahoo Finance",
        "229": "Yahoo Finance",
        "230": "Bloomberg",
        "231": "Yahoo Finance",
        "232": "Bloomberg",
        "233": "FXStreet",
        "234": "Seeking Alpha",
        "235": "Bloomberg",
        "236": "Bloomberg",
        "237": "TheStreet",
        "238": "Bloomberg",
        "239": "Nasdaq",
        "240": "Investorplace.com",
        "241": "The Motley Fool",
        "242": "Seeking Alpha",
        "243": "Seeking Alpha",
        "244": "Seeking Alpha",
        "245": "Seeking Alpha",
        "246": "9to5Mac",
        "247": "Seeking Alpha",
        "248": "TheStreet",
        "249": "Investopedia",
        "250": "Motley Fool",
        "251": "Forbes",
        "252": "9to5Mac",
        "253": "Yahoo Finance",
        "254": "Investopedia",
        "255": "Yahoo Finance",
        "256": "TheStreet"
    },
    "link": {
        "0": "http:\\/\\/www.marketwatch.com\\/news\\/story\\/14-trillion-big-techs\\/story.aspx?guid=%7B20C05575%2D04D4%2DB545%2D7901%2D9A7904DB3981%7D",
        "1": "https:\\/\\/www.fool.com\\/investing\\/2022\\/02\\/06\\/3-reasons-to-buy-duolingo-stock-on-the-dip\\/?source=financialcontent&utm_source=financialcontent&utm_medium=feed&utm_campaign=article",
        "2": "https:\\/\\/www.fool.com\\/investing\\/2022\\/02\\/06\\/is-snap-stock-a-buy-now\\/?source=financialcontent&utm_source=financialcontent&utm_medium=feed&utm_campaign=article",
        "3": "https:\\/\\/www.fool.com\\/investing\\/2022\\/02\\/06\\/is-snap-stock-a-buy-now\\/?source=eptyholnk0000202&utm_source=yahoo-host&utm_medium=feed&utm_campaign=article",
        "4": "https:\\/\\/www.barrons.com\\/articles\\/apple-stock-intel-qualcomm-nvidia-51643844523?siteid=yhoof2",
        "5": "https:\\/\\/www.fool.com\\/investing\\/2022\\/02\\/06\\/3-top-tech-stocks-to-buy-after-the-market-sell-off\\/?source=eptyholnk0000202&utm_source=yahoo-host&utm_medium=feed&utm_campaign=article",
        "6": "https:\\/\\/www.marketwatch.com\\/story\\/1-4-trillion-big-techs-pandemic-year-produces-mind-boggling-financial-results-11644096594?siteid=yhoof2",
        "7": "https:\\/\\/finance.yahoo.com\\/news\\/10-stocks-sell-according-motley-153740949.html",
        "8": "https:\\/\\/www.investors.com\\/market-trend\\/stock-market-today\\/dow-jones-futures-5-stocks-showing-strength-in-tricky-market-rally-what-to-do\\/?src=A00220",
        "9": "https:\\/\\/www.fool.com\\/investing\\/2022\\/02\\/06\\/3-top-tech-stocks-to-buy-after-the-market-sell-off\\/?source=financialcontent&utm_source=financialcontent&utm_medium=feed&utm_campaign=article",
        "10": "https:\\/\\/finance.yahoo.com\\/news\\/peloton-draws-interest-amazon-other-233034176.html",
        "11": "news.google.com\\/articles\\/CBMiXGh0dHBzOi8vd3d3LmZ4c3RyZWV0LmNvbS9uZXdzL2VsbGlvdHQtd2F2ZS12aWV3LWFwcGxlLWFhcGwtcmVzdW1lcy1oaWdoZXItdmlkZW8tMjAyMjAyMDQwMzUx0gFgaHR0cHM6Ly93d3cuZnhzdHJlZXQuY29tL2FtcC9uZXdzL2VsbGlvdHQtd2F2ZS12aWV3LWFwcGxlLWFhcGwtcmVzdW1lcy1oaWdoZXItdmlkZW8tMjAyMjAyMDQwMzUx?hl=en-US&gl=US&ceid=US%3Aen",
        "12": "https:\\/\\/www.fool.com\\/investing\\/2022\\/02\\/05\\/is-meta-platforms-stock-a-buy-now\\/?source=eptyholnk0000202&utm_source=yahoo-host&utm_medium=feed&utm_campaign=article",
        "13": "news.google.com\\/articles\\/CBMingFodHRwczovL3d3dy5kaXZpZGVuZGludmVzdG9yLmNvbS9kaXZpZGVuZC1uZXdzLzIwMjIwMjA1L2NvbS1zaC1iZW4taW50ZXJlc3QtY2hlc2FwZWFrZS1ncmFuaXRlLXdhc2gtdHJ1c3Qtb3RjYmItY2hrci1kZWNsYXJlZC1hLWRpdmlkZW5kLW9mLSQwLjA2NjctcGVyLXNoYXJlL9IBAA?hl=en-US&gl=US&ceid=US%3Aen",
        "14": "news.google.com\\/articles\\/CBMijwFodHRwczovL3d3dy5kaXZpZGVuZGludmVzdG9yLmNvbS9kaXZpZGVuZC1uZXdzLzIwMjIwMjA0L2Fwb2xsby1zZW5pb3ItZmxvYXRpbmctcmF0ZS1mdW5kLWluYy1ueXNlLWFmdC1kZWNsYXJlZC1hLWRpdmlkZW5kLW9mLSQwLjA4MDAtcGVyLXNoYXJlL9IBAA?hl=en-US&gl=US&ceid=US%3Aen",
        "15": "https:\\/\\/www.investors.com\\/news\\/apple-leads-five-stocks-near-buy-points-without-this-big-risk\\/?src=A00220",
        "16": "https:\\/\\/www.fool.com\\/investing\\/2022\\/02\\/05\\/3-reasons-to-buy-alphabet-stock-before-its-stock-s\\/?source=eptyholnk0000202&utm_source=yahoo-host&utm_medium=feed&utm_campaign=article",
        "17": "https:\\/\\/qz.com\\/2122686\\/facebook-was-on-the-defensive-until-the-metaverse-came-along\\/?utm_source=YPL",
        "18": "https:\\/\\/www.fool.com\\/investing\\/2022\\/02\\/05\\/2-growth-stocks-to-buy-and-hold-forever\\/?source=eptyholnk0000202&utm_source=yahoo-host&utm_medium=feed&utm_campaign=article",
        "19": "news.google.com\\/articles\\/CBMiS2h0dHBzOi8vcHVsc2UyLmNvbS9hcHBsZS1uYXNkYXEtYWFwbC1zdG9jay0yMDAtcHJpY2UtdGFyZ2V0LWZyb20tamVmZmVyaWVzL9IBT2h0dHBzOi8vcHVsc2UyLmNvbS9hcHBsZS1uYXNkYXEtYWFwbC1zdG9jay0yMDAtcHJpY2UtdGFyZ2V0LWZyb20tamVmZmVyaWVzL2FtcC8?hl=en-US&gl=US&ceid=US%3Aen",
        "20": "https:\\/\\/finance.yahoo.com\\/news\\/peloton-said-draw-takeover-interest-142011877.html",
        "21": "news.google.com\\/articles\\/CBMicGh0dHBzOi8vd3d3LmVsbGlvdHR3YXZldHJhZGVyLm5ldC9wL2FuYWx5c2lzL0NhcHR1cmluZy1BLUZpYm9uYWNjaS1UaW1lLVByaWNlLVNldHVwLUluLUFBUEwtMjAyMjAyMDQ3NTczNTA4Lmh0bWzSAQA?hl=en-US&gl=US&ceid=US%3Aen",
        "22": "https:\\/\\/www.bizjournals.com\\/triad\\/news\\/2022\\/02\\/05\\/triad-company-among-worlds-most-admired-companies.html?ana=yahoo",
        "23": "https:\\/\\/talkmarkets.com\\/content\\/stocks--equities\\/top-ten-stock-market-moving-headlines-from-last-week-alphabet-amazon-meta-earnings--more?post=343724",
        "24": "https:\\/\\/www.benzinga.com\\/news\\/22\\/02\\/25438230\\/apples-spring-event-could-come-earlier-than-anticipated-heres-what-you-should-know-about-the-launch",
        "25": "https:\\/\\/www.benzinga.com\\/markets\\/cryptocurrency\\/22\\/02\\/25439129\\/top-ten-stock-market-moving-headlines-from-last-week-alphabet-amazon-meta-snap-spotify-ear",
        "26": "https:\\/\\/talkmarkets.com\\/content\\/us-markets\\/2022-percentage-returns-of-largest-us-companies-by-market-capitalization-saturday-feb-5?post=343710",
        "27": "https:\\/\\/talkmarkets.com\\/content\\/stocks--equities\\/apple-focuses-on-small-businesses?post=343705",
        "28": "https:\\/\\/talkmarkets.com\\/content\\/us-markets\\/back-to-back-bank-of-england-raises-rates-again?post=343672",
        "29": "https:\\/\\/www.benzinga.com\\/trading-ideas\\/movers\\/22\\/02\\/25438813\\/this-advertising-company-has-a-better-5-year-return-than-tesla-ford-microsoft-apple-disney-n",
        "30": "https:\\/\\/www.fool.com\\/investing\\/2022\\/02\\/05\\/4-stocks-that-moved-the-market-in-january\\/?source=financialcontent&utm_source=financialcontent&utm_medium=feed&utm_campaign=article",
        "31": "https:\\/\\/www.fool.com\\/investing\\/2022\\/02\\/05\\/is-meta-platforms-stock-a-buy-now\\/?source=financialcontent&utm_source=financialcontent&utm_medium=feed&utm_campaign=article",
        "32": "https:\\/\\/talkmarkets.com\\/content\\/stocks--equities\\/cramer-highlights-key-differences-between-meta-and-amazon-following-earnings?post=343670",
        "33": "https:\\/\\/www.fool.com\\/investing\\/2022\\/02\\/05\\/2-growth-stocks-to-buy-and-hold-forever\\/?source=financialcontent&utm_source=financialcontent&utm_medium=feed&utm_campaign=article",
        "34": "https:\\/\\/www.investors.com\\/news\\/apple-leads-five-stocks-near-buy-points-without-this-big-risk\\/?src=A00224A&refcode=aflFinancialContent",
        "35": "https:\\/\\/www.fool.com\\/investing\\/2022\\/02\\/05\\/3-reasons-to-buy-alphabet-stock-before-its-stock-s\\/?source=financialcontent&utm_source=financialcontent&utm_medium=feed&utm_campaign=article",
        "36": "https:\\/\\/www.fool.com\\/investing\\/2022\\/02\\/05\\/why-elastic-stock-fell-in-january\\/?source=financialcontent&utm_source=financialcontent&utm_medium=feed&utm_campaign=article",
        "37": "https:\\/\\/www.fool.com\\/investing\\/2022\\/02\\/05\\/3-facts-about-shiba-inu-that-you-should-know\\/?source=financialcontent&utm_source=financialcontent&utm_medium=feed&utm_campaign=article",
        "38": "https:\\/\\/finance.yahoo.com\\/video\\/amazon-stock-track-largest-single-192102241.html",
        "39": "https:\\/\\/www.investors.com\\/videos\\/stock-market-rally-closes-with-strong-gains-amazon-apple-unitedhealth-in-focus\\/?src=A00224A&refcode=aflFinancialContent",
        "40": "https:\\/\\/finance.yahoo.com\\/news\\/nortonlifelock-nlok-q3-earnings-line-192707098.html",
        "41": "https:\\/\\/finance.yahoo.com\\/video\\/january-jobs-report-full-surprises-193454527.html",
        "42": "https:\\/\\/finance.yahoo.com\\/news\\/apple-aims-debut-low-cost-193822024.html",
        "43": "https:\\/\\/www.investors.com\\/etfs-and-funds\\/mutual-funds\\/best-mutual-funds-scoop-up-apple-nvidia-microsoft-as-new-market-uptrend-begins\\/?src=A00220",
        "44": "https:\\/\\/finance.yahoo.com\\/news\\/apple-plans-debut-low-cost-204528829.html",
        "45": "https:\\/\\/finance.yahoo.com\\/video\\/snap-seems-more-insulated-apple-205402334.html",
        "46": "https:\\/\\/finance.yahoo.com\\/news\\/big-tech-week-featured-alphabet-212244501.html",
        "47": "https:\\/\\/finance.yahoo.com\\/news\\/amazon-shares-jump-cloud-unit-212718450.html",
        "48": "https:\\/\\/talkmarkets.com\\/content\\/stocks--equities\\/tech-earnings-impress-in-the-face-of-economic-headwinds?post=343664",
        "49": "news.google.com\\/articles\\/CAIiEOKjicqcw1G5QUubQQaMsogqGQgEKhAIACoHCAow4uzwCjCF3bsCMIrOrwM?uo=CAUibmh0dHBzOi8vd3d3LmJsb29tYmVyZy5jb20vbmV3cy9hcnRpY2xlcy8yMDIyLTAyLTA0L21ldGEtYXNpZGUtbWVnYWNhcHMtcHV0LW9uLWEtZ3JlYXQtZWFybmluZ3Mtc2hvdy10ZWNoLXdhdGNo0gEA&hl=en-US&gl=US&ceid=US%3Aen",
        "50": "https:\\/\\/finance.yahoo.com\\/news\\/broad-peak-investment-holdings-ltd-183812804.html",
        "51": "news.google.com\\/articles\\/CAIiELG-s7SJRmwOQBqWgxA-QIEqFggEKg0IACoGCAowkqEGMJBZMMTouwY?uo=CAUiZmh0dHBzOi8vc2Vla2luZ2FscGhhLmNvbS9uZXdzLzM3OTYwNzQtYXBwbGUtYnJvYWRjb20tc3VjY2Vzc2Z1bC1pbi1nZXR0aW5nLXBhdGVudC1qdWRnZW1lbnQtdGhyb3duLW91dNIBAA&hl=en-US&gl=US&ceid=US%3Aen",
        "52": "https:\\/\\/www.thestreet.com\\/markets\\/snap-stock-soars-on-q4-profit-beat-ad-sales-user-growth-surprise?puc=yahoo&cm_ven=YAHOO",
        "53": "https:\\/\\/finance.yahoo.com\\/news\\/snap-pinterest-soar-results-dispel-220659371.html",
        "54": "https:\\/\\/www.investors.com\\/etfs-and-funds\\/mutual-funds\\/putnam-sustainable-investing-fund-outperforms\\/?src=A00220",
        "55": "https:\\/\\/www.fool.com\\/investing\\/2022\\/02\\/04\\/apples-holiday-quarter-was-nearly-flawless\\/?source=eptyholnk0000202&utm_source=yahoo-host&utm_medium=feed&utm_campaign=article",
        "56": "https:\\/\\/finance.yahoo.com\\/news\\/snap-set-to-open-42-higher-after-smashing-earnings-141840161.html",
        "57": "https:\\/\\/finance.yahoo.com\\/video\\/amazon-ceo-outside-insider-push-143001395.html",
        "58": "https:\\/\\/finance.yahoo.com\\/news\\/apple-broadcom-damages-trial-1-143709895.html",
        "59": "news.google.com\\/articles\\/CBMiiQFodHRwczovL3d3dy5kaXZpZGVuZGludmVzdG9yLmNvbS9kaXZpZGVuZC1uZXdzLzIwMjIwMjAzL2xwbC1maW5hbmNpYWwtaG9sZGluZ3MtaW5jLi1uYXNkYXEtbHBsYS1kZWNsYXJlZC1hLWRpdmlkZW5kLW9mLSQwLjI1MDAtcGVyLXNoYXJlL9IBAA?hl=en-US&gl=US&ceid=US%3Aen",
        "60": "news.google.com\\/articles\\/CBMijQFodHRwczovL3d3dy5kaXZpZGVuZGludmVzdG9yLmNvbS9kaXZpZGVuZC1uZXdzLzIwMjIwMjAzL3JlaW5zdXJhbmNlLWdyb3VwLW9mLWFtZXJpY2EsLWluYy4tbnlzZS1yZ2EtZGVjbGFyZWQtYS1kaXZpZGVuZC1vZi0kMC43MzAwLXBlci1zaGFyZS_SAQA?hl=en-US&gl=US&ceid=US%3Aen",
        "61": "https:\\/\\/finance.yahoo.com\\/news\\/empirical-finance-llc-buys-apple-143836691.html",
        "62": "news.google.com\\/articles\\/CBMihQFodHRwczovL3d3dy5kaXZpZGVuZGludmVzdG9yLmNvbS9kaXZpZGVuZC1uZXdzLzIwMjIwMjA0L2NvbW1lcmNlLWJhbmNzaGFyZXMtaW5jLW5hc2RhcS1jYnNoLWRlY2xhcmVkLWEtZGl2aWRlbmQtb2YtJDAuMjY1MC1wZXItc2hhcmUv0gEA?hl=en-US&gl=US&ceid=US%3Aen",
        "63": "https:\\/\\/finance.yahoo.com\\/news\\/fortive-ftv-q4-earnings-beat-145102212.html",
        "64": "https:\\/\\/finance.yahoo.com\\/video\\/amazon-stock-soars-meta-falls-150605207.html",
        "65": "https:\\/\\/investorplace.com\\/2022\\/02\\/the-best-is-yet-to-come-for-innovative-ev-play-lcid-stock\\/?utm_source=financialcontent&utm_medium=quotes",
        "66": "https:\\/\\/finance.yahoo.com\\/video\\/markets-undergoing-massive-change-rate-153542591.html",
        "67": "https:\\/\\/finance.yahoo.com\\/news\\/analysis-digital-ad-minnows-snap-154043064.html",
        "68": "https:\\/\\/finance.yahoo.com\\/news\\/apple-broadcom-win-1-1b-164636296.html",
        "69": "https:\\/\\/finance.yahoo.com\\/news\\/paylocity-pcty-q2-earnings-revenues-183206826.html",
        "70": "https:\\/\\/finance.yahoo.com\\/news\\/amazon-surges-eyes-wall-street-183535263.html",
        "71": "https:\\/\\/www.investors.com\\/news\\/technology\\/fb-stock-pummeled-by-apple-ad-changes\\/?src=A00220",
        "72": "https:\\/\\/finance.yahoo.com\\/news\\/modera-wealth-management-llc-buys-223825329.html",
        "73": "https:\\/\\/finance.yahoo.com\\/news\\/tech-earnings-impress-face-economic-214409392.html",
        "74": "http:\\/\\/www.marketwatch.com\\/news\\/story\\/metas-projections-facebook-advertising-point\\/story.aspx?guid=%7B20C05575%2D04D4%2DB545%2D7901%2D0DF9CBEA9599%7D",
        "75": "https:\\/\\/www.fool.com\\/investing\\/2022\\/02\\/04\\/why-meta-platforms-stock-is-plummeting-this-week\\/?source=financialcontent&utm_source=financialcontent&utm_medium=feed&utm_campaign=article",
        "76": "https:\\/\\/investorplace.com\\/hypergrowthinvesting\\/2022\\/02\\/grab-those-tech-stocks-following-amazons-blowout-earnings\\/?utm_source=financialcontent&utm_medium=quotes",
        "77": "https:\\/\\/investorplace.com\\/2022\\/02\\/metaverse-cryptos-show-signs-of-weakness-amid-metas-earnings-plunge\\/?utm_source=financialcontent&utm_medium=quotes",
        "78": "https:\\/\\/www.benzinga.com\\/trading-ideas\\/technicals\\/22\\/02\\/25423232\\/why-apple-disney-and-robinhood-stocks-look-set-for-new-highs",
        "79": "https:\\/\\/investorplace.com\\/2022\\/02\\/aapl-stock-apple-sound-investment-in-any-market\\/?utm_source=financialcontent&utm_medium=quotes",
        "80": "https:\\/\\/www.benzinga.com\\/news\\/earnings\\/22\\/02\\/25424022\\/jim-cramer-highlights-key-differences-between-meta-and-amazon-following-earnings",
        "81": "https:\\/\\/www.benzinga.com\\/government\\/22\\/02\\/25421858\\/apple-offers-3-commission-relief-in-dutch-app-store-case",
        "82": "https:\\/\\/www.benzinga.com\\/news\\/22\\/02\\/25424756\\/apple-broadcom-win-1-1b-caltech-patent-trial",
        "83": "http:\\/\\/www.marketwatch.com\\/news\\/story\\/weekend-reads-looking-ahead-after\\/story.aspx?guid=%7B20C05575%2D04D4%2DB545%2D7901%2DCCBE65BF94CF%7D",
        "84": "https:\\/\\/www.benzinga.com\\/markets\\/options\\/22\\/02\\/25427630\\/10-information-technology-stocks-whale-activity-in-todays-session",
        "85": "https:\\/\\/talkmarkets.com\\/content\\/options\\/most-active-equity-options-for-midday-friday-feb-4?post=343642",
        "86": "http:\\/\\/www.marketwatch.com\\/news\\/story\\/snap-earnings-spark-huge-rally\\/story.aspx?guid=%7B20C05575%2D04D4%2DB545%2D7902%2DE3638A542AE7%7D",
        "87": "https:\\/\\/www.investors.com\\/news\\/technology\\/fb-stock-pummeled-by-apple-ad-changes\\/?src=A00224A&refcode=aflFinancialContent",
        "88": "https:\\/\\/markets.financialcontent.com\\/stocks\\/news\\/read\\/42170138\\/spyr_inc._",
        "89": "https:\\/\\/www.fool.com\\/investing\\/2022\\/02\\/04\\/meta-platforms-stock-crashes-hit-with-several-head\\/?source=financialcontent&utm_source=financialcontent&utm_medium=feed&utm_campaign=article",
        "90": "https:\\/\\/investorplace.com\\/2022\\/02\\/will-axie-infinity-win-the-race-to-the-metaverse\\/?utm_source=financialcontent&utm_medium=quotes",
        "91": "https:\\/\\/www.investors.com\\/etfs-and-funds\\/mutual-funds\\/best-mutual-funds-scoop-up-apple-nvidia-microsoft-as-new-market-uptrend-begins\\/?src=A00224A&refcode=aflFinancialContent",
        "92": "https:\\/\\/www.fool.com\\/investing\\/2022\\/02\\/04\\/apples-holiday-quarter-was-nearly-flawless\\/?source=financialcontent&utm_source=financialcontent&utm_medium=feed&utm_campaign=article",
        "93": "https:\\/\\/www.investors.com\\/market-trend\\/stock-market-today\\/dow-jones-futures-5-stocks-showing-strength-in-tricky-market-rally-what-to-do\\/?src=A00224A&refcode=aflFinancialContent",
        "94": "news.google.com\\/articles\\/CAIiEHTu2KyzcyGrCxMaGs3Zu8gqFggEKg0IACoGCAowkqEGMJBZMMTouwY?uo=CAUiYmh0dHBzOi8vc2Vla2luZ2FscGhhLmNvbS9uZXdzLzM3OTYyMTEtYXBwbGUtdG8tdW52ZWlsLW5ldy1pcGhvbmUtc2UtaXBhZC1haXItaW4tZWFybHktbWFyY2gtcmVwb3J00gEA&hl=en-US&gl=US&ceid=US%3Aen",
        "95": "https:\\/\\/finance.yahoo.com\\/news\\/column-beginning-facebooks-downfall-223343937.html",
        "96": "news.google.com\\/articles\\/CBMihAFodHRwczovL3d3dy5kaXZpZGVuZGludmVzdG9yLmNvbS9kaXZpZGVuZC1uZXdzLzIwMjIwMjAzL3p1cm4td2F0ZXItc29sdXRpb25zLWNvcnAtbnlzZS16d3MtZGVjbGFyZWQtYS1kaXZpZGVuZC1vZi0kMC4wMzAwLXBlci1zaGFyZS_SAQA?hl=en-US&gl=US&ceid=US%3Aen",
        "97": "news.google.com\\/articles\\/CBMieWh0dHBzOi8vd3d3Lm5hc2RhcS5jb20vYXJ0aWNsZXMvYWZ0ZXItaG91cnMtbW9zdC1hY3RpdmUtZm9yLWZlYi00LTIwMjItJTNBLWNvdHktcXFxLXNoeS1hYXBsLWJhYy1jLW1zZnQtbmVlLXNuYXAtcG0tY21jc2HSAX1odHRwczovL3d3dy5uYXNkYXEuY29tL2FydGljbGVzL2FmdGVyLWhvdXJzLW1vc3QtYWN0aXZlLWZvci1mZWItNC0yMDIyLSUzQS1jb3R5LXFxcS1zaHktYWFwbC1iYWMtYy1tc2Z0LW5lZS1zbmFwLXBtLWNtY3NhP2FtcA?hl=en-US&gl=US&ceid=US%3Aen",
        "98": "https:\\/\\/www.investors.com\\/research\\/peloton-stock-buy-now\\/?src=A00220",
        "99": "news.google.com\\/articles\\/CAIiEGRzfdHOJw7KB_FXbDJApsEqFggEKg0IACoGCAowkqEGMJBZMNL5vAY?uo=CAUiTWh0dHBzOi8vc2Vla2luZ2FscGhhLmNvbS9hcnRpY2xlLzQ0ODQzNzYtYXBwbGUtbWV0YXZlcnNlLWhlYWRzZXRzLWdhbWVjaGFuZ2Vy0gEA&hl=en-US&gl=US&ceid=US%3Aen",
        "100": "https:\\/\\/www.benzinga.com\\/analyst-ratings\\/analyst-color\\/22\\/02\\/25418054\\/read-how-analysts-reacted-to-snaps-q4-results",
        "101": "http:\\/\\/www.marketwatch.com\\/news\\/story\\/these-two-sp-500-stocks\\/story.aspx?guid=%7B20C05575%2D04D4%2DB545%2D7906%2DC8D8BE1368EB%7D",
        "102": "news.google.com\\/articles\\/CAIiEGYnsJPmYfk-lJf4o68cihcqGQgEKhAIACoHCAowt8yYCzDI1rADMNqB0gY?hl=en-US&gl=US&ceid=US%3Aen",
        "103": "news.google.com\\/articles\\/CBMiXWh0dHBzOi8vd3d3LnRoZXN0cmVldC5jb20vYXBwbGUvbmV3cy9hcHBsZS1zdG9jay1qaW0tY3JhbWVycy1pZGVhbC1lbnRyeS1wcmljZS1hbmQtd2hhdC10by1kb9IBYmh0dHBzOi8vd3d3LnRoZXN0cmVldC5jb20vYXBwbGUvLmFtcC9uZXdzL2FwcGxlLXN0b2NrLWppbS1jcmFtZXJzLWlkZWFsLWVudHJ5LXByaWNlLWFuZC13aGF0LXRvLWRv?hl=en-US&gl=US&ceid=US%3Aen",
        "104": "https:\\/\\/talkmarkets.com\\/content\\/stocks--equities\\/elliott-wave-view-apple-aapl-resumes-higher?post=343574",
        "105": "https:\\/\\/investorplace.com\\/2022\\/02\\/when-it-comes-to-spy-stock-every-pullback-is-an-opportunity\\/?utm_source=financialcontent&utm_medium=quotes",
        "106": "https:\\/\\/www.benzinga.com\\/trading-ideas\\/long-ideas\\/22\\/02\\/25415195\\/jim-cramer-says-facebook-parent-meta-has-become-undervalued-but-heres-why-you-should-wai",
        "107": "http:\\/\\/www.marketwatch.com\\/news\\/story\\/way-picking-value-stocks-has\\/story.aspx?guid=%7B20C05575%2D04D4%2DB545%2D7900%2DF509D2BE1FC5%7D",
        "108": "https:\\/\\/www.investors.com\\/etfs-and-funds\\/mutual-funds\\/putnam-sustainable-investing-fund-outperforms\\/?src=A00224A&refcode=aflFinancialContent",
        "109": "https:\\/\\/www.bizjournals.com\\/triangle\\/news\\/2022\\/02\\/03\\/tbj-2022-space-award-winners-commercial-real-estae.html?ana=yahoo",
        "110": "https:\\/\\/www.fool.com\\/investing\\/2022\\/02\\/03\\/senate-committee-passes-sweeping-app-store-reform\\/?source=eptyholnk0000202&utm_source=yahoo-host&utm_medium=feed&utm_campaign=article",
        "111": "https:\\/\\/finance.yahoo.com\\/news\\/amazon-trots-youtube-sized-advertising-010059656.html",
        "112": "https:\\/\\/www.marketwatch.com\\/story\\/meta-stock-plummets-after-earningscan-company-salvage-any-growth-out-of-facebook-platform-11643896586?siteid=yhoof2",
        "113": "https:\\/\\/finance.yahoo.com\\/news\\/meta-stock-blowup-is-an-important-investing-lesson-strategist-202112198.html",
        "114": "https:\\/\\/finance.yahoo.com\\/news\\/amazon-adds-huge-season-faangs-233523434.html",
        "115": "https:\\/\\/finance.yahoo.com\\/video\\/apple-privacy-measures-cost-facebook-143520201.html",
        "116": "https:\\/\\/finance.yahoo.com\\/news\\/meta-plunges-facebook-users-stall-211912823.html",
        "117": "https:\\/\\/finance.yahoo.com\\/news\\/1-u-senate-panel-votes-165101646.html",
        "118": "https:\\/\\/finance.yahoo.com\\/video\\/meta-earnings-dropped-lot-negative-163356847.html",
        "119": "https:\\/\\/finance.yahoo.com\\/news\\/u-senate-panel-votes-approve-162855662.html",
        "120": "https:\\/\\/finance.yahoo.com\\/news\\/apple-privacy-changes-hammer-social-media-stocks-beyond-meta-162759059.html",
        "121": "https:\\/\\/finance.yahoo.com\\/video\\/u-economic-recovery-entering-strange-155130928.html",
        "122": "https:\\/\\/finance.yahoo.com\\/video\\/market-check-stocks-fall-meta-153802185.html",
        "123": "https:\\/\\/finance.yahoo.com\\/video\\/fed-tightening-does-not-signify-153240974.html",
        "124": "https:\\/\\/finance.yahoo.com\\/news\\/qualcomm-gives-rosy-forecast-smartphone-213955755.html",
        "125": "https:\\/\\/finance.yahoo.com\\/video\\/meta-stock-presenting-very-good-151131002.html",
        "126": "https:\\/\\/www.fool.com\\/investing\\/2022\\/02\\/03\\/5-red-flags-for-meta-platforms-future\\/?source=eptyholnk0000202&utm_source=yahoo-host&utm_medium=feed&utm_campaign=article",
        "127": "https:\\/\\/finance.yahoo.com\\/news\\/amazon-earnings-162246834.html",
        "128": "https:\\/\\/finance.yahoo.com\\/news\\/why-facebook-parent-metas-stock-is-getting-crushed-141137947.html",
        "129": "https:\\/\\/www.barrons.com\\/articles\\/why-the-winners-and-losers-from-metas-bombshell-earnings-will-become-clearer-today-51643894709?siteid=yhoof2",
        "130": "https:\\/\\/www.fool.com\\/investing\\/2022\\/02\\/03\\/3-robinhood-stocks-to-buy-and-hold-forever\\/?source=eptyholnk0000202&utm_source=yahoo-host&utm_medium=feed&utm_campaign=article",
        "131": "https:\\/\\/finance.yahoo.com\\/news\\/us-stocks-wall-st-futures-132319622.html",
        "132": "https:\\/\\/www.fool.com\\/investing\\/2022\\/02\\/03\\/heres-why-apple-great-stock-to-hold-for-decades\\/?source=eptyholnk0000202&utm_source=yahoo-host&utm_medium=feed&utm_campaign=article",
        "133": "https:\\/\\/www.fool.com\\/investing\\/2022\\/02\\/03\\/better-buy-blackberry-vs-apple\\/?source=eptyholnk0000202&utm_source=yahoo-host&utm_medium=feed&utm_campaign=article",
        "134": "https:\\/\\/finance.yahoo.com\\/news\\/exclusive-iphone-flaw-exploited-second-110514645.html",
        "135": "https:\\/\\/finance.yahoo.com\\/news\\/metas-miss-creates-big-tech-110156841.html",
        "136": "https:\\/\\/finance.yahoo.com\\/news\\/apple-makes-progress-india-iphone-043013486.html",
        "137": "https:\\/\\/finance.yahoo.com\\/news\\/korea-seeks-improved-compliance-plans-094306641.html",
        "138": "https:\\/\\/www.fool.com\\/investing\\/2022\\/02\\/03\\/why-meta-platforms-stock-cratered-on-thursday\\/?source=eptyholnk0000202&utm_source=yahoo-host&utm_medium=feed&utm_campaign=article",
        "139": "https:\\/\\/finance.yahoo.com\\/news\\/8-best-key-finders-help-143312434.html",
        "140": "https:\\/\\/www.investors.com\\/etfs-and-funds\\/sectors\\/sp500-companies-stockpile-1-trillion-cash-investors-want-it\\/?src=A00220",
        "141": "https:\\/\\/www.investopedia.com\\/why-disney-plus-holds-edge-over-other-streamers-5218032?utm_campaign=quote-yahoo&utm_source=yahoo&utm_medium=referral",
        "142": "https:\\/\\/finance.yahoo.com\\/news\\/neumann-capital-management-llc-buys-223832568.html",
        "143": "https:\\/\\/finance.yahoo.com\\/news\\/facebook-owner-meta-set-200-094832161.html",
        "144": "https:\\/\\/finance.yahoo.com\\/news\\/how-apple-app-tracking-transparency-works-162225922-220141435.html",
        "145": "https:\\/\\/finance.yahoo.com\\/news\\/us-stocks-wall-st-ends-215752279.html",
        "146": "https:\\/\\/www.barrons.com\\/articles\\/amazon-earnings-facebook-alphabet-51643909876?siteid=yhoof2",
        "147": "https:\\/\\/finance.yahoo.com\\/video\\/amazon-competition-getting-intense-lead-214215301.html",
        "148": "https:\\/\\/finance.yahoo.com\\/video\\/tech-guidance-more-disappointing-earnings-212751763.html",
        "149": "https:\\/\\/finance.yahoo.com\\/video\\/stocks-accelerate-losses-market-close-211946070.html",
        "150": "https:\\/\\/finance.yahoo.com\\/news\\/snap-current-quarter-outlook-beats-211914294.html",
        "151": "https:\\/\\/finance.yahoo.com\\/news\\/1-snap-current-quarter-outlook-211726016.html",
        "152": "https:\\/\\/finance.yahoo.com\\/news\\/snap-beats-revenue-estimates-recovery-211017877.html",
        "153": "https:\\/\\/www.fool.com\\/investing\\/2022\\/02\\/03\\/meta-platforms-plummeting-stock-is-it-a-buy\\/?source=eptyholnk0000202&utm_source=yahoo-host&utm_medium=feed&utm_campaign=article",
        "154": "https:\\/\\/finance.yahoo.com\\/news\\/regulators-cant-touch-facebookbut-the-market-can-205338312.html",
        "155": "https:\\/\\/finance.yahoo.com\\/video\\/market-strategist-technical-dynamics-become-204210581.html",
        "156": "https:\\/\\/finance.yahoo.com\\/news\\/apple-app-store-grip-challenged-175303498.html",
        "157": "https:\\/\\/finance.yahoo.com\\/news\\/m-d-sass-investors-services-193801500.html",
        "158": "https:\\/\\/www.bizjournals.com\\/austin\\/news\\/2022\\/02\\/03\\/facebook-apple-is-costing-us-billions.html?ana=yahoo",
        "159": "https:\\/\\/finance.yahoo.com\\/video\\/big-tech-stocks-moving-structural-193039849.html",
        "160": "https:\\/\\/www.marketwatch.com\\/story\\/senate-committee-passes-bill-aimed-at-apple-googles-app-stores-2022-02-03?siteid=yhoof2",
        "161": "https:\\/\\/finance.yahoo.com\\/news\\/anfield-capital-management-llc-buys-183806880.html",
        "162": "https:\\/\\/finance.yahoo.com\\/video\\/tech-stocks-tumble-following-huge-182305366.html",
        "163": "https:\\/\\/www.barrons.com\\/articles\\/lumentum-stock-apple-supply-chain-51643911324?siteid=yhoof2",
        "164": "https:\\/\\/finance.yahoo.com\\/news\\/korea-seeks-improved-compliance-plans-093600865.html",
        "165": "news.google.com\\/articles\\/CBMieWh0dHBzOi8vd3d3LmRpdmlkZW5kaW52ZXN0b3IuY29tL2RpdmlkZW5kLW5ld3MvMjAyMjAyMDIvaGF3a2lucy1pbmMtbmFzZGFxLWh3a24tZGVjbGFyZWQtYS1kaXZpZGVuZC1vZi0kMC4xNDAwLXBlci1zaGFyZS_SAQA?hl=en-US&gl=US&ceid=US%3Aen",
        "166": "news.google.com\\/articles\\/CBMiiAFodHRwczovL3d3dy5kaXZpZGVuZGludmVzdG9yLmNvbS9kaXZpZGVuZC1uZXdzLzIwMjIwMjAzL2ludGVyY29udGluZW50YWwtZXhjaGFuZ2UtaW5jLW55c2UtaWNlLWRlY2xhcmVkLWEtZGl2aWRlbmQtb2YtJDAuMzgwMC1wZXItc2hhcmUv0gEA?hl=en-US&gl=US&ceid=US%3Aen",
        "167": "https:\\/\\/www.benzinga.com\\/news\\/earnings\\/22\\/02\\/25414894\\/the-nasdaq-had-its-worse-day-since-october-2020-thanks-in-part-to-meta-platforms",
        "168": "news.google.com\\/articles\\/CBMiXGh0dHBzOi8vd3d3LnRoZXN0cmVldC5jb20vYXBwbGUvc3RvY2svYWFwbC1zdG9jay1wcmVtYXJrZXQtd2h5LWl0LXdpbGwtaGF2ZS1hLXJvdWdoLXRodXJzZGF50gFhaHR0cHM6Ly93d3cudGhlc3RyZWV0LmNvbS9hcHBsZS8uYW1wL3N0b2NrL2FhcGwtc3RvY2stcHJlbWFya2V0LXdoeS1pdC13aWxsLWhhdmUtYS1yb3VnaC10aHVyc2RheQ?hl=en-US&gl=US&ceid=US%3Aen",
        "169": "news.google.com\\/articles\\/CBMidmh0dHBzOi8vd3d3LmRpdmlkZW5kaW52ZXN0b3IuY29tL2RpdmlkZW5kLW5ld3MvMjAyMjAyMDIva2xhLWNvcnAtbmFzZGFxLWtsYWMtZGVjbGFyZWQtYS1kaXZpZGVuZC1vZi0kMS4wNTAwLXBlci1zaGFyZS_SAQA?hl=en-US&gl=US&ceid=US%3Aen",
        "170": "news.google.com\\/articles\\/CBMiWmh0dHBzOi8vd3d3LmZvb2wuY29tL2ludmVzdGluZy8yMDIyLzAyLzAzL2hlcmVzLXdoeS1hcHBsZS1ncmVhdC1zdG9jay10by1ob2xkLWZvci1kZWNhZGVzL9IBAA?hl=en-US&gl=US&ceid=US%3Aen",
        "171": "news.google.com\\/articles\\/CBMihAFodHRwczovL3d3dy5kaXZpZGVuZGludmVzdG9yLmNvbS9kaXZpZGVuZC1uZXdzLzIwMjIwMjAyL3JvY2t3ZWxsLWF1dG9tYXRpb24sLWluYy4tbnlzZS1yb2stZGVjbGFyZWQtYS1kaXZpZGVuZC1vZi0kMS4xMjAwLXBlci1zaGFyZS_SAQA?hl=en-US&gl=US&ceid=US%3Aen",
        "172": "news.google.com\\/articles\\/CBMiqwFodHRwczovL3d3dy5kaXZpZGVuZGludmVzdG9yLmNvbS9kaXZpZGVuZC1uZXdzLzIwMjIwMjAyL2dsb2JhbC14LW1zY2ktc3VwZXJkaXZpZGVuZC1lbWVyZ2luZy1tYXJrZXRzLWV0Zi1nbG9iYWwteC1mdW5kcy1ueXNlLXNkZW0tZGVjbGFyZWQtYS1kaXZpZGVuZC1vZi0kMC4wODgwLXBlci1zaGFyZS_SAQA?hl=en-US&gl=US&ceid=US%3Aen",
        "173": "news.google.com\\/articles\\/CAIiEApy7BJn6nGaKOIn0v2b0skqGQgEKhAIACoHCAow4uzwCjCF3bsCMIrOrwM?uo=CAUic2h0dHBzOi8vd3d3LmJsb29tYmVyZy5jb20vbmV3cy9hcnRpY2xlcy8yMDIyLTAyLTAyL2FwcGxlLXVyZ2VzLXNlbmF0ZS10by1yZWplY3QtYmlsbC10aGF0LWFsbG93cy1vdXRzaWRlLWFwcC1zdG9yZXPSAQA&hl=en-US&gl=US&ceid=US%3Aen",
        "174": "news.google.com\\/articles\\/CBMiemh0dHBzOi8vd3d3LmRpdmlkZW5kaW52ZXN0b3IuY29tL2RpdmlkZW5kLW5ld3MvMjAyMjAyMDIva2VtcGVyLWNvcnAtZGUtbnlzZS1rbXByLWRlY2xhcmVkLWEtZGl2aWRlbmQtb2YtJDAuMzEwMC1wZXItc2hhcmUv0gEA?hl=en-US&gl=US&ceid=US%3Aen",
        "175": "news.google.com\\/articles\\/CBMihQFodHRwczovL3d3dy5kaXZpZGVuZGludmVzdG9yLmNvbS9kaXZpZGVuZC1uZXdzLzIwMjIwMjAyL2dyYWZ0ZWNoLWludGVybmF0aW9uYWwtbHRkLW55c2UtZWFmLWRlY2xhcmVkLWEtZGl2aWRlbmQtb2YtJDAuMDEwMC1wZXItc2hhcmUv0gEA?hl=en-US&gl=US&ceid=US%3Aen",
        "176": "news.google.com\\/articles\\/CBMiUWh0dHBzOi8vZmluYW5jZS55YWhvby5jb20vbmV3cy9hbmZpZWxkLWNhcGl0YWwtbWFuYWdlbWVudC1sbGMtYnV5cy0xODM4MDY4ODAuaHRtbNIBWWh0dHBzOi8vZmluYW5jZS55YWhvby5jb20vYW1waHRtbC9uZXdzL2FuZmllbGQtY2FwaXRhbC1tYW5hZ2VtZW50LWxsYy1idXlzLTE4MzgwNjg4MC5odG1s?hl=en-US&gl=US&ceid=US%3Aen",
        "177": "news.google.com\\/articles\\/CBMibWh0dHBzOi8vd3d3LmJlbnppbmdhLmNvbS9uZXdzLzIyLzAyLzI1Mzg5MTQxL3lldC1hbm90aGVyLWlzcmFlbGktZmlybS1hcGFydC1mcm9tLW5zby1ncm91cC1oYWQtaGFja2VkLWlwaG9uZXPSAS1odHRwczovL3d3dy5iZW56aW5nYS5jb20vYW1wL2NvbnRlbnQvMjUzODkxNDE?hl=en-US&gl=US&ceid=US%3Aen",
        "178": "news.google.com\\/articles\\/CBMidmh0dHBzOi8vd3d3LmRpdmlkZW5kaW52ZXN0b3IuY29tL2RpdmlkZW5kLW5ld3MvMjAyMjAyMDMvZGVsdXhlLWNvcnAtbnlzZS1kbHgtZGVjbGFyZWQtYS1kaXZpZGVuZC1vZi0kMC4zMDAwLXBlci1zaGFyZS_SAQA?hl=en-US&gl=US&ceid=US%3Aen",
        "179": "news.google.com\\/articles\\/CBMia2h0dHBzOi8vd3d3LmJlbnppbmdhLmNvbS9uZXdzLzIyLzAyLzI1Mzk4OTYzL2hvdy1tdWNoLXdpbGwtYXBwbGVzLWlvcy1wcml2YWN5LWNoYW5nZXMtY29zdC1mYWNlYm9vay1pbi0yMDIy0gEtaHR0cHM6Ly93d3cuYmVuemluZ2EuY29tL2FtcC9jb250ZW50LzI1Mzk4OTYz?hl=en-US&gl=US&ceid=US%3Aen",
        "180": "news.google.com\\/articles\\/CBMiSWh0dHBzOi8vZmluYW5jZS55YWhvby5jb20vbmV3cy9tLWQtc2Fzcy1pbnZlc3RvcnMtc2VydmljZXMtMTkzODAxNTAwLmh0bWzSAVFodHRwczovL2ZpbmFuY2UueWFob28uY29tL2FtcGh0bWwvbmV3cy9tLWQtc2Fzcy1pbnZlc3RvcnMtc2VydmljZXMtMTkzODAxNTAwLmh0bWw?hl=en-US&gl=US&ceid=US%3Aen",
        "181": "news.google.com\\/articles\\/CBMigQFodHRwczovL3d3dy5kaXZpZGVuZGludmVzdG9yLmNvbS9kaXZpZGVuZC1uZXdzLzIwMjIwMjAxL2dpbGVhZC1zY2llbmNlcy1pbmMtbmFzZGFxLWdpbGQtZGVjbGFyZWQtYS1kaXZpZGVuZC1vZi0kMC43MzAwLXBlci1zaGFyZS_SAQA?hl=en-US&gl=US&ceid=US%3Aen",
        "182": "news.google.com\\/articles\\/CBMiSGh0dHBzOi8vZmluYW5jZS55YWhvby5jb20vbmV3cy9mYW1pbHktZmlybS1pbmMtYnV5cy1hcHBsZS0xNDM4MTYwNzUuaHRtbNIBUGh0dHBzOi8vZmluYW5jZS55YWhvby5jb20vYW1waHRtbC9uZXdzL2ZhbWlseS1maXJtLWluYy1idXlzLWFwcGxlLTE0MzgxNjA3NS5odG1s?hl=en-US&gl=US&ceid=US%3Aen",
        "183": "news.google.com\\/articles\\/CBMidmh0dHBzOi8vd3d3LmRpdmlkZW5kaW52ZXN0b3IuY29tL2RpdmlkZW5kLW5ld3MvMjAyMjAyMDIvY29ybmluZy1pbmMtbnlzZS1nbHctZGVjbGFyZWQtYS1kaXZpZGVuZC1vZi0kMC4yNzAwLXBlci1zaGFyZS_SAQA?hl=en-US&gl=US&ceid=US%3Aen",
        "184": "news.google.com\\/articles\\/CBMiSmh0dHBzOi8vZmluYW5jZS55YWhvby5jb20vbmV3cy93YXJyZW4tYnVmZmV0dC13aW5zLTIwMjItZXRmLTEzMDAwMTkxMC5odG1s0gFSaHR0cHM6Ly9maW5hbmNlLnlhaG9vLmNvbS9hbXBodG1sL25ld3Mvd2FycmVuLWJ1ZmZldHQtd2lucy0yMDIyLWV0Zi0xMzAwMDE5MTAuaHRtbA?hl=en-US&gl=US&ceid=US%3Aen",
        "185": "news.google.com\\/articles\\/CBMiU2h0dHBzOi8vd3d3Lm5hc2RhcS5jb20vYXJ0aWNsZXMvd2hhdC1pcy1hcHBsZS1pbmMucy1uYXNkYXElM0FhYXBsLXNoYXJlLXByaWNlLWRvaW5n0gFXaHR0cHM6Ly93d3cubmFzZGFxLmNvbS9hcnRpY2xlcy93aGF0LWlzLWFwcGxlLWluYy5zLW5hc2RhcSUzQWFhcGwtc2hhcmUtcHJpY2UtZG9pbmc_YW1w?hl=en-US&gl=US&ceid=US%3Aen",
        "186": "news.google.com\\/articles\\/CBMie2h0dHBzOi8vd3d3LmRpdmlkZW5kaW52ZXN0b3IuY29tL2RpdmlkZW5kLW5ld3MvMjAyMjAyMDEvaW1wZXJpYWwtb2lsLWx0ZC1hbWV4LWltby1kZWNsYXJlZC1hLWRpdmlkZW5kLW9mLSQwLjM0MDAtcGVyLXNoYXJlL9IBAA?hl=en-US&gl=US&ceid=US%3Aen",
        "187": "news.google.com\\/articles\\/CBMiaWh0dHBzOi8vc3RvY2tuZXdzLmNvbS9uZXdzL2FhcGwtZml2Zy1hZGktZXJpYy1zd2tzLXFjb20tNWctaW5kdXN0cnktaW4tMjAyMi10aGUtY29tcGxldGUtaW52ZXN0b3JzLWd1aWRlL9IBAA?hl=en-US&gl=US&ceid=US%3Aen",
        "188": "news.google.com\\/articles\\/CAIiEJpev5bka0WcDmbiXuK2teAqFggEKg0IACoGCAowkqEGMJBZMNL5vAY?hl=en-US&gl=US&ceid=US%3Aen",
        "189": "news.google.com\\/articles\\/CAIiEOCxbbU9P2sqjP9jUAACUeQqFggEKg0IACoGCAowkqEGMJBZMMTouwY?uo=CAUibWh0dHBzOi8vc2Vla2luZ2FscGhhLmNvbS9uZXdzLzM3OTQxMzctYXBwbGUtMTAtcS1zaG93cy1zaWducy1vZi1ncm9zcy1tYXJnaW4tZXhwYW5zaW9uLWltcHJvdmluZy1zdXBwbHktY2hhaW7SAQA&hl=en-US&gl=US&ceid=US%3Aen",
        "190": "news.google.com\\/articles\\/CAIiEDIQa966757ubSNRfSKt9B0qFggEKg0IACoGCAowkqEGMJBZMNL5vAY?uo=CAUiWGh0dHBzOi8vc2Vla2luZ2FscGhhLmNvbS9hcnRpY2xlLzQ0ODMyOTMtYXBwbGUtcm9idXN0LWNhc2gtYmFzZWQtc291cmNlcy1pbnRyaW5zaWMtdmFsdWXSAQA&hl=en-US&gl=US&ceid=US%3Aen",
        "191": "news.google.com\\/articles\\/CBMiUmh0dHBzOi8vZmluYW5jZS55YWhvby5jb20vbmV3cy9vY2NpZGVudGFsLWFzc2V0LW1hbmFnZW1lbnQtbGxjLWJ1eXMtMTQzODE3OTUxLmh0bWzSAVpodHRwczovL2ZpbmFuY2UueWFob28uY29tL2FtcGh0bWwvbmV3cy9vY2NpZGVudGFsLWFzc2V0LW1hbmFnZW1lbnQtbGxjLWJ1eXMtMTQzODE3OTUxLmh0bWw?hl=en-US&gl=US&ceid=US%3Aen",
        "192": "news.google.com\\/articles\\/CBMimwFodHRwczovL3d3dy5kaXZpZGVuZGludmVzdG9yLmNvbS9kaXZpZGVuZC1uZXdzLzIwMjIwMjAxL2NyZWRpdC1zdWlzc2UtYXNzZXQtbWFuYWdlbWVudC1pbmNvbWUtZnVuZCwtaW5jLi1hbWV4LWNpay1kZWNsYXJlZC1hLWRpdmlkZW5kLW9mLSQwLjAyMjUtcGVyLXNoYXJlL9IBAA?hl=en-US&gl=US&ceid=US%3Aen",
        "193": "news.google.com\\/articles\\/CBMiiAFodHRwczovL3d3dy5kaXZpZGVuZGludmVzdG9yLmNvbS9kaXZpZGVuZC1uZXdzLzIwMjIwMjAxL3NhYnJhLWhlYWx0aC1jYXJlLXJlaXQtaW5jLW5hc2RhcS1zYnJhLWRlY2xhcmVkLWEtZGl2aWRlbmQtb2YtJDAuMzAwMC1wZXItc2hhcmUv0gEA?hl=en-US&gl=US&ceid=US%3Aen",
        "194": "news.google.com\\/articles\\/CBMifWh0dHBzOi8vd3d3LmRpdmlkZW5kaW52ZXN0b3IuY29tL2RpdmlkZW5kLW5ld3MvMjAyMjAyMDEvc3VuY29rZS1lbmVyZ3ktaW5jLW55c2Utc3hjLWRlY2xhcmVkLWEtZGl2aWRlbmQtb2YtJDAuMDYwMC1wZXItc2hhcmUv0gEA?hl=en-US&gl=US&ceid=US%3Aen",
        "195": "news.google.com\\/articles\\/CBMiTmh0dHBzOi8vZmluYW5jZS55YWhvby5jb20vbmV3cy9kZWZlbmRlci1jYXBpdGFsLWxsYy1idXlzLWxhbmRlYy0xODM4MTE5ODAuaHRtbNIBVmh0dHBzOi8vZmluYW5jZS55YWhvby5jb20vYW1waHRtbC9uZXdzL2RlZmVuZGVyLWNhcGl0YWwtbGxjLWJ1eXMtbGFuZGVjLTE4MzgxMTk4MC5odG1s?hl=en-US&gl=US&ceid=US%3Aen",
        "196": "news.google.com\\/articles\\/CBMiTWh0dHBzOi8vZmluYW5jZS55YWhvby5jb20vbmV3cy9iY2dtLXdlYWx0aC1tYW5hZ2VtZW50LWxsYy1idXlzLTE4MzgyMzc5OC5odG1s0gFVaHR0cHM6Ly9maW5hbmNlLnlhaG9vLmNvbS9hbXBodG1sL25ld3MvYmNnbS13ZWFsdGgtbWFuYWdlbWVudC1sbGMtYnV5cy0xODM4MjM3OTguaHRtbA?hl=en-US&gl=US&ceid=US%3Aen",
        "197": "news.google.com\\/articles\\/CBMihwFodHRwczovL3d3dy5kaXZpZGVuZGludmVzdG9yLmNvbS9kaXZpZGVuZC1uZXdzLzIwMjIwMjAxL29yZC1hdWRpb2NvZGVzLWx0ZC1pc3JhZWwtbmFzZGFxLWF1ZGMtZGVjbGFyZWQtYS1kaXZpZGVuZC1vZi0kMC4xODAwLXBlci1zaGFyZS_SAQA?hl=en-US&gl=US&ceid=US%3Aen",
        "198": "news.google.com\\/articles\\/CBMiYmh0dHBzOi8vbmV3cy5hbHBoYXN0cmVldC5jb20vYXBwbGUtYWFwbC10YW1lcy1zdXBwbHktY2hhaW4taHVyZGxlcy1sb29rcy1oZWFkZWQtZm9yLWEtcmVjb3JkLXllYXIv0gEA?hl=en-US&gl=US&ceid=US%3Aen",
        "199": "news.google.com\\/articles\\/CBMijAFodHRwczovL3d3dy5kaXZpZGVuZGludmVzdG9yLmNvbS9kaXZpZGVuZC1uZXdzLzIwMjIwMTMxL2NvcmUtYmQtZmQtY2wtaS1tYWRpc29uLWZ1bmRzLW5hc2RhcS1tYm9peC1kZWNsYXJlZC1hLWRpdmlkZW5kLW9mLSQwLjAxNDUtcGVyLXNoYXJlL9IBAA?hl=en-US&gl=US&ceid=US%3Aen",
        "200": "news.google.com\\/articles\\/CAIiEBVu3vcRNX1cjK_1tPCBKBsqFggEKg0IACoGCAowkqEGMJBZMMTouwY?uo=CAUib2h0dHBzOi8vc2Vla2luZ2FscGhhLmNvbS9uZXdzLzM3OTM1NjgtYXBwbGUtc2Vlcy0yNS1ncm93dGgtaW4tZXVyb3BlYW4tc21hcnRwaG9uZS1pbi0yMDIxLWNvdW50ZXJwb2ludC1yZXNlYXJjaNIBAA&hl=en-US&gl=US&ceid=US%3Aen",
        "201": "news.google.com\\/articles\\/CAIiEP9UHNfBHMtP7Ai3LgKm9k4qMwgEKioIACIQT4VDYmxpaDN7y5JvscS9FioUCAoiEE-FQ2JsaWgze8uSb7HEvRYw6_HMBg?uo=CAUiNmh0dHBzOi8vd3d3Lm5hc2RhcS5jb20vYXJ0aWNsZXMvZG93LW1vdmVycyUzQS1kb3ctYWFwbNIBAA&hl=en-US&gl=US&ceid=US%3Aen",
        "202": "news.google.com\\/articles\\/CBMigwFodHRwczovL3d3dy5kaXZpZGVuZGludmVzdG9yLmNvbS9kaXZpZGVuZC1uZXdzLzIwMjIwMTMxL2Vzc2VudGlhbC11dGlsaXRpZXMtaW5jLW55c2Utd3RyZy1kZWNsYXJlZC1hLWRpdmlkZW5kLW9mLSQwLjI2ODItcGVyLXNoYXJlL9IBAA?hl=en-US&gl=US&ceid=US%3Aen",
        "203": "news.google.com\\/articles\\/CBMiiAFodHRwczovL3d3dy5kaXZpZGVuZGludmVzdG9yLmNvbS9kaXZpZGVuZC1uZXdzLzIwMjIwMTMxL2NsLWEtYmF5LWNvbW11bml0eS1iYW5jb3JwLW90Y2JiLWNib2JhLWRlY2xhcmVkLWEtZGl2aWRlbmQtb2YtJDAuMDQwMC1wZXItc2hhcmUv0gEA?hl=en-US&gl=US&ceid=US%3Aen",
        "204": "news.google.com\\/articles\\/CAIiEIZTkgoLwbR0QTWiZUnvXTQqMwgEKioIACIQBwB3h9S7eSAUFMWok1yrKioUCAoiEAcAd4fUu3kgFBTFqJNcqyow_P6VBw?uo=CAUigQFodHRwczovL2ZpbmFuY2UueWFob28uY29tL25ld3Mvc3BvdGlmeS1yZXNwb25kcy10by1uZWlsLXlvdW5nLWJhY2tsYXNoLWFzLXN0cmVhbWluZy1lY29ub21pY3MtdW5kZXJzY29yZS1pdHMtcG93ZXItMDMwNTMxNjk3Lmh0bWzSAQA&hl=en-US&gl=US&ceid=US%3Aen",
        "205": "news.google.com\\/articles\\/CAIiEB0vaHXZkh13RXeqiTjSeQwqFggEKg0IACoGCAowkqEGMJBZMNL5vAY?uo=CAUiW2h0dHBzOi8vc2Vla2luZ2FscGhhLmNvbS9hcnRpY2xlLzQ0ODI4MTYtYXBwbGUtYWFwbC1lYXJuaW5ncy1wcm9iYWJsZS1kaXZpZGVuZC1oaWtlLWJ1eWJhY2vSAQA&hl=en-US&gl=US&ceid=US%3Aen",
        "206": "news.google.com\\/articles\\/CAIiEPBP06jDEqHq1JoFZUHQEcgqGQgEKhAIACoHCAow4uzwCjCF3bsCMIrOrwM?uo=CAUiZ2h0dHBzOi8vd3d3LmJsb29tYmVyZy5jb20vbmV3cy9uZXdzbGV0dGVycy8yMDIyLTAxLTMwL3dpbGwtYXBwbGUtYWFwbC1idXktcGVsb3Rvbi1wcm9iYWJseS1ub3Qta3oxZGtjbTfSAQA&hl=en-US&gl=US&ceid=US%3Aen",
        "207": "news.google.com\\/articles\\/CBMiVGh0dHBzOi8vd3d3Lm5hc2RhcS5jb20vYXJ0aWNsZXMvYWN3aS1hYXBsLWFtem4tZ29vZyUzQS1sYXJnZS1pbmZsb3dzLWRldGVjdGVkLWF0LWV0ZtIBWGh0dHBzOi8vd3d3Lm5hc2RhcS5jb20vYXJ0aWNsZXMvYWN3aS1hYXBsLWFtem4tZ29vZyUzQS1sYXJnZS1pbmZsb3dzLWRldGVjdGVkLWF0LWV0Zj9hbXA?hl=en-US&gl=US&ceid=US%3Aen",
        "208": "news.google.com\\/articles\\/CAIiENDrEq6Qf1OgyN2DarMybs8qGQgEKhAIACoHCAow2Nb3CjDivdcCMMPf7gU?hl=en-US&gl=US&ceid=US%3Aen",
        "209": "news.google.com\\/articles\\/CAIiEJwq7LK_VR8WJseUvXHF3a8qMwgEKioIACIQT4VDYmxpaDN7y5JvscS9FioUCAoiEE-FQ2JsaWgze8uSb7HEvRYw6_HMBg?uo=CAUiSWh0dHBzOi8vd3d3Lm5hc2RhcS5jb20vYXJ0aWNsZXMvZGFpbHktZGl2aWRlbmQtcmVwb3J0JTNBLWFhcGxsbXR3YmFmbnZjbXPSAQA&hl=en-US&gl=US&ceid=US%3Aen",
        "210": "news.google.com\\/articles\\/CBMifWh0dHBzOi8vd3d3LmZ4c3RyZWV0LmNvbS9hbmFseXNpcy9hcHBsZS1hYXBsLXN0b2NrLXVwLTUtaW4tYWZ0ZXJob3Vycy10ZXNsYS10c2xhLXN0b2NrLWRvd24tMTEtbW9vZC13b2JibHktdmlkZW8tMjAyMjAxMjgwOTE50gGBAWh0dHBzOi8vd3d3LmZ4c3RyZWV0LmNvbS9hbXAvYW5hbHlzaXMvYXBwbGUtYWFwbC1zdG9jay11cC01LWluLWFmdGVyaG91cnMtdGVzbGEtdHNsYS1zdG9jay1kb3duLTExLW1vb2Qtd29iYmx5LXZpZGVvLTIwMjIwMTI4MDkxOQ?hl=en-US&gl=US&ceid=US%3Aen",
        "211": "news.google.com\\/articles\\/CAIiEN0HcKn_N9I3dRCVFr4lbN4qGQgEKhAIACoHCAowt8yYCzDI1rADMNqB0gY?uo=CAUidmh0dHBzOi8vaW52ZXN0b3JwbGFjZS5jb20vMjAyMi8wMS9tZXRhdmVyc2UtbmV3cy0xMC10aGluZ3MtZm9yLWFhcGwtc3RvY2staW52ZXN0b3JzLXRvLWtub3ctYWJvdXQtYXBwbGVzLWJpZy1hci1wbGFucy_SAQA&hl=en-US&gl=US&ceid=US%3Aen",
        "212": "news.google.com\\/articles\\/CAIiEC6pO2yiYplEO7_Nh5z0g7kqGQgEKhAIACoHCAow4uzwCjCF3bsCMIrOrwM?uo=CAUib2h0dHBzOi8vd3d3LmJsb29tYmVyZy5jb20vbmV3cy9hcnRpY2xlcy8yMDIyLTAxLTI4L2FwcGxlLWFuZC1taWNyb3NvZnQtbGVhZC1nYWlucy1hcy10ZWNoLXN0b2Nrcy1zdGFnZS1jb21lYmFja9IBAA&hl=en-US&gl=US&ceid=US%3Aen",
        "213": "news.google.com\\/articles\\/CBMidWh0dHBzOi8vd3d3LmJlbnppbmdhLmNvbS9uZXdzLzIyLzAxLzI1MjgwMzIxL25ldy1zdHJlZXQtcmVzZWFyY2gtdXBncmFkZXMtYXBwbGUtdG8tbmV1dHJhbC1hbm5vdW5jZXMtMTY1LXByaWNlLXRhcmdldNIBAA?hl=en-US&gl=US&ceid=US%3Aen",
        "214": "news.google.com\\/articles\\/CAIiEOK67_14LA3X__a1WOdUMusqGQgEKhAIACoHCAowt8yYCzDI1rADMNqB0gY?uo=CAUiVWh0dHBzOi8vaW52ZXN0b3JwbGFjZS5jb20vMjAyMi8wMS93aGF0LWRvZXMtY2FuY2Vsc3BvdGlmeS1yZWFsbHktbWVhbi1mb3ItYWFwbC1zdG9jay_SAQA&hl=en-US&gl=US&ceid=US%3Aen",
        "215": "news.google.com\\/articles\\/CAIiENX6kdfQ5gzcXI3L4fXL08wqGQgEKhAIACoHCAow4uzwCjCF3bsCMIrOrwM?uo=CAUicmh0dHBzOi8vd3d3LmJsb29tYmVyZy5jb20vbmV3cy9hcnRpY2xlcy8yMDIyLTAxLTI3L25vdy1pdC1zLWFwcGxlLXMtdHVybi10by10cnktYW5kLWJvb3N0LXJvdXRlZC1zdG9ja3MtdGVjaC13YXRjaNIBAA&hl=en-US&gl=US&ceid=US%3Aen",
        "216": "news.google.com\\/articles\\/CAIiEJgLjP0KsDBiMEl-wAGQ_RQqFggEKg0IACoGCAowkqEGMJBZMMTouwY?uo=CAUic2h0dHBzOi8vc2Vla2luZ2FscGhhLmNvbS9uZXdzLzM3OTIzNjYtYXBwbGUtd29ya2luZy1vbi1zb2Z0d2FyZS10by1sZXQtc21icy10YWtlLXBheW1lbnRzLWRpcmVjdC1mcm9tLWlwaG9uZS1yZXBvcnTSAQA&hl=en-US&gl=US&ceid=US%3Aen",
        "217": "news.google.com\\/articles\\/CBMie2h0dHBzOi8vd3d3Lm5hc2RhcS5jb20vYXJ0aWNsZXMvYWZ0ZXItaG91cnMtZWFybmluZ3MtcmVwb3J0LWZvci1qYW51YXJ5LTI3LTIwMjItJTNBLWFhcGwtdi1zeWstbWRsei1rbGFjLWNwLXRlYW0tcm1kLWFqZy1jZdIBf2h0dHBzOi8vd3d3Lm5hc2RhcS5jb20vYXJ0aWNsZXMvYWZ0ZXItaG91cnMtZWFybmluZ3MtcmVwb3J0LWZvci1qYW51YXJ5LTI3LTIwMjItJTNBLWFhcGwtdi1zeWstbWRsei1rbGFjLWNwLXRlYW0tcm1kLWFqZy1jZT9hbXA?hl=en-US&gl=US&ceid=US%3Aen",
        "218": "news.google.com\\/articles\\/CBMiUmh0dHBzOi8vd3d3Lm5hc2RhcS5jb20vYXJ0aWNsZXMvNC10b3Atc3RvY2stdHJhZGVzLWZvci1mcmlkYXklM0EtYWFwbC1tY2Qtbm93LWxjaWTSAVZodHRwczovL3d3dy5uYXNkYXEuY29tL2FydGljbGVzLzQtdG9wLXN0b2NrLXRyYWRlcy1mb3ItZnJpZGF5JTNBLWFhcGwtbWNkLW5vdy1sY2lkP2FtcA?hl=en-US&gl=US&ceid=US%3Aen",
        "219": "news.google.com\\/articles\\/CBMiTmh0dHBzOi8vd3d3LmludmVzdG9wZWRpYS5jb20vYXBwbGUtZWFybmluZ3Mtd2hhdC10by1sb29rLWZvci1mcm9tLWFhcGwtNTIxNzAzONIBAA?hl=en-US&gl=US&ceid=US%3Aen",
        "220": "news.google.com\\/articles\\/CAIiEH8v3KHEyDtIop9hKv7AOo4qGQgEKhAIACoHCAow4uzwCjCF3bsCMIrOrwM?uo=CAUia2h0dHBzOi8vd3d3LmJsb29tYmVyZy5jb20vbmV3cy9hcnRpY2xlcy8yMDIyLTAxLTI2L2FwcGxlLWdldHMtcmVzdHJhaW5pbmctb3JkZXItYWdhaW5zdC13b21hbi1zdGFsa2luZy1jb29r0gEA&hl=en-US&gl=US&ceid=US%3Aen",
        "221": "news.google.com\\/articles\\/CAIiEGSQ1zzuTRlHlukB3wDvgnwqGQgEKhAIACoHCAow4uzwCjCF3bsCMNGAygM?uo=CAUicmh0dHBzOi8vd3d3LmJsb29tYmVyZy5jb20vbmV3cy9hcnRpY2xlcy8yMDIyLTAxLTI2L2FwcGxlLXRvcHMtY2hpbmEtZm9yLWZpcnN0LXRpbWUtaW4tc2l4LXllYXJzLWFmdGVyLWh1YXdlaS1mYWRlc9IBAA&hl=en-US&gl=US&ceid=US%3Aen",
        "222": "news.google.com\\/articles\\/CAIiEL-RHjd9WCj9zRcE2x65a3MqFggEKg0IACoGCAows4UBMMAaMNPRtQY?hl=en-US&gl=US&ceid=US%3Aen",
        "223": "news.google.com\\/articles\\/CBMiZGh0dHBzOi8vaW52ZXN0b3JwbGFjZS5jb20vMjAyMi8wMS90ZWNoLXN0b2Nrcy1hbGVydC13aHktYXJlLXRzbGEtbXNmdC1hYXBsLW52ZGEtc25hcC1wbHVuZ2luZy1hZ2Fpbi_SAWhodHRwczovL2ludmVzdG9ycGxhY2UuY29tLzIwMjIvMDEvdGVjaC1zdG9ja3MtYWxlcnQtd2h5LWFyZS10c2xhLW1zZnQtYWFwbC1udmRhLXNuYXAtcGx1bmdpbmctYWdhaW4vYW1wLw?hl=en-US&gl=US&ceid=US%3Aen",
        "224": "news.google.com\\/articles\\/CAIiEA77lJAOCZ6-vrQnd3klboMqGQgEKhAIACoHCAow4uzwCjCF3bsCMIrOrwM?uo=CAUic2h0dHBzOi8vd3d3LmJsb29tYmVyZy5jb20vbmV3cy9hcnRpY2xlcy8yMDIyLTAxLTI0L2FwcGxlLWlzLXBsdW5naW5nLWFuZC1hLXdlYWtlci1kb2xsYXItbWF5LWJlLXRoZS1zYXZpb3ItaXQtbmVlZHPSAQA&hl=en-US&gl=US&ceid=US%3Aen",
        "225": "news.google.com\\/articles\\/CAIiELi88RZMDKu1sTyHKcBpau4qMwgEKioIACIQT4VDYmxpaDN7y5JvscS9FioUCAoiEE-FQ2JsaWgze8uSb7HEvRYw6_HMBg?uo=CAUiUWh0dHBzOi8vd3d3Lm5hc2RhcS5jb20vYXJ0aWNsZXMvbm90ZXdvcnRoeS1tb25kYXktb3B0aW9uLWFjdGl2aXR5JTNBLWJhLWFhcGwtdWx0YdIBAA&hl=en-US&gl=US&ceid=US%3Aen",
        "226": "news.google.com\\/articles\\/CAIiEGHRwhDMKSiWAENm3S6wd48qMwgEKioIACIQT4VDYmxpaDN7y5JvscS9FioUCAoiEE-FQ2JsaWgze8uSb7HEvRYw6_HMBg?uo=CAUiZWh0dHBzOi8vd3d3Lm5hc2RhcS5jb20vYXJ0aWNsZXMvd2Vla2x5LXByZXZpZXclM0EtZWFybmluZ3MtdG8td2F0Y2gtdGhpcy13ZWVrLWFhcGwtYW1kLWludGMtbXNmdC10c2xh0gEA&hl=en-US&gl=US&ceid=US%3Aen",
        "227": "news.google.com\\/articles\\/CBMiU2h0dHBzOi8vd3d3LnRoZXN0cmVldC5jb20vYXBwbGUvc3RvY2svYXBwbGUtc3RvY2staXQtY291bGQtcmVhY2gtNS10cmlsbGlvbi1ieS0yMDI10gFYaHR0cHM6Ly93d3cudGhlc3RyZWV0LmNvbS9hcHBsZS8uYW1wL3N0b2NrL2FwcGxlLXN0b2NrLWl0LWNvdWxkLXJlYWNoLTUtdHJpbGxpb24tYnktMjAyNQ?hl=en-US&gl=US&ceid=US%3Aen",
        "228": "news.google.com\\/articles\\/CBMiV2h0dHBzOi8vZmluYW5jZS55YWhvby5jb20vbmV3cy9waW5uYWNsZS13ZWFsdGgtbWFuYWdlbWVudC1hZHZpc29yeS1ncm91cC0wMjM4MDM2MTMuaHRtbNIBX2h0dHBzOi8vZmluYW5jZS55YWhvby5jb20vYW1waHRtbC9uZXdzL3Bpbm5hY2xlLXdlYWx0aC1tYW5hZ2VtZW50LWFkdmlzb3J5LWdyb3VwLTAyMzgwMzYxMy5odG1s?hl=en-US&gl=US&ceid=US%3Aen",
        "229": "news.google.com\\/articles\\/CBMiUGh0dHBzOi8vZmluYW5jZS55YWhvby5jb20vbmV3cy9yYWRub3ItY2FwaXRhbC1tYW5hZ2VtZW50LWxsYy1idXlzLTE4MzgwNzU2NS5odG1s0gFYaHR0cHM6Ly9maW5hbmNlLnlhaG9vLmNvbS9hbXBodG1sL25ld3MvcmFkbm9yLWNhcGl0YWwtbWFuYWdlbWVudC1sbGMtYnV5cy0xODM4MDc1NjUuaHRtbA?hl=en-US&gl=US&ceid=US%3Aen",
        "230": "news.google.com\\/articles\\/CAIiEAMqmNieP9i6MgwT9IO7Qv4qGQgEKhAIACoHCAow4uzwCjCF3bsCMIrOrwM?uo=CAUicWh0dHBzOi8vd3d3LmJsb29tYmVyZy5jb20vbmV3cy9hcnRpY2xlcy8yMDIyLTAxLTIwL2FwcGxlLXJlcGxhY2VzLXByLWhlYWQtd2l0aC1jb21wYW55LXZldGVyYW4tYWZ0ZXItZWlnaHQtbW9udGhz0gEA&hl=en-US&gl=US&ceid=US%3Aen",
        "231": "news.google.com\\/articles\\/CBMiU2h0dHBzOi8vZmluYW5jZS55YWhvby5jb20vbmV3cy9yaXRob2x0ei13ZWFsdGgtbWFuYWdlbWVudC1idXlzLWFwcGxlLTE0MzgxOTk1OC5odG1s0gFbaHR0cHM6Ly9maW5hbmNlLnlhaG9vLmNvbS9hbXBodG1sL25ld3Mvcml0aG9sdHotd2VhbHRoLW1hbmFnZW1lbnQtYnV5cy1hcHBsZS0xNDM4MTk5NTguaHRtbA?hl=en-US&gl=US&ceid=US%3Aen",
        "232": "news.google.com\\/articles\\/CAIiENxDnyAcCWEzQdjxVUctV-gqGQgEKhAIACoHCAow4uzwCjCF3bsCMIrOrwM?uo=CAUicmh0dHBzOi8vd3d3LmJsb29tYmVyZy5jb20vbmV3cy9hcnRpY2xlcy8yMDIyLTAxLTE4L2VyaWNzc29uLWVzY2FsYXRlcy01Zy1wYXRlbnQtZGlzcHV0ZS13aXRoLWFwcGxlLWluLW5ldy1sYXdzdWl0c9IBAA&hl=en-US&gl=US&ceid=US%3Aen",
        "233": "news.google.com\\/articles\\/CBMiggFodHRwczovL3d3dy5meHN0cmVldC5jb20vbmV3cy9hcHBsZS1zdG9jay1wcmljZS1hbmQtZm9yZWNhc3QtY2FuLWFhcGwtZWFybmluZ3MtcHJvdmlkZS10aGUtY2F0YWx5c3QtZm9yLWFub3RoZXItcmFsbHktMjAyMjAxMTcxNDE50gEA?hl=en-US&gl=US&ceid=US%3Aen",
        "234": "news.google.com\\/articles\\/CAIiENe_j4tMQgF65m6coGmhnDAqFggEKg0IACoGCAowkqEGMJBZMMTouwY?uo=CAUic2h0dHBzOi8vc2Vla2luZ2FscGhhLmNvbS9uZXdzLzM3ODgwMjYtYXBwbGUtdnMtdGVzbGEtY2FuLXRoZS13b3JsZHMtbW9zdC12YWx1YWJsZS1jb21wYW55LWNvbXBldGUtYWdhaW5zdC1lbG9uLW11c2vSAQA&hl=en-US&gl=US&ceid=US%3Aen",
        "235": "news.google.com\\/articles\\/CAIiENYiguKn5i11YTFcjzP4vBYqGQgEKhAIACoHCAow4uzwCjCF3bsCMIrOrwM?uo=CAUiiQFodHRwczovL3d3dy5ibG9vbWJlcmcuY29tL25ld3MvbmV3c2xldHRlcnMvMjAyMi0wMS0xNi93aGF0LXdpbGwtYXBwbGUtYWFwbC1jYWxsLWl0cy1uZXctYXItdnItaGVhZHNldC1hcHBsZS12aXNpb24tYXBwbGUtcmVhbGl0eS1reWhtaWJpYdIBAA&hl=en-US&gl=US&ceid=US%3Aen",
        "236": "news.google.com\\/articles\\/CAIiECQyOYA7HRv_x1Vw5hsYrPQqGQgEKhAIACoHCAow4uzwCjCF3bsCMIrOrwM?uo=CAUic2h0dHBzOi8vd3d3LmJsb29tYmVyZy5jb20vbmV3cy9hcnRpY2xlcy8yMDIyLTAxLTE0L2FwcGxlLXMtaG90bHktYW50aWNpcGF0ZWQtaGVhZHNldC1yaXNrcy1iZWluZy1kZWxheWVkLXVudGlsLTIwMjPSAQA&hl=en-US&gl=US&ceid=US%3Aen",
        "237": "news.google.com\\/articles\\/CBMiUWh0dHBzOi8vd3d3LnRoZXN0cmVldC5jb20vYXBwbGUvbmV3cy9hYXBsLXZzLWFtem4tamltLWNyYW1lci1waWNrZWQtdGhlLXJpZ2h0LW9uZdIBVmh0dHBzOi8vd3d3LnRoZXN0cmVldC5jb20vYXBwbGUvLmFtcC9uZXdzL2FhcGwtdnMtYW16bi1qaW0tY3JhbWVyLXBpY2tlZC10aGUtcmlnaHQtb25l?hl=en-US&gl=US&ceid=US%3Aen",
        "238": "news.google.com\\/articles\\/CBMicGh0dHBzOi8vd3d3LmJsb29tYmVyZy5jb20vbmV3cy9hcnRpY2xlcy8yMDIyLTAxLTEyL21pY3Jvc29mdC1yZWNydWl0cy1rZXktYXBwbGUtZW5naW5lZXItdG8td29yay1vbi1jdXN0b20tY2hpcHPSAQA?hl=en-US&gl=US&ceid=US%3Aen",
        "239": "news.google.com\\/articles\\/CBMiWGh0dHBzOi8vd3d3Lm5hc2RhcS5jb20vYXJ0aWNsZXMvYXBwbGUtYWFwbC1yZXBvcnRlZGx5LWRpc2N1c3Npbmctc3RyZWFtaW5nLWRlYWwtd2l0aC1tbGLSAVxodHRwczovL3d3dy5uYXNkYXEuY29tL2FydGljbGVzL2FwcGxlLWFhcGwtcmVwb3J0ZWRseS1kaXNjdXNzaW5nLXN0cmVhbWluZy1kZWFsLXdpdGgtbWxiP2FtcA?hl=en-US&gl=US&ceid=US%3Aen",
        "240": "news.google.com\\/articles\\/CBMiWGh0dHBzOi8vaW52ZXN0b3JwbGFjZS5jb20vMjAyMi8wMS9mYi1hbXpuLW52ZGEtYWFwbC1jb2luLXdoeS1hcmUtdGVjaC1zdG9ja3MtZG93bi10b2RheS_SAVxodHRwczovL2ludmVzdG9ycGxhY2UuY29tLzIwMjIvMDEvZmItYW16bi1udmRhLWFhcGwtY29pbi13aHktYXJlLXRlY2gtc3RvY2tzLWRvd24tdG9kYXkvYW1wLw?hl=en-US&gl=US&ceid=US%3Aen",
        "241": "news.google.com\\/articles\\/CAIiEJvvSMqg-yCHSvZdVHzSQq8qEwgEKgwIACoFCAowgHkwoBEwwjU?uo=CAUiW2h0dHBzOi8vd3d3LmZvb2wuY29tL2ludmVzdGluZy8yMDIyLzAxLzA4L2hlcmVzLWhvdy1hcHBsZS1zdG9jay1jYW4tc29hci1hYm92ZS0yMDAtaW4tMjAyMi_SAQA&hl=en-US&gl=US&ceid=US%3Aen",
        "242": "news.google.com\\/articles\\/CAIiEN2UFbS6FJlLQtpiK40mXpQqFggEKg0IACoGCAowkqEGMJBZMNL5vAY?uo=CAUiRWh0dHBzOi8vc2Vla2luZ2FscGhhLmNvbS9hcnRpY2xlLzQ0NzgwMTgtYXBwbGUtc3RvY2stbm90LWJ1eS1ub3Qtc2VsbNIBAA&hl=en-US&gl=US&ceid=US%3Aen",
        "243": "news.google.com\\/articles\\/CAIiELkaFeXvZjk5cYM40HPnZCMqFggEKg0IACoGCAowkqEGMJBZMNL5vAY?uo=CAUiRmh0dHBzOi8vc2Vla2luZ2FscGhhLmNvbS9hcnRpY2xlLzQ0Nzc3NDEtYXBwbGUtc3RvY2stcmVhY2gtMjAwLWluLTIwMjLSAQA&hl=en-US&gl=US&ceid=US%3Aen",
        "244": "news.google.com\\/articles\\/CAIiEGvFwLR5McMlsn0uNNZ6Y-gqFggEKg0IACoGCAowkqEGMJBZMNL5vAY?uo=CAUiQ2h0dHBzOi8vc2Vla2luZ2FscGhhLmNvbS9hcnRpY2xlLzQ0NzYyMTktYXBwbGUtc3RhdGUtb2YtdW5jZXJ0YWludHnSAQA&hl=en-US&gl=US&ceid=US%3Aen",
        "245": "news.google.com\\/articles\\/CAIiEHaZA_V2KElkXT9o_VnJDDAqFggEKg0IACoGCAowkqEGMJBZMNL5vAY?uo=CAUiRGh0dHBzOi8vc2Vla2luZ2FscGhhLmNvbS9hcnRpY2xlLzQ0NzUyMzctYXBwbGUtc3RvY2staXMtbm93LWEtYnViYmxl0gEA&hl=en-US&gl=US&ceid=US%3Aen",
        "246": "news.google.com\\/articles\\/CAIiEB_qttaFdnGl5bdz_eiPjE4qFggEKg0IACoGCAows4UBMMAaMNPRtQY?uo=CAUiPGh0dHBzOi8vOXRvNW1hYy5jb20vMjAyMS8xMi8wMi9pcGhvbmUtMTMtcmVwb3J0cy1hYXBsLXN0b2NrL9IBAA&hl=en-US&gl=US&ceid=US%3Aen",
        "247": "news.google.com\\/articles\\/CAIiEHDbjzCle6GHnjgbxXYFc-4qFggEKg0IACoGCAowkqEGMJBZMNL5vAY?uo=CAUiSWh0dHBzOi8vc2Vla2luZ2FscGhhLmNvbS9hcnRpY2xlLzQ0NzA5NDgtYXBwbGUtc3RvY2stc2VsbC1iZWZvcmUtZGVjZW1iZXLSAQA&hl=en-US&gl=US&ceid=US%3Aen",
        "248": "news.google.com\\/articles\\/CBMiW2h0dHBzOi8vd3d3LnRoZXN0cmVldC5jb20vYXBwbGUvc3RvY2svc2VsbC1hYXBsLXdoeS10aGlzLWV4cGVydC1zZWVzLWFwcGxlLXN0b2NrLWRpcHBpbmctMTLSAWBodHRwczovL3d3dy50aGVzdHJlZXQuY29tL2FwcGxlLy5hbXAvc3RvY2svc2VsbC1hYXBsLXdoeS10aGlzLWV4cGVydC1zZWVzLWFwcGxlLXN0b2NrLWRpcHBpbmctMTI?hl=en-US&gl=US&ceid=US%3Aen",
        "249": "news.google.com\\/articles\\/CAIiEHkNZZfZ5Vgo911iGgPgZtoqMwgEKioIACIQaI-KISnONJyMPNSt_eLQTCoUCAoiEGiPiiEpzjScjDzUrf3i0Ewwi6TZBg?uo=CAUiSmh0dHBzOi8vd3d3LmludmVzdG9wZWRpYS5jb20vYXBwbGUtcTQtZnkyMDIxLWVhcm5pbmdzLXJlcG9ydC1yZWNhcC01MjA3Njc20gEA&hl=en-US&gl=US&ceid=US%3Aen",
        "250": "news.google.com\\/articles\\/CBMiJ2h0dHBzOi8vd3d3LmZvb2wuY29tL3F1b3RlL25hc2RhcS9hYXBsL9IBAA?hl=en-US&gl=US&ceid=US%3Aen",
        "251": "news.google.com\\/articles\\/CBMiRWh0dHBzOi8vd3d3LmZvcmJlcy5jb20vYWR2aXNvci9pbnZlc3RpbmcvaG93LXRvLWJ1eS1hcHBsZS1hYXBsLXN0b2NrL9IBAA?hl=en-US&gl=US&ceid=US%3Aen",
        "252": "news.google.com\\/articles\\/CAIiECx3tkr_mfrhY7gH3PL-i0EqFggEKg0IACoGCAows4UBMMAaMNPRtQY?uo=CAUiT2h0dHBzOi8vOXRvNW1hYy5jb20vMjAyMS8wOC8xNy9uZXctYWFwbC1hbGwtdGltZS1oaWdoLWFzLWl0LWZpbmFsbHktYnJlYWtzLTE1MC_SAQA&hl=en-US&gl=US&ceid=US%3Aen",
        "253": "news.google.com\\/articles\\/CBMiQWh0dHBzOi8vZmluYW5jZS55YWhvby5jb20vbmV3cy9idWxsLWRheS1hcHBsZS1hYXBsLTA5MDAwOTg0OS5odG1s0gFJaHR0cHM6Ly9maW5hbmNlLnlhaG9vLmNvbS9hbXBodG1sL25ld3MvYnVsbC1kYXktYXBwbGUtYWFwbC0wOTAwMDk4NDkuaHRtbA?hl=en-US&gl=US&ceid=US%3Aen",
        "254": "news.google.com\\/articles\\/CAIiEO2N6o_m2wFg6m2q0iSp9bwqMwgEKioIACIQaI-KISnONJyMPNSt_eLQTCoUCAoiEGiPiiEpzjScjDzUrf3i0Ewwi6TZBg?uo=CAUiV2h0dHBzOi8vd3d3LmludmVzdG9wZWRpYS5jb20vYWFwbC1vcHRpb24tdHJhZGVycy1lbnRodXNpYXN0aWMtYWhlYWQtb2YtZWFybmluZ3MtNTE5NDE5MdIBAA&hl=en-US&gl=US&ceid=US%3Aen",
        "255": "news.google.com\\/articles\\/CBMiS2h0dHBzOi8vZmluYW5jZS55YWhvby5jb20vbmV3cy9hcHBsZS1hYXBsLXN0b2NrLXNpbmtzLW1hcmtldC0yMTQ1MDk1NDAuaHRtbNIBU2h0dHBzOi8vZmluYW5jZS55YWhvby5jb20vYW1waHRtbC9uZXdzL2FwcGxlLWFhcGwtc3RvY2stc2lua3MtbWFya2V0LTIxNDUwOTU0MC5odG1s?hl=en-US&gl=US&ceid=US%3Aen",
        "256": "news.google.com\\/articles\\/CBMiT2h0dHBzOi8vd3d3LnRoZXN0cmVldC5jb20vYXBwbGUvc3RvY2svYWFwbC1hZnRlci1ob3Vycy1hcHBsZS1zdG9jay1kaXBwZWQtYWdhaW7SAVRodHRwczovL3d3dy50aGVzdHJlZXQuY29tL2FwcGxlLy5hbXAvc3RvY2svYWFwbC1hZnRlci1ob3Vycy1hcHBsZS1zdG9jay1kaXBwZWQtYWdhaW4?hl=en-US&gl=US&ceid=US%3Aen"
    }
}

export default NewsScrape;