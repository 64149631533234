import React, { useContext, useState, useEffect } from 'react';

import './intro.css'

const Intro = () => {
    return (
        <div className='intro_layout'>
            Hi, there! I'm Michael. Welcome to my personal project.
        </div>
    )
}

export default Intro;