import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import './SellStock.css'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    useLocation,
    useHistory
  } from "react-router-dom"

const SellStock = (props) => {
	const [amount, setAmount] = useState()
    const [Type, setType] = useState('share')
    const [Acc, setAcc] = useState(['All Accounts'])
    const [Pos, setPos] = useState()
    const [AllPos, setAllPos] = useState()
    const {user} = useContext(AppContext);
    const [Option, setOption] = useState()
    const history = useHistory();
    const [Total,setTotal] = useState();


    useEffect( async ()=> {
        getOption()
    }, [])

	const ClickShare = (e) => {
        e.preventDefault()
        setType('share')
    }

    const ClickDollar = (e) => {
        e.preventDefault()
        setType('dollar')
    }

    const getOption = async () => {
        var opt = []
        var opt2 = {}
        var response
        response = await fetch(`http://193.31.27.5:8000/api/position/${{user}['user']}`)
        let data = await response.json()
        console.log(data)
        if (Object.keys(data).includes(props.symbol)){
            opt2[props.Acc]= data[props.symbol]
        }
        setOption(opt2)
        console.log(opt2)
        setTotal(data[props.symbol.toUpperCase()]);
    }

    const ClickSell = async (e) =>{
        e.preventDefault()
        var addItem
        var amountSell = amount
        if(Type == 'share'){
            addItem = {
                symbol: props.symbol,
                price: props.Price,
                amount: amount,
                account: Acc,
            };
        }
        else{
            addItem = {
                symbol: props.symbol,
                price: props.Price,
                amount: amount/props.Price,
                account: Acc,
            }
        }
        
        let response = await fetch(`http://193.31.27.5:8000/api/activity/${{user}['user']}/sell`, {
        method: 'POST',
        'headers': {
            'Content-Type': 'application/json'
        },
        'body': JSON.stringify(addItem)
        })
        history.go(0)
    }

	return (
		<form>
            <div className='BuyStock_Box'>
                <div className='OrderLayout'>
                    <dl className='OrderForm'>
                        <dt>Type</dt>
                        <dd>
                            <div className='OrderType'>
                                <button 
                                onClick={ClickShare} 
                                style={{"backgroundColor":(Type=='share')?'rgb(200, 200, 200)':'rgba(38, 49, 60, .05)'}}
                                >Share</button>
                                <button 
                                onClick={ClickDollar}
                                style={{"backgroundColor":(Type=='dollar')?'rgb(200, 200, 200)':'rgba(38, 49, 60, .05)'}}
                                >Dollar</button>
                            </div>
                        </dd>
                        <dt>Amount</dt>
                        <dd>
                            <div className='OrderInput'>
                                <input
                                value = {amount}
                                placeholder="0"
                                onChange = {(e) => setAmount(e.target.value)}/>
                            </div>
                        </dd>
                        <dt>Market Price</dt>
                        <dd></dd>
                        <dd>{props.Price.toFixed(2)}</dd>
                        <dt>{(Type=='share')?'Est. Cost':'Est. Qty'}</dt>
                        <dd></dd>
                        <dd>
                            {(Type=='share')
                            ?
                            ((amount == undefined) ? 0 : (amount * props.Price).toFixed(2))
                            :
                            ((amount == undefined) ? 0 : (amount/props.Price).toFixed(2))}
                        </dd>
                    </dl>
                </div>
                {amount == undefined && <div className='Order_sell'>
                    <button color='rgba(255, 78, 78, .9)' onClick={ClickSell}>Sell</button>
                </div>}
                {Option && Type=='share' && amount <= Total && <div className='Order_sell'>
                    <button color='rgba(255, 78, 78, .9)' onClick={ClickSell}>Sell</button>
                </div>}
                {Option && Type=='share' && amount > Total && 
                <>
                    <div className='Order_sell'>
                    <button disabled='true' onClick={ClickSell}>Sell</button>
                    </div>
                    <div className="Order_Desc">Too much</div>
                </>}
                {Option && Type=='dollar' && amount <= Total * props.Price && <div className='Order_sell'>
                    <button onClick={ClickSell}>Sell</button>
                </div>}
                {Option && Type=='dollar' && amount > Total * props.Price && 
                <>
                    <div className='Order_sell'>
                    <button disabled='true' onClick={ClickSell}>Sell</button>
                    </div>
                    <div className="Order_Desc">Too much</div>
                </>}
                {(Total && Type == 'share' && <div className='Order_Desc'>
                    Available shares: {Total.toFixed(2)}
                </div>)}
        
                {Total && Type == 'dollar' && <div className='Order_Desc'>
                    Available money: {(Total * props.Price).toFixed(2) }
                </div>
            }
                
            </div>
        </form>
	);
};

export default SellStock;

/*
import React, { useContext, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import './SellStock.css'

const SellStock = (props) => {
	const { porto, dispatch } = useContext(AppContext);

	const [sellDate, setSellDate] = useState('2022-02-07');
    const [amount, setAmount] = useState('');
    var [ID, setID] = useState(0)

	const onSubmit = async (event) => {
        
        console.log(JSON.stringify(stock))
        var ID = []
        for (var x=0;x<amount;x++){
            for (var item in porto) {

                if (porto[item]['symbol'] == props.symbol && porto[item]['sellDate'] == '' && ID.includes(porto[item]['id']) == false){
                    var stock = {
                        symbol: props.symbol,
                        buyDate: porto[item]['buyDate'],
                        sellDate,
                    };
                    ID.push(porto[item]['id'])
                    console.log(porto[item]['id'])
                    console.log(stock)

                    let response = await fetch(`http://193.31.27.5:8000/api/investment/${porto[item]['id']}/delete`, {
                    method: 'PUT',
                    'headers': {
                        'Content-Type': 'application/json'
                    },
                    'body': JSON.stringify(stock)
                    })
                    let data = await response.json()
                    console.log(data)
                    break
                }
            }
        }

        setAmount(0)
	};

	return (
		<form onSubmit={onSubmit}>
            <div className="SellStock_Box">
                <div className='SellStock_Input'>
                    <div className='SellStock_Input_Amount'>
                        <label htmlFor='name'>Amount</label>
                        <input
                            required='required'
                            type='number'
                            className='form-control'
                            id='amount'
                            value={amount}
                            onChange={(event) => setAmount(event.target.value)}
                        />
                    </div>
                    <div className='SellStock_Input_Date'>
                        <label htmlFor='cost'>Date</label>
                        <input
                            type='text'
                            className='form-control'
                            id='date'
                            value={sellDate}
                            onChange={(event) => setSellDate(event.target.value)}
                        />
                    </div>
                </div>
                <div className='SellStock_Button'>
                    <button type='submit' className='btn btn-primary'>
                            Sell
                    </button>
                </div>
            </div>
		</form>
	);
};

export default SellStock;
*/

/*return (
		<form>
            <div className='BuyStock_Box'>
                <div className='OrderLayout'>
                    <dl className='OrderForm'>
                        <dt>Account</dt>
                        <dd>
                            <select value={Acc} onChange={e => setAcc(e.target.value)} className='Acc_Select'>
                                {Option && Object.keys(Option).map((p, idx) => { 
                                    return(
                                        <option className='Acc_Option'>
                                            {p}
                                        </option>
                                    )
                                })}
                            </select>
                        </dd>
                        <dt>Type</dt>
                        <dd>
                            <div className='OrderType'>
                                <button 
                                onClick={ClickShare} 
                                style={{"backgroundColor":(Type=='share')?'rgb(200, 200, 200)':'rgba(38, 49, 60, .05)'}}
                                >Share</button>
                                <button 
                                onClick={ClickDollar}
                                style={{"backgroundColor":(Type=='dollar')?'rgb(200, 200, 200)':'rgba(38, 49, 60, .05)'}}
                                >Dollar</button>
                            </div>
                        </dd>
                        <dt>Amount</dt>
                        <dd>
                            <div className='OrderInput'>
                                <input
                                value = {amount}
                                placeholder="0"
                                onChange = {(e) => setAmount(e.target.value)}/>
                            </div>
                        </dd>
                        <dt>Market Price</dt>
                        <dd></dd>
                        <dd>{props.Price.toFixed(2)}</dd>
                        <dt>{(Type=='share')?'Est. Cost':'Est. Qty'}</dt>
                        <dd></dd>
                        <dd>
                            {(Type=='share')
                            ?
                            ((amount == undefined) ? 0 : (amount * props.Price).toFixed(2))
                            :
                            ((amount == undefined) ? 0 : (amount/props.Price).toFixed(2))}
                        </dd>
                    </dl>
                </div>
                {amount == undefined && <div className='Order'>
                    <button onClick={ClickSell}>Sell</button>
                </div>}
                {Option && Type=='share' && amount <= Option[Acc] && <div className='Order'>
                    <button onClick={ClickSell}>Sell</button>
                </div>}
                {Option && Type=='share' && amount > Option[Acc] && 
                <>
                    <div className='Order'>
                    <button disabled='true' onClick={ClickSell}>Sell</button>
                    </div>
                    <div className="Order_Desc">Too much</div>
                </>}
                {Option && Type=='dollar' && amount <= Option[Acc] * props.Price && <div className='Order'>
                    <button onClick={ClickSell}>Sell</button>
                </div>}
                {Option && Type=='dollar' && amount > Option[Acc] * props.Price && 
                <>
                    <div className='Order'>
                    <button disabled='true' onClick={ClickSell}>Sell</button>
                    </div>
                    <div className="Order_Desc">Too much</div>
                </>}
                {(Option && Type == 'share' && <div className='Order_Desc'>
                    Available shares: {Option[Acc]}
                </div>)}
                
                {Option && Type == 'dollar' && <div className='Order_Desc'>
                    Available money: {(Option[Acc] * props.Price) }
                </div>
            }
                
            </div>
        </form>
	);
};*/