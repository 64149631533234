import React, { useContext, useState } from 'react';
import { AppContext } from '../context/AppContext';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    useLocation,
    Redirect,
    useHistory
  } from "react-router-dom"


const LoggedIn = (props) => {
    const { user, dispatch } = useContext(AppContext);
    let history = useHistory();

    const onClickLogOut = (event) => {
        dispatch({
            type: 'SIGN_OUT',
        });
        dispatch({
            type: 'DELETE_ALL_EXPENSE',
        });
        history.push('/')
    }

	return (
		<div>
			Hello {user}
            <button onClick={onClickLogOut} className=''> Log Out </button>
		</div>
	);
};

export default LoggedIn;