import React, { useContext, useState, useEffect} from 'react';
import { TiDelete } from 'react-icons/ti';
import './WI.css'
import { AppContext } from '../../context/AppContext';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
  } from 'chart.js';

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams
  } from "react-router-dom"

import { Line } from "react-chartjs-2";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
  );

const WI = (props) => {
    let result = props.pos

	return (
		<div className="Port_List_PW_List">
			{result && Object.keys(result).map((res, idx) => {
                return (
                    <div className='GraphBox' key={idx} >
                        <Link onClick={() => {window.location.href=`/portfolio/${res}`}} key={res}>{res}</Link>
                        <GraphOverviewWI datasets={result[res]}/>
                    </div>
                );
            })}
		</div>
	);
};


const GraphOverviewWI = (props) => {
    let datasets = props.datasets
    let [Xvalue, setXvalue] = useState([])
    let [Yvalue, setYvalue] = useState([])

    useEffect(() => {
        getData()
    }, [])


    let getData = async () => {
        let XvalueFunction = [];
        let YvalueFunction = [];
        
        for (var date in datasets) {
            XvalueFunction.push(date);
            YvalueFunction.push(datasets[date])
        }
        setYvalue(YvalueFunction);
        setXvalue(XvalueFunction);
    }

    let dataset = []
    dataset.push({x: Xvalue, y: Yvalue})

   
    const dataA = {
        labels: Xvalue,
        datasets: [{
            type: 'line',
            borderColor: "black",
            borderWidth: 2,
            pointBorderColor: 'rgba(0, 0, 0, 0)',
            pointBackgroundColor: 'rgba(0, 0, 0, 0)',
            data: Yvalue
        }]
    }

    return (
        <div className='GraphLine'>
            <Line data={dataA} options={options}/>       
        </div>
    );
};

const options = {
    plugins: {
        legend: {
            display: false,
            labels:{
                boxWidth: 0,
            }
         } },
    scales: {
        x: {
          grid: {
            display: false
          },
          ticks: {
            display: false 
            }
        },
        y: {
          grid: {
            display: false
          },
          ticks: {
            display: false 
            }
        }
      }
};  

export default WI;