import React, { useContext, useEffect, useState, useRef } from 'react';
import { AppContext } from '../../context/AppContext';

import {
    BrowserRouter as Router,
    Link,
    useParams,
    useLocation,
    useHistory
  } from "react-router-dom"

import './MarketNews.css'


const MarketNews = (props) => {
    const {user} = useContext(AppContext);
    const [pos,setPos] = useState([])

    useEffect( async () => {
        var response = await fetch(`http://localhost:8000/api/portposition/${{user}['user']}`)
        let position = await response.json()
        setPos(position)
    }, [])

    return (
    <>
        <div className="Port_Layout">
            <table className='position'>
                <thead>
                    <tr>
                        <th><div className='left_item'>Symbol</div></th>
                        <th>Quantity</th>
                        <th>Cost Basis</th>
                        <th>Last Price</th>
                        <th>$ Today Gain/Loss</th>
                        <th>% Today Gain/Loss</th>
                        <th>$ Total Gain/Loss</th>
                        <th>% Total Gain/Loss</th>
                        <th>Current Value</th>
                    </tr>
                </thead>
                <tbody>
                    {pos && 
                    Object.keys(pos).map((p, idx) => {
                        return (
                            <tr>
                                <td className='symbol_name'><h5>{p}</h5></td>
                                {Object.keys(pos[p]).map((item, idx2) => {
                                    console.log(item)
                                    if (item != 'sum' && item != '% of Account'){ 
                                        if (item.includes('Gain')){
                                            if (pos[p][item].toFixed(2) < 0){
                                                if (item.includes('%')){
                                                    return (
                                                        <td><font color='red'>{pos[p][item].toFixed(2)}%</font></td>
                                                    )
                                                }
                                                else{
                                                    return (
                                                        <td><font color='red'>{pos[p][item].toFixed(2)}$</font></td>
                                                    )
                                                }
                                                
                                            }
                                            else{
                                                if (item.includes('%')){
                                                    return (
                                                        <td><font color='green'>{pos[p][item].toFixed(2)}%</font></td>
                                                    )
                                                }
                                                else{
                                                    return (
                                                        <td><font color='green'>{pos[p][item].toFixed(2)}$</font></td>
                                                    )
                                                }
                                            }   
                                        }
                                        else{
                                            return(<td><font color='black'>{pos[p][item].toFixed(2)}</font></td>)
                                        }
                                        }       
                                })
                                }
                            </tr>

                        )
                    })
                    }
                </tbody>
            </table>
        </div>
    </>
    );
};

export default MarketNews;
