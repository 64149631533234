import React, { useContext, useState, useEffect } from 'react';
import ExpenseItem from './ExpenseItem';
import { AppContext } from '../../context/AppContext';

const ExpenseList = () => {
	const { expenses } = useContext(AppContext);

	return (
		<div>
			<div className="Expense">
				<h2>Expense</h2>
			</div>
			<div className="Expense_List">
				{expenses.map((expense, idx) => (
					<ExpenseItem key={idx}
						id={expense.id}
						name={expense.name}
						cost={expense.cost}
						tags={expense.tags}
						date={expense.date}
					/>
				))}
			</div>
		</div>
	);
};

export default ExpenseList;

/*
<input
				type='text'
				className='Form'
				placeholder='Type to search...'
				onChange={handleChange}
			/>

*/