import React, { useContext, useState } from 'react';
import { FaMoneyBill } from "react-icons/fa";
import { RiVirusFill } from "react-icons/ri";
import { AiOutlineStock, AiFillAppstore } from "react-icons/ai";
import { TiWarningOutline } from "react-icons/ti";
import { GrMoney } from "react-icons/gr";
import './Sidebar.css'
import { AppContext } from '../context/AppContext';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  useRouteMatch,
  useParams
} from "react-router-dom"

import LoggedIn from './LoggedIn';

export default function Sidebar() {
  const { user, dispatch } = useContext(AppContext);

  const links = [
    {
      title: "budget",
      icon: FaMoneyBill,
    },
    {
      title: "stock",
      icon: AiOutlineStock,
    },

    {
      title: "portfolio",
      icon: GrMoney,
    },

    {
      title: "risk",
      icon: TiWarningOutline,
    },

    {
      title: "covid",
      icon: RiVirusFill,
    },
  ];

  return (
    <div className="sidebar">
      <div className="brand">
        <h2>
          <span>MA</span>
        </h2>
      </div>
      <div className="LoginItem">
        {user ? 
          (
            <LoggedIn />
          )
        : 
        (<Link to={`/login`} style={{ textDecoration: 'none' }}>
          Login
        </Link>)
        }
      </div>
      <ul className="links">
        {links.map((link, idx) => {
          return (
            <li key={idx}>
                <div className="SidebarItem">
                    {<link.icon />}
                    <Link to={`/${link.title}`}>{link.title}</Link>
                </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
}

/*
return (
    <div className="sidebar">
      <div className="brand">
        <h2>
          <span>MA</span>
        </h2>
      </div>
      <ul className="links">
        <li>
            <FaMoneyBill />
            <Link to="/budget">Budget</Link>
        </li>
        <li>  
            <AiOutlineStock />
            Stock
        </li>
        <li>
            <TiWarningOutline />
            Risk
        </li>
        <li>
            <RiVirusFill />
            Covid
        </li>
      </ul>
    </div>
  );
  */