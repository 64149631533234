import React, { useState, useContext } from 'react';
import { FaLess } from 'react-icons/fa';
import { AppContext } from '../../context/AppContext';
import './Register.css'

import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    useLocation
  } from "react-router-dom"

const Register = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isDisabled, setIsDisabled] = useState(true)
    const [emailValid, setEmailValid] = useState(false)
    const [passwordValid, setPasswordValid] = useState(false)

    const signIn = e => {
        e.preventDefault();
        console.log(email)
    }

    const handleChangeUsername = e => {
        setEmail(e.target.value)
    }

    React.useEffect( async () => {
        let answer = await validateEmail(email)
        setEmailValid(answer);
    }, [email]);

    React.useEffect(() => {
        setPasswordValid(validatePassword(password));
    }, [password]);

    React.useEffect(() => {
        if (emailValid === true && passwordValid === true){
            setIsDisabled(false)
        }
        else{
            setIsDisabled(true)
        }
    })

    const validateEmail = async email => {
        let response = await fetch('http://localhost:8000/api/users/')
		let data = await response.json()
        let EmailExists = await data.map(({email})=>email);
        if (typeof email === "string" && email.length >= 4 && EmailExists.includes(email) == false){
            return true
        }
        return false
    }

    const validatePassword = password => password.length >= 1

    const onClickRegister = async (event) => {
        const user = {
			email,
			password
		};

        let response = await fetch(`http://193.31.27.5:8000/api/users/register`, {
			method: 'POST',
			'headers': {
				'Content-Type': 'application/json'
			},
			'body': JSON.stringify(user)
		})

    }
    
	return (
		<div className='login'>
            <div className='login_container'>
                <h1>
                    Register
                </h1>
                <form>
                    <div className='Login_Text'>
                        E-Mail
                    </div>

                    <input type='text' 
                        value={email} 
                        onChange={handleChangeUsername}
                    >

                    </input>

                    <div className='Login_Text'>
                        Password
                    </div>

                    <input type='password' value={password} onChange={e => setPassword(e.target.value)}>

                    </input>

                    <Link to={`/login`}>
                        <button onClick={onClickRegister} className='Register_Button' disabled={isDisabled}>Register</button>
                    </Link>
                </form>
            </div>
            
		</div>
	);
};

export default Register;