import React, { useContext, useEffect, useState, useRef } from 'react';
import {
    BrowserRouter as Router,
    Link,
    useParams,
    useLocation,
    useHistory
  } from "react-router-dom"

import SearchBar from '../SearchBar/SearchBar';
import './StockNews.css'
import StockNavbar from './StockNavbar'


const Analyst = (props) => {
    const [symbol, setSymbol] = useState('')
    const history = useHistory();
    const location = useLocation();
    const [, forceUpdate] = useState();
    const { symbolURL } = useParams();
    const [result ,setResult] = useState('')
    const [isLoading, setLoading] = useState(true)

    useEffect( async () => {
        setSymbol(location.pathname.split('/')[2])
        if (symbol != ''){
            history.go(0)
        }
    }, [symbolURL])

    return (
    <>
        <SearchBar/>
        <div className="Stock_Layout">
            
            <h1>{symbol}</h1>
            <StockNavbar/>
            price target
            buy, bold, sell
            Short selling
            put/call ratio
            Social sentiment
        </div>
    </>
    );
};

export default Analyst;

//{symbol && <StockChart symbol={symbol}/>}
//{count &&<ChartTest3 data={count} len={len} ClosePrice={ClosePrice}/>}
/*
{Object.keys(count)
            .map((item, idx) => {
                return(
                    <h5 key={idx}>{count[item]}</h5>
                )
            })}
            */
