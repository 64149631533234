import React, { useEffect, useRef, useState } from "react";
import TradingViewWidget, { Themes } from "react-tradingview-widget";
import SearchBar from "../SearchBar/SearchBar";
import './StockNews.css'
import './TradingChart.css'
import StockNavbar from './StockNavbar'
import StockPrice from './StockPrice'
import { AdvancedRealTimeChart } from "react-ts-tradingview-widgets";
import {
  BrowserRouter as Router,
  Link,
  useParams,
  useLocation,
  useHistory
} from "react-router-dom"

const TradingChart = (props) => {
  const tradingRef = useRef(null);
  const location = useLocation();
  const [symbol, setSymbol] = useState(location.pathname.split('/')[2]);

  return (
    <>
        <SearchBar/>
        <div className="Stock_Layout">
            <StockPrice/>
            <StockNavbar/>
            <div className="TD_Chart">

            <TradingViewWidget
              symbol={symbol}
              theme={Themes.dark}
              locale="en"
              hide_side_toolbar={false}
            />
            </div>
            
            
        </div>
    </>
  );
};
export default TradingChart;

/*
            <AdvancedRealTimeChart 
                symbol={symbol}
                theme={Themes.LIGHT}
                locale="en"
            />
            */