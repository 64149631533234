import React, { useContext, useEffect, useState, useRef } from 'react';
import {
    BrowserRouter as Router,
    Link,
    useParams,
    useLocation,
    useHistory
  } from "react-router-dom"

import SearchBar from '../SearchBar/SearchBar';
import './StockNews.css'
import StockNavbar from './StockNavbar'
import StockPrice from './StockPrice'


const StockNews = (props) => {
    const [symbol, setSymbol] = useState('')
    const history = useHistory();
    const location = useLocation();
    const [, forceUpdate] = useState();
    const { symbolURL } = useParams();
    const [result ,setResult] = useState('')
    const [isLoading, setLoading] = useState(true)
    const [V, setV] = useState()
    const [R, setR] = useState()
    const [P, setP] = useState()
    

    useEffect( async () => {
        setSymbol(location.pathname.split('/')[2])
        if (symbol != ''){
            history.go(0)
        }
        let response = await fetch(`http://193.31.27.5:8000/api/stockmarketprice/${location.pathname.split('/')[2]}/news`)
        let data = await response.json()

        let response1 = await fetch(`http://193.31.27.5:8000/api/stockprice/${symbolURL}`)
        let dataStock = await response1.json()

        var StockPrice = dataStock['daily']['Price']
        var index = Object.keys(StockPrice).length
        index = Object.keys(StockPrice)[index - 1]

        var index_2 = Object.keys(dataStock['daily']['Close'])
        var ClosePrice = dataStock['daily']['Close'][index_2[0]]
        console.log(ClosePrice)

        setV(StockPrice[index].toFixed(2))
        setR((StockPrice[index].toFixed(2) - ClosePrice).toFixed(2))
        setP(((StockPrice[index].toFixed(2) - ClosePrice) / ClosePrice * 100).toFixed(2))

        setResult(data)
    }, [symbolURL])

    return (
    <>
        <SearchBar />
        <div className="Stock_Layout">
            <StockPrice/>
            <StockNavbar/>
            {result ? <div className='News'>
                {Object.keys(result).map((week, idx) => {
                    return(
                        <div className="News_Day" key={`p-${idx}`}>
                            <div className='News_WeekName'>
                                <h3>{week}</h3>
                            </div>
                            <ItemNews value={result[week]}/>
                        </div>
                    )
                }
                )}
            </div>
            :
            <h1>Loading</h1>}
        </div>
    </>
    );
};

const ItemNews = (props) => {
    var a = props.value
    return (
        <div className="">
            {Object.keys(a['News']).map(
                (item, idx) => {
                    return(
                        <div className="NewsItem" key={idx}>
                            <div className="News_Title">
                                <Link to={{ pathname: `https://${a['Link'][item].slice(7)}` }} target="_blank">
                                    {a['News'][item]}
                                </Link>
                            </div>
                            <div className="News_Desc">{a['Desc'][item]}</div>
                        </div>
                    )
                }
            )}
        </div>
    );
};

export default StockNews;

//{symbol && <StockChart symbol={symbol}/>}
//{count &&<ChartTest3 data={count} len={len} ClosePrice={ClosePrice}/>}
/*
{Object.keys(count)
            .map((item, idx) => {
                return(
                    <h5 key={idx}>{count[item]}</h5>
                )
            })}

            <div className='StockNews'>
                <div className="News_All">
                    {result && Object.keys(result['date']).map(
                        (item, idx) => {
                            return(
                                <div className = "NewsItem"key= {idx}>
                                    <div className="NewsItem_Title" >
                                        <Link to={{ pathname: `https://${result['link'][item].slice(7)}` }} target="_blank">
                                            {result['title'][item]}
                                        </Link>
                                    </div>
                                    <div className="NewsItem_Desc">{result['source'][item]}, {result['date'][item]}</div>
                                </div>
                            )
                        }
                    )}  
                </div>
            </div>
            */
