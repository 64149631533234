import React, { useContext, useEffect, useState, useRef } from 'react';

import {
    BrowserRouter as Router,
    Link,
    useParams,
    useLocation,
    useHistory
  } from "react-router-dom"

import SearchBar from '../SearchBar/SearchBar';
import './AI.css';
import Search from './Search';


const Research = (props) => {

    return (
    <>
        <SearchBar/>
        <div className="Port_Layout">
            <Search/>
        </div>
    </>
    );
};

export default Research;
