import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../context/AppContext';
import WI from './WI'
import './InvestmentList.css'
import Test1 from './Test1'


const InvestmentList = (props) => {
    const [result, setResult] = useState([]);

    useEffect(() => {
        var aa = props.data
        setResult(props.data)
        Object.keys(aa).map((item, idx) => {
            (
                console.log(aa[item])
            )
        })
    }, [])

	return (
        <div className="Port_List">
            {result && Object.keys(result)
            .map((item, idx) => {
                return(
                    <div className='Port_List_PW' key={idx} >
                        <h3>{item}</h3>
                        <Test1 pos={result[item]}/>
                    </div>
                )
            })}
        </div>
	);
};

export default InvestmentList;

//<WI pos={result[item]}/>
//<ChartTest3 data={count} len={0} ClosePrice={ClosePrice}/>

/* import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../context/AppContext';
import WI from './WI'
import './InvestmentList.css'

const InvestmentList = (props) => {
    const { budget, porto} = useContext(AppContext);
    const [result, setResult] = useState([]);

    useEffect(() => {
        setResult(props.data)
    }, [])

	return (
        <div className="Port_List">
            {result && Object.keys(result)
            .map((item, idx) => {
                return(
                    <div className='Port_List_PW' key={idx} >
                        <h3>{item}</h3>
                        <WI pos={result[item]}/>
                    </div>
                )
            })}
        </div>
	);
};

export default InvestmentList;
*/