import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../context/AppContext';
import { v4 as uuidv4 } from 'uuid';
import { v1 as uuidv1 } from 'uuid';
import './BuyStock.css'
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    useLocation,
    useHistory
  } from "react-router-dom"

const BuyStock = (props) => {
    const [amount, setAmount] = useState()
    const [Type, setType] = useState('share')
    const [Acc, setAcc] = useState('All Accounts')
    const {user} = useContext(AppContext);
    const history = useHistory();
    const [cash, setCash] = useState();

    useEffect( () => {
        getCash()
    }, [])

    const getCash = async () => {
        let response = await fetch(`http://193.31.27.5:8000/api/cash/${user}`)
        let data = await response.json()
        setCash(data['Cash'])
        console.log(data['Cash'])
    }

	const ClickShare = (e) => {
        e.preventDefault()
        setType('share')
    }

    const ClickDollar = (e) => {
        e.preventDefault()
        setType('dollar')
    }

    const ClickBuy = async (e) =>{
        e.preventDefault()
        var addItem
        if(Type == 'share'){
            addItem = {
                symbol: props.symbol.toUpperCase(),
                price: props.Price,
                amount: amount,
                account: Acc,
            };
        }
        else{
            addItem = {
                symbol: props.symbol.toUpperCase(),
                price: props.Price,
                amount: amount/props.Price,
                account: Acc,
            }
        }
        
        let response = await fetch(`http://193.31.27.5:8000/api/activity/${{user}['user']}/create`, {
        method: 'POST',
        'headers': {
            'Content-Type': 'application/json'
        },
        'body': JSON.stringify(addItem)
        })
        history.go(0)
    }

	return (
        <form>
            <div className='BuyStock_Box'>
                <div className='OrderLayout'>
                    <dl className='OrderForm'>
                        <dt>Type</dt>
                        <dd>
                            <div className='OrderType'>
                                <button 
                                onClick={ClickShare} 
                                style={{"backgroundColor":(Type=='share')?'rgb(200, 200, 200)':'rgba(38, 49, 60, .05)'}}
                                >Share</button>
                                <button 
                                onClick={ClickDollar}
                                style={{"backgroundColor":(Type=='dollar')?'rgb(200, 200, 200)':'rgba(38, 49, 60, .05)'}}
                                >Dollar</button>
                            </div>
                        </dd>
                        <dt>Amount</dt>
                        <dd>
                            <div className='OrderInput'>
                                <input
                                placeholder='0'
                                value = {amount}
                                onChange = {(e) => setAmount(e.target.value)}/>
                            </div>
                        </dd>
                        <dt>Market Price</dt>
                        <dd></dd>
                        <dd>{props.Price.toFixed(2)}</dd>
                        <dt>{(Type=='share')?'Est. Cost':'Est. Qty'}</dt>
                        <dd></dd>
                        <dd>
                            {(Type=='share')
                            ?
                            ((amount == undefined) ? 0 : (amount * props.Price).toFixed(2))
                            :
                            ((amount == undefined) ? 0 : (amount/props.Price).toFixed(2))}
                        </dd>
                    </dl>
                </div>
                {amount == undefined && <div className='Order'>
                    <button onClick={ClickBuy}>Buy</button>
                </div>}
                {Type=='share' && (amount * props.Price) <= cash && <div className='Order'>
                    <button onClick={ClickBuy}>Buy</button>
                </div>}
                {Type=='share' && (amount * props.Price) > cash && 
                <>
                    <div className='Order'>
                    <button disabled='True' onClick={ClickBuy}>Buy</button>
                    </div>
                    <div className="Order_Desc">Not enough cash</div>
                </>}
                {Type=='dollar' && amount <= cash && <div className='Order'>
                    <button onClick={ClickBuy}>Buy</button>
                </div>}
                {Type=='dollar' && amount > cash && 
                <>
                    <div className='Order'>
                    <button disabled='True' onClick={ClickBuy}>Buy</button>
                    </div>
                    <div className="Order_Desc">Not enough cash</div>
                </>}

                <div className='Order_Desc'>
                    Cash: {cash && cash.toFixed(2)}
                </div>
                
            </div>
        </form>
	);
};

export default BuyStock;

/*
import React, { useContext, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { v4 as uuidv4 } from 'uuid';
import { v1 as uuidv1 } from 'uuid';
import './BuyStock.css'

const BuyStock = (props) => {
	const { expenses, dispatch } = useContext(AppContext);

	const [buyDate, setBuyDate] = useState('2022-02-01');
    const [amount, setAmount] = useState('');

	const onSubmit = async (event) => {
		const stock = {
			symbol: props.symbol,
			buyDate,
		};
        for (var x=0;x<amount;x++)
            {
                let response = await fetch(`http://193.31.27.5:8000/api/investment/create`, {
                method: 'POST',
                'headers': {
                    'Content-Type': 'application/json'
                },
                'body': JSON.stringify(stock)
                })
                let data = await response.json()

                dispatch({
                    type: 'ADD_INVEST',
                    payload: stock,
                });
            }

        setAmount(0)
	};

	return (
        <form>
            <div className='BuyStock_Box'>
            
                <div className='BuyStock_Input'>
                    <div className='BuyStock_Input_Amount'>
                        <label htmlFor='name'>Amount</label>
                        <input
                            required='required'
                            type='number'
                            className='form-control'
                            id='amount'
                            value={amount}
                            onChange={(event) => setAmount(event.target.value)}
                        />
                    </div>
                    <div className='BuyStock_Input_Date'>
                        <label htmlFor='cost'>Date</label>
                        <input
                            type='text'
                            className='form-control'
                            id='date'
                            value={buyDate}
                            onChange={(event) => setBuyDate(event.target.value)}
                        />
                    </div>
                </div>
                <div className='BuyStock_Button'>
                    <button type='submit' className='btn btn-primary'>
                        Buy
                    </button>
                </div>
            
            </div>
        </form>
	);
};

export default BuyStock;
*/

/*
return (
        <form>
            <div className='BuyStock_Box'>
                <div className='OrderLayout'>
                    <dl className='OrderForm'>
                        <dt>Account</dt>
                        <dd>
                            <select value={Acc} onChange={e => setAcc(e.target.value)} className='Acc_Select'>
                                {props.Acc.map((p, idx) => { 
                                    return(
                                        <option className='Acc_Option'>
                                            {p}
                                        </option>
                                    )
                                })}
                            </select>
                        </dd>
                        <dt>Type</dt>
                        <dd>
                            <div className='OrderType'>
                                <button 
                                onClick={ClickShare} 
                                style={{"backgroundColor":(Type=='share')?'rgb(200, 200, 200)':'rgba(38, 49, 60, .05)'}}
                                >Share</button>
                                <button 
                                onClick={ClickDollar}
                                style={{"backgroundColor":(Type=='dollar')?'rgb(200, 200, 200)':'rgba(38, 49, 60, .05)'}}
                                >Dollar</button>
                            </div>
                        </dd>
                        <dt>Amount</dt>
                        <dd>
                            <div className='OrderInput'>
                                <input
                                placeholder='0'
                                value = {amount}
                                onChange = {(e) => setAmount(e.target.value)}/>
                            </div>
                        </dd>
                        <dt>Market Price</dt>
                        <dd></dd>
                        <dd>{props.Price.toFixed(2)}</dd>
                        <dt>{(Type=='share')?'Est. Cost':'Est. Qty'}</dt>
                        <dd></dd>
                        <dd>
                            {(Type=='share')
                            ?
                            ((amount == undefined) ? 0 : (amount * props.Price).toFixed(2))
                            :
                            ((amount == undefined) ? 0 : (amount/props.Price).toFixed(2))}
                        </dd>
                    </dl>
                </div>
                <div className='Order'>
                    <button onClick={ClickBuy}>Buy</button>
                </div>
                
            </div>
        </form>
	);
};
*/
