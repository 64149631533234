import React, { useContext, useState } from 'react';
import { BiUserCircle } from "react-icons/bi";
import { FaRegUserCircle } from "react-icons/fa";
import { FiSearch } from "react-icons/fi";

import { AppContext } from '../../context/AppContext';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    useLocation,
    useHistory
  } from "react-router-dom"
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import './SearchBar.css'



const SearchBar = (props) => {
    const [symbol, setSymbol] = useState('')
    let history = useHistory();

    const _pressEnter = (event) => {
        if(event.key === 'Enter'){ 
            window.clearInterval(window.asd)
            history.push(`/stock/${symbol}/overview`)
        }
    }

	return (
        <div className="SearchBox">
            <div className='Symbol'>MA</div>
            <div>
                <form className='Input'>
                    <div className='Icon'><FiSearch/></div>
                    <input
                        type='text'
                        placeholder='Search Stock'
                        value={symbol}
                        onChange={(event) => setSymbol(event.target.value)}
                        onKeyDown={(event) => _pressEnter(event)}
                    />
                </form>
            </div>
            <div className='Navigation'>
                <Link to={`/portfolio/overview`}>
                    Portfolio
                </Link>
                <Link to={`/research`}>
                    Research
                </Link>
                <Link>
                    <FaRegUserCircle size={20}/>
                </Link>
            </div>
        </div>
	);
};

export default SearchBar;
