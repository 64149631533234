import React, { useState, useEffect} from 'react';
import './EcoCalender.css'

const EcoCalender = (props) => {
    const [result, setResult] = useState([]);
    const a = ['THIS WEEK']

    useEffect(() => {
        getData()
    }, [])

    let getData = async () => {
        let response = await fetch('http://193.31.27.5:8000/api/ecocalender/')
        let data = await response.json()
        setResult(data)
    }

    if(result['THIS WEEK']){
        return (
            <div className='Calender'>
                {a.map(
                    (week, idx) => {
                        return(
                            <div className="Weekly" key={`p-${idx}`}>
                                <h3>{week}</h3>
                                <Graphing value = {result[week]}/>
                            </div>
                        )
                    }
                )}
            </div>
        );
    }
    else{
        return (<></>)
    }
    
};

const Graphing = (props) => {
    var a = props.value
    return (
        <div className="">
            {Object.keys(a).map(
                (day, idx) => {
                    return(
                        <div className="daily" key={idx}>
                            <div className="date">{day}</div>
                            <Report date = {day} value = {a[day]}/>
                        </div>
                    )
                }
            )}
        </div>
    );
};

const Report = (props) => {
    var a = props.value
    return (
        <div className="report">
            {a['Report'].map(
                (rep, idx) => {
                    return (
                        <div className={"rep"} key={`k=${idx}`}>{rep}</div>
                    )
                }
            )}
        </div>
    );
};

export default EcoCalender;
/*

<table>
                            <thead>
                                <tr>
                                <th>Time (ET)</th>
                                <th>Report</th>
                                <th>Period</th>
                                <th>Actual</th>
                                <th>Median Forecast</th>
                                <th>Previous</th>
                                </tr>
                            </thead>
                        </table>

<tbody>
                    {Object.keys(a).map(
                        (item, idx) => {
                            return (
                                <tr>
                                <th>{a[{item}]['Time (ET)']}</th>
                                <th>{a[{item}]['Report']}</th>
                                <th>{a[{item}]['Period']}</th>
                                <th>{a[{item}]['Actual']}</th>
                                <th>{a[{item}]['Median Forecast']}</th>
                                <th>{a[{item}]['Previous']}</th>
                                </tr>
                            )
                        }
                    )}
                </tbody>
                */