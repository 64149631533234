import React, { useState, useContext, useEffect } from 'react';
import ViewBudget from './ViewBudget';
import EditBudget from './EditBudget';
import { AppContext } from '../../context/AppContext';
import './Budget.css'

const Budget = () => {
	const { budget, user, dispatch } = useContext(AppContext);
	const userName = user;

	const [isEditing, setIsEditing] = useState(false);

	const handleEditClick = () => {
		setIsEditing(true);
	};

	const FindUserID = async () => {
		let response = await fetch(`http://127.0.0.1:8000/api/users/${user}`)
		let data = await response.json()
		return data
	}

	useEffect( async () => {
		let response2 = await fetch(`http://127.0.0.1:8000/api/budgets/${user}`)
		let data = await response2.json()

		dispatch({
			type: 'SET_BUDGET',
			payload: data.budget,
		});
	}, [])

	const handleSaveClick = async (value) => {
		let user = await FindUserID()
		let budget = value
		const users = {
			budget,
			user
		};

		let response = await fetch(`http://127.0.0.1:8000/api/budgets/${userName}/change`, {
			method: 'POST',
			'headers': {
				'Content-Type': 'application/json'
			},
			'body': JSON.stringify(users)
		})

		

		setIsEditing(false);
	};
	
	return (
		<div className='Budget'>
			{isEditing ? (
				<EditBudget handleSaveClick={handleSaveClick} budget={budget} />
			) : (
				<ViewBudget handleEditClick={handleEditClick} budget={budget} />
			)}
		</div>
	);
};

export default Budget;