import React, { createContext, useReducer, useState, useEffect } from 'react';

// 5. The reduceer - this is used to update the state, based on the action
export const AppReducer = (state, action) => {
	switch (action.type) {
		case 'ADD_EXPENSE':
			return {
				...state,
				expenses: [...state.expenses, action.payload],
			};

		case 'DELETE_EXPENSE':
			return {
				...state,
				expenses: state.expenses.filter(
					(expense) => expense.id !== action.payload
				),
			};

		case 'DELETE_ALL_EXPENSE':
			return {
				...state,
				expenses: [
				],
			}

		case 'DELETE_WATCHLIST':
			return {
				...state,
				watchlist: state.watchlist.filter(
					(item) => item.id !== action.payload
				),
			};
		case 'SET_BUDGET':
			return {
				...state,
				budget: action.payload,
			};

		case 'ADD_INVEST':
			return {
				...state,
				porto: [...state.porto, action.payload],
			};

		case 'ADD_WATCHLIST':
			return {
				...state,
				watchlist: [...state.watchlist, action.payload],
			};

		case 'SIGN_IN':
			return {
				...state,
				user: action.payload,
			}
		
		case 'SIGN_OUT':
			return {
				...state,
				user: '',
			}

		default:
			return state;
	}
};

export const AppContext = createContext();

export const AppProvider = (props) => {
	const initialState = {
		budget: 3000,
		expenses: [
		],
		porto: [
		],
		watchlist: [
		],
		user:'admin',
		inv: [],
	};
	const [state, dispatch] = useReducer(AppReducer, initialState);

	let [expense, setExpense] = useState([])
	let [port, setPort] = useState([])
	let [watchlist_list, setWatchlist_list] = useState([])
	let [user, setUser] = useState('admin')
	const [isLoading, setLoading] = useState(true);


    useEffect(async () => {
		let response2 = await fetch('http://193.31.27.5:8000/api/investment/')
		let data2 = await response2.json()
		setPort(data2)
		{data2.map((p, idx) => { 
			dispatch({
				type: 'ADD_INVEST',
				payload: p,
			})
		})}

		let response3 = await fetch('http://193.31.27.5:8000/api/watchlist/')
		let data3 = await response3.json()
		setPort(data3)
		{data3.map((p, idx) => { 
			dispatch({
				type: 'ADD_WATCHLIST',
				payload: p,
			})
		})}

		setLoading(false)
    }, [])

	if (isLoading) {
		return ''
	  }
	return (
		<AppContext.Provider
			value={{
				expenses: state.expenses,
				budget: state.budget,
				porto: state.porto,
				watchlist: state.watchlist,
				user: state.user,
				inv: state.inv,
				dispatch,
			}}
		>
			{props.children}
		</AppContext.Provider>
	);
};