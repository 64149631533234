import React, { useContext, useState, useEffect, useRef } from 'react';
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts/highstock";
import './FundBarChart.css'
import { AppContext } from '../../context/AppContext';

const FundBarChart = (props) => {
    const options = {
        title: {
            text: '',
        },
        chart: {
            type: 'column',
            animation: Highcharts.svg,
            width:900,
            height:300,
        },
      
        series: [{data:props.Y_Axis}]
        ,
        xAxis:{
            categories: props.X_Axis,
            title:'',      
        },
        yAxis:{
            title:'',      
        },
        credits: {
            enabled: false
        },
    }

    return (
        <div className="AI_StockChart">
            <HighchartsReact
                highcharts={Highcharts}
                options={options}
            />
        </div>
    )
}

export default FundBarChart

/*


    const options_3 = {
        plugins: {
            legend: {
                display: false,
                labels:{
                    boxWidth: 0,
                }
             } },
        scales: {
            x: {
              grid: {
                display: false
              },
              ticks: {
                display: false 
                }
            },
            y: {
              grid: {
                display: false
              },
              ticks: {
                display: false 
                }
            }
          }
    };  


    const options = {
        chart:{
            type: 'spline',
            animation: Highcharts.svg,
        },
        yAxis:{
            labels: {
                format: '{value:.2f}',
                enabled: false
            },
            opposite: true,
            title:'',      
        },
        credits: {
            enabled: false
        },
        series: [
            {
                name: '',
                data: props.data,
                color: 'green',
                showInLegend: false,
                states: {
                    hover: {
                        enabled: false,
                        lineWidth: 2,
                    }
                },
            },
            {
                name: '',
                data: props.ClosePrice,
                color: 'black',
                opacity: 0.5,
                showInLegend: false,
                enableMouseTracking: false,
                dashStyle: 'Dash'
            },

        ],
        title:{
            text: ''
        },
        tooltip: {
            crosshairs: [true],
            split: true,
        },
        plotOptions: {
            series: {
                point: {
                    events: {
                        load() {
                            setTimeout(this.reflow.bind(), 0);
                        },
                        mouseOver: (function() {
                            return function() {
                                var point = { x: this.x, y: this.y };
                                handleMouseOver(point);
                            };
                        })(this),
                        mouseOut: (function() {
                            return function(){
                                handleMouseOut();
                            };
                        })(this)
                    },
                },
            }
        },
    }


*/