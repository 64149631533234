/*
import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import './ChartTest.css'


class ChartTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      value: '',
      seconds: 0 ,
      dataset: []
    };
    this.chart = null;
    this.exportChart = () => {
      this.chart.exportChart();
    };
    this.myRef = React.createRef("chart");
  }

  handleMouseOver = point => {
    this.setState({value: point.y.toFixed(2)})
  };
  
  handleMouseOut= () => {
    var el = this.props.dataset_highchart[this.props.dataset_highchart.length - 1]
    this.setState({value: el[1].toFixed(2)})
  };

  componentDidMount(){
    var data = [],
      time = (new Date()).getTime(),
      i;

      for (i = 0; i <= 77; i += 1) {
        data.push({
          x: i,
          y: null
        });
      }
    this.setState({dataset: data});
  }

  render() {
    const options = {
      chart: {
        type: 'spline',
        animation: Highcharts.svg,
        marginRight: 10,
        events: {
          load: function () {
            var series = this.series[0];
            var idx = 0;

            setInterval(async function handle() {
              let response = await fetch(`http://193.31.27.5:8000/api/stock/AAPL`)
              let StockPrice = await response.json()
              let index = Object.keys(StockPrice)

              for (var ii = 0; ii < Object.keys(StockPrice).length-1; ii += 1) {
                if (series.points){
                  series.points[ii].update({
                    y: StockPrice[index[ii]],
                  }, true, false);
                }
                
              }

              idx++
            }, 3000);
          }
        }
      },
    
      series: [{
        name: 'Random data',
        data: this.state.dataset,
      }],
    };

    return (
      <div className = "StockPrice">
        <div>
          <h3>{this.state.value}</h3>
        </div>
        <div className="MainChart">
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
          />
        </div>
      </div>
    );
  }
}

export default ChartTest;

*/
import React from "react";
import HighchartsReact from "highcharts-react-official";
import Highcharts from "highcharts";
import './ChartTest.css'


class ChartTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = { 
      value: '',
      seconds: 0 };
    this.chart = null;
    this.exportChart = () => {
      this.chart.exportChart();
    };
    this.myRef = React.createRef("chart");
  }

  handleMouseOver = point => {
    this.setState({value: point.y.toFixed(2)})
  };
  
  handleMouseOut= () => {
    var el = this.props.dataset_highchart[this.props.dataset_highchart.length - 1]
    this.setState({value: el[1].toFixed(2)})
  };


  render() {
    const options = {

      credits: {
        enabled: false
      },
      legend: {
        display: false,
      },
      title:{
        text:''
      },
      chart: {
        type: "spline"
      },
      
      series: [
        {
          name: 'price',
          lineColor: '#000000',
          lineWidth: 2,
          showInLegend: false,
          data: this.props.dataset_highchart,
          states: {
              hover: {
                  enabled: false,
                  lineWidth: 0,
              }
          },
          tooltip: {
            enabled: false
          },
        }
      ],
      tooltip: {
        pointFormat: ''
      },
      xAxis: {
        labels: {
          enabled: false
        },
        title: {
            text: null
        },
        gridLineWidth: 0,
        tickLength: 0,
        lineWidth: 0.1,
        lineColor: '#000000'
        },
      yAxis: {
        max: this.props.maxx,
        min: this.props.minn,
        gridLineWidth: 0,
        tickLength: 0,
        title: {
            text: null
        },
        lineWidth: 0.1,
        lineColor: '#000000'
        },
      plotOptions: {
        spline: {
          marker: {
              enabled: false
            }
        },
        series: {
          tooltip: {
            visible: false
          },
          point: {
            events: {
              load() {
                setTimeout(this.reflow.bind(), 0);
              },
              mouseOver: (function(self) {
                return function() {
                  var point = { x: this.x, y: this.y };
                  self.handleMouseOver(point);
                };
              })(this),
              mouseOut: (function(self) {
                return function(){
                  self.handleMouseOut();
                };
              })(this)
            },
          }
        }
      }
    };

    return (
      <div className = "StockPrice">
        <div>
          <h3>{this.state.value}</h3>
        </div>
        <div className="MainChart">
          <HighchartsReact
            highcharts={Highcharts}
            options={options}
          />
        </div>
      </div>
    );
  }
}

export default ChartTest;

