import React, { useContext, useEffect, useState, useRef } from 'react';
import { AppContext } from '../../context/AppContext';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    useLocation,
    useHistory
  } from "react-router-dom"
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

import Select from 'react-select'

import SearchBar from '../SearchBar/SearchBar'
import ChartTest3 from './ChartTest3'
import Port_Navbar from './Port_Navbar'
import InvestmentList from './InvestmentList'
import PosWL from './PosWL';
import './Portfolio.css'

import Position from '../portfolio_url/Position';
import EcoCalender from '../portfolio_url/EcoCalender';
import Deposit from './Deposit';



const Stock = (props) => {
    const [symbol, setSymbol] = useState('')
    const history = useHistory();
    const location = useLocation();
    const { symbolURL } = useParams();
    var [count, setCount] = useState([]);
    var [port_value, setPort] = useState([]);
    const [len, setLen] = useState([])
    const [AllData, setAllData] = useState()
    const TimeFrame = useRef('daily');
    const [ClosePrice, setClosePrice] = useState([])
    const Index = useRef(78)
    const [V, setV] = useState()
    const [R, setR] = useState()
    const [P, setP] = useState()
    const [Watchlist, setWatchList] = useState()
    const {user} = useContext(AppContext);
    const [ListAcc, setListAcc] = useState([])
    const [AllAcc, setAllAcc] = useState([])
    const [Acc, setAcc] = useState('All Accounts')
    const [AccSelection, setAccSelection] = useState()
    const [Pos, setPos] = useState()
    const [AllPos, setAllPos] = useState()
    const [Cash, setCash] = useState()

    const getAccount = async () => {
        let response3 = await fetch(`http://193.31.27.5:8000/api/account/${{user}['user']}`)
		let data3 = await response3.json()
        let res = []

        data3.map((p, idx) => { 
            res.push(p.portfolio)
        })
        setListAcc(res)

        setAllAcc(data3)

        var res1 = []
        data3.map((p, idx) => { 
            res1.push({label: p.portfolio})
        })
        setAccSelection(res1)
    }

    useEffect(async () => {
        getAccount()
        getPosition(Acc)
        IntervalFunction(Acc)
    }, [])

    useEffect( async () => {
        setSymbol(location.pathname.split('/')[2])
        if (symbol != ''){
            history.go(0)
        }
        let response3 = await fetch(`http://193.31.27.5:8000/api/watchlist/${{user}['user']}`)
		let data3 = await response3.json()
		setWatchList(data3)
    }, [symbolURL])

    const getPosition = async ( Account ) => {
        var response
        if (Account == 'All Accounts'){
            response = await fetch(`http://193.31.27.5:8000/api/position/${{user}['user']}`)
        }
        else{
            var AccId = AllAcc.filter(item => item.portfolio == Account)
            response = await fetch(`http://193.31.27.5:8000/api/position/${{user}['user']}/${AccId[0].id}`)
        }
        let data = await response.json()
        setAllPos(data)
        setPos(Object.keys(data))
    }

    const IntervalFunction = async (Account) => {
        window.clearInterval(window.asd)
        var cc = 0
        window.asd = setInterval( async function () {
            console.log("port")
            var response 
            if (Account == 'All Accounts'){
                response = await fetch(`http://193.31.27.5:8000/api/transaction/${{user}['user']}`)
            }
            else{
                var AccId = AllAcc.filter(item => item.portfolio == Account)
                response = await fetch(`http://193.31.27.5:8000/api/transaction/${{user}['user']}/${AccId[0].id}`)
            }
            let dataStock = await response.json()
            //let StockPrice = dataStock["Change, 1d, 5m"]
            setAllData(dataStock)
            

            let StockPrice = dataStock[TimeFrame.current]
            let Port = dataStock[TimeFrame.current + '_port']
            var data = []
            var Port_data = []
            let index = Object.keys(StockPrice)
            var data_2 = []

            for (var i = 0; i <= Index.current; i += 1){
                data_2.push(
                    0
                )
                if (i==0){
                    data.push(
                        StockPrice[index[i]]
                    );
                    Port_data.push(
                        Port[index[i]]
                    );
                }
                else{
                    if (StockPrice[index[i]]===undefined){
                        data.push(null)
                        Port_data.push(null)
                    }
                    else{
                        data.push(
                            StockPrice[index[i]]
                        );
                        Port_data.push(
                            Port[index[i]]
                        );
                    }
                    
                }
            }
            setCash(dataStock['Cash'])
            setPort(Port_data)
            setLen(index.length)
            setCount(data)
            setClosePrice(data_2)
        },3000)
    }

    const onClick1d = () => {
        TimeFrame.current = 'daily'
        Index.current = 78
        let StockPrice = AllData["daily"]
        let Port = AllData["daily_port"]
        var data = []
        var data_2 = []
        var Port_data = []
        let index = Object.keys(StockPrice)
        for (var i = 0; i <= 78; i += 1){
            data_2.push(
                0
            )
            if (i==0){
                data.push(
                    StockPrice[index[i]]
                );
                Port_data.push(
                    Port[index[i]]
                );
            }
            else{
                if (StockPrice[index[i]]===undefined){
                    data.push(null)
                    Port_data.push(null)
                }
                else{
                    data.push(
                        StockPrice[index[i]]
                    );
                    Port_data.push(
                        Port[index[i]]
                    );
                }
                
            }
        }
        setPort(Port_data)
        setLen(index.length)
        setCount(data);
        setClosePrice(data_2)
    }

    const onClick1w = () => {
        TimeFrame.current = 'weekly'
        let StockPrice = AllData["weekly"]
        let Port = AllData['weekly_port']
        Index.current = 66
        var data = []
        var data_2 = []
        var Port_data = []
        let index = Object.keys(StockPrice)
        console.log(index.length)
        for (var i = 0; i <= 66; i += 1){
            data_2.push(
                0
            )
            if (i==0){
                data.push(
                    StockPrice[index[i]]
                );
                Port_data.push(
                    Port[index[i]]
                );
            }
            else{
                if (StockPrice[index[i]]===undefined){
                    data.push(null)
                    Port_data.push(null);
                }
                else{
                    data.push(
                        StockPrice[index[i]]
                    );
                    Port_data.push(
                        Port[index[i]]
                    );
                }
                
            }
        }
        setPort(Port_data)
        setLen(index.length)
        setCount(data);
        setClosePrice(data_2)
    }

    const onClick1m = () => {
        TimeFrame.current = 'monthly'
        let StockPrice = AllData["monthly"]
        let Port = AllData['monthly_port']
        Index.current = 98
        var data = []
        var data_2 = []
        var Port_data = []
        let index = Object.keys(StockPrice)
        for (var i = 0; i <= 97; i += 1){
            data_2.push(
                0
            )
            if (i==0){
                data.push(
                    StockPrice[index[i]]
                );
                Port_data.push(
                    Port[index[i]]
                );
            }
            else{
                if (StockPrice[index[i]]===undefined){
                    data.push(null)
                    Port_data.push(null);
                }
                else{
                    data.push(
                        StockPrice[index[i]]
                    );
                    Port_data.push(
                        Port[index[i]]
                    );
                }
            }
        }
        setPort(Port_data)
        setLen(index.length)
        setCount(data)
        setClosePrice(data_2)
    }

    const onClick1y = () => {
        TimeFrame.current = 'yearly'
        let StockPrice = AllData["yearly"]
        let Port = AllData['yearly_port']
        var data = []
        var data_2 = []
        var Port_data = []
        let index = Object.keys(StockPrice)
        Index.current = 254
        for (var i = 0; i <= 254; i += 1){
            data_2.push(
                0
            )
            if (i==0){
                data.push(
                    StockPrice[index[i]]
                );
                Port_data.push(
                    Port[index[i]]
                );
            }
            else{
                if (StockPrice[index[i]]===undefined){
                    data.push(null)
                    Port_data.push(null);
                }
                else{
                    data.push(
                        StockPrice[index[i]]
                    );
                    Port_data.push(
                        Port[index[i]]
                    );
                }
                
            }
        }
        setPort(Port_data)
        setLen(index.length)
        setCount(data);
        setClosePrice(data_2)
    }

    const ChangeAcc = (e) => {
        setAcc(e.target.value)
        setCount([])
        IntervalFunction(e.target.value)
        getPosition(e.target.value)

    }

    if (count.length != 0 && ClosePrice.length!=0){return (
        <>
        <SearchBar/>
        <div className='Port_Layout'>
            <div className='Chart_Port'>
                <div className='Chart_BuySell'>
                    <div>
                        <h2>{V}</h2>
                        {(P >= 0) ? 
                        <h4 style={{ color: 'green' }}>{R}(+{P}%)</h4>
                        :
                        <h4 style={{ color: 'red' }}>{R}({P}%)</h4>
                        }
                        <div className="Stock_TimeFrame">
                            <button onClick={onClick1d}>1d</button>
                            <button onClick={onClick1w}>1w</button>
                            <button onClick={onClick1m}>1m</button>
                            <button onClick={onClick1y}>1y</button>
                        </div>
                        {count && <ChartTest3 data={count} len={len} ClosePrice={ClosePrice}
                        setV={setV} setR={setR} setP={setP} port={port_value}/>}
                    </div>
                </div>
                <div>
                    <Deposit/>
                    <PosWL Watchlist={Watchlist} AllPos={AllPos} Cash={Cash}/>
                </div>  
            </div> 
        </div>
        <Position/>
        </>
        
	)}
    else{
        return (
            <>
            <SearchBar/>
            <div className='Port_Layout'>
                <h1>Loading</h1>
            </div>
        </>
        )
    }
	
};

export default Stock;

//{symbol && <StockChart symbol={symbol}/>}
//{count && <ChartTest3 data={count}/>}
/*
{Object.keys(count)
            .map((item, idx) => {
                return(
                    <h5 key={idx}>{count[item]}</h5>
                )
            })}
            */

/*
<div className="SecondGraph">
                {<InvestmentList data = {AllData['highlight']}/>}
            </div>
            */

/*
if (count.length != 0 && ClosePrice.length!=0){return (
        <>
        <SearchBar/>
        <div className='Port_Layout'>
            <select value={Acc} onChange={ChangeAcc} className='Acc_Select'>
                {ListAcc.map((p, idx) => { 
                    return(
                        <option className='Acc_Option'>
                            {p}
                        </option>
                    )
                })}
            </select>
            <Port_Navbar/>
            <div className='Chart_BuySell'>
                <div>
                    <h2>{V}</h2>
                    {(P >= 0) ? 
                    <h4 style={{ color: 'green' }}>{R}(+{P}%)</h4>
                    :
                    <h4 style={{ color: 'red' }}>{R}({P}%)</h4>
                    }
                    <div className="Stock_TimeFrame">
                        <button onClick={onClick1d}>1d</button>
                        <button onClick={onClick1w}>1w</button>
                        <button onClick={onClick1m}>1m</button>
                        <button onClick={onClick1y}>1y</button>
                    </div>
                    {count && <ChartTest3 data={count} len={len} ClosePrice={ClosePrice}
                    setV={setV} setR={setR} setP={setP}/>}
                </div>
            </div>
            <div className='Watchlist'>
                <div>
                    Watchlist:
                </div>
                {Watchlist.map((p, idx) => { 
                    return(
                        <div>
                        {p.symbol}
                        </div>
                    )
                })}
                <div>
                    Position:
                </div>
                {
                    Object.keys(AllPos).map((p, idx) => {
                        return (
                            <div>
                                {p}+'' +{AllPos[p]}
                            </div>    
                        )
                    })
                }
            </div>
        </div>
        </>
        
	)}
    else{
        return (
            <>
            <SearchBar/>
            <div className='Port_Layout'>
                
                {(ListAcc.length > 0) 
                && 
                <select value={Acc} onChange={ChangeAcc} className='Acc_Select'>
                    {ListAcc.map((p, idx) => { 
                        return(
                            <option className='Acc_Option'>
                                {p}
                            </option>
                        )
                    })}
                </select>}
                <Port_Navbar/>
                <h1>Loading</h1>
            </div>
        </>
        )
    }
	
};
*/
