import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../context/AppContext';
import {
    useHistory
  } from "react-router-dom"
import { FaMoneyBill } from "react-icons/fa";


import './Deposit.css'


const Deposit = () => {
	const [amount, setAmount] = useState()
    const [Acc, setAcc] = useState('All Accounts')
    const {user} = useContext(AppContext);
    const history = useHistory();

    const ClickDeposit = async (e) => {
        e.preventDefault() 
        if (amount != undefined && amount != ''){
            var addItem
            addItem = {
                activity: e.target.value.toLowerCase(),
                price: amount,
                account: Acc,
            };
            console.log(addItem)
            
            let response = await fetch(`http://193.31.27.5:8000/api/activity/${{user}['user']}/deposit`, {
            method: 'POST',
            'headers': {
                'Content-Type': 'application/json'
            },
            'body': JSON.stringify(addItem)
            })
            history.go(0)
        }
    }


	return (
            <div className='Deposit_Container'>
                <div className='Amount_Input'>
                    <input
                    placeholder='0'
                    value = {amount}
                    onChange = {(e) => setAmount(e.target.value)}/>
                </div>
                <div className='Deposit_Withdraw'>
                    <div className='Deposit'>
                        <button value={'Deposit'} onClick={ClickDeposit}>Deposit</button>
                    </div>
                    <div className='Withdraw'>
                        <button value={'Withdraw'} onClick={ClickDeposit}>Withdraw</button>
                    </div>
                </div>
            </div>
	);
};

export default Deposit;

/*



*/

