import React, { useContext, useEffect, useState } from 'react';
import { AppContext } from '../../context/AppContext';

const ViewBudget = (props) => {
	const { user, dispatch } = useContext(AppContext);
	const [userBudget, setUserBudget] = useState(0)

	useEffect(async () => {
		let response = await fetch(`http://127.0.0.1:8000/api/budgets/${user}`)
		let data = await response.json()
		setUserBudget(data.budget)
	})

	return (
		<>
			<span>Budget: {userBudget}</span>
			<button type='button' onClick={props.handleEditClick}>
				Edit
			</button>
		</>
	);
};

export default ViewBudget;