import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import React from 'react';

import photo_overview_1 from './photo_overview_1.png';
import photo_overview_2 from './photo_overview_2.png';
import photo_stock_1 from './photo_stock_1.png';
import photo_stock_2 from './photo_stock_2.png';
import photo_research_1 from './photo_research_1.png';
import video_1 from './video_1.mp4';
import video_2 from './video_2.mp4';
import video_3 from './video_3.mp4';
import video_4 from './video_4.mp4';


import './main.css'

const Main = () => {
    let history = useHistory();

    const ClickItem = () =>{
        window.clearInterval(window.asd)
        history.push(`/portfolio/overview`)
    }

    return (
        <div className='main_layout'>
            <h2 id='Objective'>Objective</h2>
            <p>
                This is a stock trading website, which allows users to buy and sell stocks, manage their portfolios,
                deposit and withdraw money, and access stock-related news.
                The website is equipped with a clean and user-friendly UI.
            </p>
            <p>
                The goal of this project is to learn and implement various aspects of computer science, 
                including front-end and back-end development, database management, and website hosting 
                using a virtual private server (VPS). Additionally, I want to enchance my skill in 
                data analytics, data engineering, and data science. 
            </p>
            <div className='main_button'>
                <button onClick={() => ClickItem()}>
                    Portfolio
                </button>
            </div>
            <h2 className='Technical'>
                Built with
            </h2>
            <ul>
                <li>React - front-end (HTML, CSS, JavaScript)</li>
                <li>Django - back-end (Python)</li>
                <li>MySQL - database</li>
                <li>VPS - website hosting</li>
            </ul>
            <h2 id='Feature'>
                Feature
            </h2>
            <h3 id='Portfolio'>
                Portfolio
            </h3>
            <ul>
                <li>Users can view the chart of their stock portfolio, with stock prices retrieved from the Yahoo Finance API.</li>
                <li>Depositing and withdrawing funds to/from the portfolio.</li>
                <li>Users can access lists of positions and watchlists.</li>
                <li>Detailed position information, such as total gain, cost basis, etc. is displayed at the bottom of the page.</li>
            </ul>
            <div className='photo_container'>
                <div className='photo_item'>
                    <img src={photo_overview_1} className='photo'/>
                </div>
                <div className='photo_item'>
                    <img src={photo_overview_2} className='photo'/>
                </div>
            </div>
            <h3 id='Stock'>
                Stock
            </h3>
            <ul>
                <li>Individual stock pages provide interactive charts for price visualization using Highcharts library.</li>
                <li>Users can buy stocks if they have sufficient funds, and the option to sell is available for owned stocks.</li>
                <li>The page includes a 'News' section, where news from sources like Google News, Finviz, and Financial Content is scraped,
                    cleaned, and displayed based on sentiment scores.</li>
                <li>The 'Chart' section allows users to interact with tradingview charts.</li>
            </ul>
            <div className='photo_container'>
                <div className='photo_item'>
                    <img src={photo_stock_1} className='photo'/>
                </div>
                <div className='photo_item'>
                    <img src={photo_stock_2}  className='photo'/>
                </div>
            </div>
            <h3 id='Research'>
                Research
            </h3>
            <ul>
                <li>Economic calendar data is scraped from MarketView and displayed on this page.</li>
                <li>News from the overall market is also showcased, sourced from the same websites mentioned above.</li>
            </ul>
            <div className='photo_container'>
                <div className='photo_item'>
                    <img src={photo_research_1} className='photo_2'/>
                </div>
            </div>
            <h3 id='Video'>
                Video
            </h3>
            <div className='video_container'>
                <div className='photo_item'>
                    <video controls className="video">
                        <source src={video_1} type="video/quicktime" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className='photo_item'>
                    <video controls className="video">
                        <source src={video_2} type="video/quicktime" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className='photo_item'>
                    <video controls className="video">
                        <source src={video_3} type="video/quicktime" />
                        Your browser does not support the video tag.
                    </video>
                </div>
                <div className='photo_item'>
                    <video controls className="video">
                        <source src={video_4} type="video/quicktime" />
                        Your browser does not support the video tag.
                    </video>
                </div>
            </div>
        </div>
    )
}

export default Main;

/*

<h2 id='Challenge'>
                Skill learning / Project Challenges
            </h2>
            <ul>
                <li>Learning and implemting a completely new technologies, such as front-end and cloud-based website hosting, expanded my skill set </li>
                <li>Debugging a complex and messy codebase enchanced my skill in identifying and resolving code issue. This is important, since I began coding this project as a beginner</li>
                <li>Scraping and cleaning data have a significant challenges. However it provided a valuable experience in data engineering and data anlysis to ensure the quality of the data for further analysis </li>
                <li>Creating a user-friendly UI developed a data visualization skill to present the information in an intuitive way</li>
            </ul>
            <h2 id='Improvement'>
                Room for Improvement
            </h2>
            <ul>
                <li>The CSS style only works for laptop/monitor screen ratio</li>
            </ul>

*/

/*
            <h3>
                Ask ChatGPT
            </h3>
            <ul>
                <li>Powered by GPT 3.5 API, it empowers users to access stock data, specifically focusing on stock price, news, and fundamental data.</li>
            </ul>
            */