import React, { useContext, useState, useEffect } from 'react';
import {
    useHistory
  } from "react-router-dom"
import { FaMoneyBill } from "react-icons/fa";


import './PosWL.css'


const PosWL = (props) => {
    var Watchlist = props.Watchlist
    var AllPos = props.AllPos
    let history = useHistory();

    const ClickItem = (p) =>{
        //console.log(p);
        window.clearInterval(window.asd)
        history.push(`/stock/${p}/overview`)
    }

	return (
            <div className='Box'>
                <div className='Box_Pos'>
                    <div className='Cash_Deposit'>
                        <div className='Cash'>
                            Cash : {props.Cash.toFixed(2)}
                        </div>
                    </div>
                    <div className = 'Pos'>
                        <div className='Pos_Name'>
                            Investments
                        </div>
                        <div className='Item_List'>
                            {
                            Object.keys(AllPos).map((p, idx) => {
                                if (AllPos[p]!= 0){
                                    return (
                                    <div className='Item'>
                                        <button onClick={() => ClickItem(p)} value={p} className='Item_Pos'>
                                            <div className='Item_Amount'> 
                                                {AllPos[p].toFixed(2)}  
                                            </div>
                                            <div>
                                                {p}
                                            </div>

                                        </button>
                                    </div>
                                )
                                }
                            })
                            }
                        </div>
                    </div> 
                </div>
                <div className='Box_WL'>
                    <div className='WL_Name'>
                        Watchlists
                    </div>
                    <div className='Item_List'>
                        {Watchlist.map((a, idx) => { 
                            return(
                                <div className='Item'>
                                    <button onClick={() => ClickItem(a.symbol)} value={a.symbol} className='Item_WL'>
                                        <div> 
                                            {a.symbol.toUpperCase()}
                                        </div>
                                    </button>
                                </div>
                            )
                        })}
                    </div>
                    
                </div>
            </div>
	);
};

export default PosWL;

/*



*/

