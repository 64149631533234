import React, { useContext , Component, useEffect} from 'react';
import { AppContext } from '../../context/AppContext';
import './Progress.css'

const Progress = () => {
	const { expenses, budget } = useContext(AppContext);

	const totalExpenses = expenses.reduce((total, item) => {
		return (total += item.cost);
	}, 0);

	const alertType = totalExpenses > budget ? 'alert-danger' : 'alert-success';

    useEffect(() => {
      },);

	return (
        <div className="Progress">
            <div className="percent">
                <svg strokeDashoffset={`${440 - (440 * ((totalExpenses)/budget * 100))/100}`}>
                    <circle cx="70" cy="70" r="70"></circle>
                    <circle cx="70" cy="70" r="70"
                    stroke='red'></circle>
                </svg>
            </div>
            <div className="number">
                {parseFloat((totalExpenses)/budget * 100).toFixed(1)}%
            </div>
        </div>
	);
};

export default Progress;