import React, { useContext, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import { v4 as uuidv4 } from 'uuid';
import { v1 as uuidv1 } from 'uuid';

const AddExpenseForm = (props) => {
	const { expenses, dispatch } = useContext(AppContext);

	const [name, setName] = useState('');
	const [cost, setCost] = useState('');
	const [tags, setTag] = useState('');
	const [date, setDate] = useState('');

	const onSubmit = async (event) => {
		const expense = {
			name,
			cost: parseInt(cost),
			tags,
			date
		};

		let response = await fetch(`http://127.0.0.1:8000/api/expense/create`, {
			method: 'POST',
			'headers': {
				'Content-Type': 'application/json'
			},
			'body': JSON.stringify(expense)
		})
		
		console.log(JSON.stringify(expense))
		let data = await response.json()
		console.log(data)

		dispatch({
			type: 'ADD_EXPENSE',
			payload: expense,
		});

		setName('');
		setCost('');
		setTag('');
		setDate('');
	};

	return (
		<form onSubmit={onSubmit}>
			<div className=''>
				<div className=''>
					<label htmlFor='name'>Name</label>
					<input
						required='required'
						type='text'
						className='form-control'
						id='name'
						value={name}
						onChange={(event) => setName(event.target.value)}
					/>
				</div>
				<div className=''>
					<label htmlFor='cost'>Cost</label>
					<input
						required='required'
						type='number'
						className='form-control'
						id='cost'
						value={cost}
						onChange={(event) => setCost(event.target.value)}
					/>
				</div>
				<div className=''>
					<label htmlFor='tags'>Tag</label>
					<input
						required='required'
						type='text'
						className='form-control'
						id='tags'
						value={tags}
						onChange={(event) => setTag(event.target.value)}
					/>
				</div>
				<div className=''>
					<label htmlFor='date'>Date</label>
					<input
						required='required'
						type='text'
						className='form-control'
						id='date'
						value={date}
						onChange={(event) => setDate(event.target.value)}
					/>
				</div>
			</div>
			<div className='row mt-3'>
				<div className='col-sm'>
					<button type='submit' className='btn btn-primary'>
						Save
					</button>
				</div>
			</div>
		</form>
	);
};

export default AddExpenseForm;