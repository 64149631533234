import React, { useContext, useEffect, useState, useRef } from 'react';
import { AppContext } from '../../context/AppContext';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    useLocation,
    useHistory
  } from "react-router-dom"
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

import Select from 'react-select'

import SearchBar from './SearchBar'
import './Education.css'



const Education = (props) => {
    return (
        <>
        <SearchBar/>
        <div class='Layout'>

        </div>
        </>
        
	)
	
};

export default Education;

//{symbol && <StockChart symbol={symbol}/>}
//{count && <ChartTest3 data={count}/>}
/*
{Object.keys(count)
            .map((item, idx) => {
                return(
                    <h5 key={idx}>{count[item]}</h5>
                )
            })}
            */

/*
<div className="SecondGraph">
                {<InvestmentList data = {AllData['highlight']}/>}
            </div>
            */

/*
if (count.length != 0 && ClosePrice.length!=0){return (
        <>
        <SearchBar/>
        <div className='Port_Layout'>
            <select value={Acc} onChange={ChangeAcc} className='Acc_Select'>
                {ListAcc.map((p, idx) => { 
                    return(
                        <option className='Acc_Option'>
                            {p}
                        </option>
                    )
                })}
            </select>
            <Port_Navbar/>
            <div className='Chart_BuySell'>
                <div>
                    <h2>{V}</h2>
                    {(P >= 0) ? 
                    <h4 style={{ color: 'green' }}>{R}(+{P}%)</h4>
                    :
                    <h4 style={{ color: 'red' }}>{R}({P}%)</h4>
                    }
                    <div className="Stock_TimeFrame">
                        <button onClick={onClick1d}>1d</button>
                        <button onClick={onClick1w}>1w</button>
                        <button onClick={onClick1m}>1m</button>
                        <button onClick={onClick1y}>1y</button>
                    </div>
                    {count && <ChartTest3 data={count} len={len} ClosePrice={ClosePrice}
                    setV={setV} setR={setR} setP={setP}/>}
                </div>
            </div>
            <div className='Watchlist'>
                <div>
                    Watchlist:
                </div>
                {Watchlist.map((p, idx) => { 
                    return(
                        <div>
                        {p.symbol}
                        </div>
                    )
                })}
                <div>
                    Position:
                </div>
                {
                    Object.keys(AllPos).map((p, idx) => {
                        return (
                            <div>
                                {p}+'' +{AllPos[p]}
                            </div>    
                        )
                    })
                }
            </div>
        </div>
        </>
        
	)}
    else{
        return (
            <>
            <SearchBar/>
            <div className='Port_Layout'>
                
                {(ListAcc.length > 0) 
                && 
                <select value={Acc} onChange={ChangeAcc} className='Acc_Select'>
                    {ListAcc.map((p, idx) => { 
                        return(
                            <option className='Acc_Option'>
                                {p}
                            </option>
                        )
                    })}
                </select>}
                <Port_Navbar/>
                <h1>Loading</h1>
            </div>
        </>
        )
    }
	
};
*/
