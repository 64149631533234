import React, { useState, useEffect, useRef} from 'react';

import {
    BrowserRouter as Router,
    Link,
    useParams,
    useLocation,
    useHistory
  } from "react-router-dom"

import './Answer.css'
import StockChart from './StockChart'
import FundBarChart from './FundBarChart';


const Answer = (props) => {
    const [input, setInput] = useState()
    const [result, setResult] = useState([]);
    const [AllData, setAllData] = useState("");
    const [MP, setMP] = useState();
    const Index = useRef(253)
    var [count, setCount] = useState([]);
    const [len, setLen] = useState([])
    const [ClosePrice, setClosePrice] = useState([])
    const [V, setV] = useState()
    const [R, setR] = useState()
    const [P, setP] = useState()
    const [type, setType] = useState()
    const [symbol, setSymbol] = useState()
    const [info, setInfo] = useState()

    const [BarXaxis, setBarXaxis] = useState([])
    const [BarYaxis, setBarYaxis] = useState([])


    useEffect( async () => {
        setInput(props.input);
        setCount([])
        setBarYaxis([])
    }, [props.input])

    useEffect( () => {
        getData();
    }, [input])

    let getData = async () => {
        let response = await fetch(`http://127.0.0.1:8000/api/ai/${props.input.replace(/ /g, "%20")}`)
        let dataStock = await response.json()
        setAllData(dataStock)
            
        if (dataStock['type'] == 'stock_chart'){
            var StockChart = dataStock['yearly']
            let StockPrice = dataStock['yearly']['Price']
            var data = []
            let index = Object.keys(StockPrice)
            var dataClose = []

            var index3 = Object.keys(StockPrice).length
            index3 = Object.keys(StockPrice)[index3 - 1]
            setMP(StockPrice[index3])

            for (var i = 0; i <= Index.current; i += 1){
                var index_2 = Object.keys(StockChart['Close'])
                dataClose.push(
                    StockChart['Close'][index_2[0]]
                )
                if (i==0){
                    data.push(
                        StockPrice[index[i]]
                    );
                }
                else{
                    if (StockPrice[index[i]]===undefined){
                        data.push(null)
                    }
                    else{
                        data.push(
                            StockPrice[index[i]]
                        );
                    }
                    
                }
            }
            setLen(index.length)
            setCount(data);
            setClosePrice(dataClose)
            setType(dataStock['type'])
            setSymbol(dataStock['symbol'])
        }
        
        if (dataStock['type'] == 'bar')
        {
            var y_axis = []
            for (var i = 0; i < dataStock['x_axis'].length; i += 1){
                y_axis.push({name: dataStock['x_axis'][i], y: dataStock['y_axis'][i], })
            }
            setBarXaxis(dataStock['x_axis'])
            setBarYaxis(y_axis)
            setType(dataStock['type'])
            setSymbol(dataStock['symbol'])
            setInfo(dataStock['name'])
        }
    }

    if (count.length != 0 && ClosePrice.length != 0 && len.length != 0 && type == 'stock_chart'){return (
        <div className='AI_Answer_AnswerBox'>
            <div className='AI_Answer_Head'>
                <h2>{symbol}</h2>
                <h2>{V}</h2>
            </div>
            <>
            {count && <StockChart data={count} len={len} ClosePrice={ClosePrice}
            setV={setV} setR={setR} setP={setP}/>}
            </>
            
        </div>
    )}
    if (BarYaxis.length != 0 && type == 'bar'){return (
        <div className='AI_Answer_AnswerBox'>
            <div className='AI_Answer_Head'>
                <h2>{symbol}</h2>
                <h2>{info}</h2>
            </div>
            <>
            {BarYaxis && <FundBarChart X_Axis={BarXaxis} Y_Axis={BarYaxis}/>}
            </>
        </div>
    )}
    else{
        return (
        <div className='AI_Answer_AnswerBox'>
            Loading ...
        </div>
        )
    }
    
};

export default Answer;