import React, { useContext, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import './SellStock.css'

const SellStock = (props) => {
	const [amount, setAmount] = useState()
    const [Type, setType] = useState('share')

	const ClickShare = (e) => {
        e.preventDefault()
        setType('share')
    }

    const ClickDollar = (e) => {
        e.preventDefault()
        setType('dollar')
    }


	return (
		<form>
            <div className='BuyStock_Box'>
                <div className='OrderLayout'>
                    <dl className='OrderForm'>
                        <dt>Type</dt>
                        <dd>
                            <div className='OrderType'>
                                <button onClick={ClickShare} style={{"backgroundColor":'rgb(200, 200, 200)'}}>Share</button>
                                <button onClick={ClickDollar}>Dollar</button>
                            </div>
                        </dd>
                        <dt>Amount</dt>
                        <dd>
                            <div className='OrderInput'>
                                <input
                                value = {amount}
                                placeholder="0"
                                onChange = {(e) => setAmount(e.target.value)}/>
                            </div>
                        </dd>
                        <dt>Market Price</dt>
                        <dd></dd>
                        <dd>{props.Price.toFixed(2)}</dd>
                        <dt>{(Type=='share')?'Cost':'Quantity'}</dt>
                        <dd></dd>
                        <dd>
                            {(Type=='share')
                            ?
                            (amount * props.Price).toFixed(2)
                            :
                            (amount/props.Price).toFixed(2)}
                        </dd>
                    </dl>
                </div>
                <div className='Order'>
                    <button>Sell</button>
                </div>
            </div>
        </form>
	);
};

export default SellStock;

/*
import React, { useContext, useState } from 'react';
import { AppContext } from '../../context/AppContext';
import './SellStock.css'

const SellStock = (props) => {
	const { porto, dispatch } = useContext(AppContext);

	const [sellDate, setSellDate] = useState('2022-02-07');
    const [amount, setAmount] = useState('');
    var [ID, setID] = useState(0)

	const onSubmit = async (event) => {
        
        console.log(JSON.stringify(stock))
        var ID = []
        for (var x=0;x<amount;x++){
            for (var item in porto) {

                if (porto[item]['symbol'] == props.symbol && porto[item]['sellDate'] == '' && ID.includes(porto[item]['id']) == false){
                    var stock = {
                        symbol: props.symbol,
                        buyDate: porto[item]['buyDate'],
                        sellDate,
                    };
                    ID.push(porto[item]['id'])
                    console.log(porto[item]['id'])
                    console.log(stock)

                    let response = await fetch(`http://localhost:8000/api/investment/${porto[item]['id']}/delete`, {
                    method: 'PUT',
                    'headers': {
                        'Content-Type': 'application/json'
                    },
                    'body': JSON.stringify(stock)
                    })
                    let data = await response.json()
                    console.log(data)
                    break
                }
            }
        }

        setAmount(0)
	};

	return (
		<form onSubmit={onSubmit}>
            <div className="SellStock_Box">
                <div className='SellStock_Input'>
                    <div className='SellStock_Input_Amount'>
                        <label htmlFor='name'>Amount</label>
                        <input
                            required='required'
                            type='number'
                            className='form-control'
                            id='amount'
                            value={amount}
                            onChange={(event) => setAmount(event.target.value)}
                        />
                    </div>
                    <div className='SellStock_Input_Date'>
                        <label htmlFor='cost'>Date</label>
                        <input
                            type='text'
                            className='form-control'
                            id='date'
                            value={sellDate}
                            onChange={(event) => setSellDate(event.target.value)}
                        />
                    </div>
                </div>
                <div className='SellStock_Button'>
                    <button type='submit' className='btn btn-primary'>
                            Sell
                    </button>
                </div>
            </div>
		</form>
	);
};

export default SellStock;
*/