import React, { useContext, useEffect, useState, useRef } from 'react';
import { AppContext } from '../../context/AppContext';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    useRouteMatch,
    useParams,
    useLocation,
    useHistory
  } from "react-router-dom"
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';

import './StockNavbar.css'


const StockNavbar = (props) => {
    const [symbol, setSymbol] = useState('')
    const history = useHistory();
    const location = useLocation();
    const [, forceUpdate] = useState();
    const { symbolURL } = useParams();
    const [result ,setResult] = useState('')
    const [isLoading, setLoading] = useState(true)

    useEffect( async () => {
        setSymbol(location.pathname.split('/')[2])
        if (symbol != ''){
            history.go(0)
        }
    }, [symbolURL])

    return (
        <div className="Stock_Navbar">
                <Link className="Navbar_Link" to={`/stock/${symbolURL}/overview`} style={{ textDecoration: 'none' }}>
                    Overview
                </Link>
                <Link className="Navbar_Link" to={`/stock/${symbolURL}/news`} style={{ textDecoration: 'none' }}>
                    News
                </Link>
                <Link className="Navbar_Link" to={`/stock/${symbolURL}/chart`} style={{ textDecoration: 'none' }}>
                    Chart
                </Link>
                
            </div>
    );
};

export default StockNavbar;

/*

<Link className="Navbar_Link" to={`/stock/${symbolURL}/fundamentals`} style={{ textDecoration: 'none' }}>
                    Fundamentals
                </Link>
                <Link className="Navbar_Link" to={`/stock/${symbolURL}/keystats`} style={{ textDecoration: 'none' }}>
                    Key Stats
                </Link>
                <Link className="Navbar_Link" to={`/stock/${symbolURL}/seasonality`} style={{ textDecoration: 'none' }}>
                    Seasonality
                </Link>
                <Link className="Navbar_Link" to={`/stock/${symbolURL}/comparison`} style={{ textDecoration: 'none' }}>
                    Comparison
                </Link>
                <Link className="Navbar_Link" to={`/stock/${symbolURL}/calender`} style={{ textDecoration: 'none' }}>
                    Calender
                </Link>
                <Link className="Navbar_Link" to={`/stock/${symbolURL}/profile`} style={{ textDecoration: 'none' }}>
                    Profile
                </Link>
                <Link className="Navbar_Link" to={`/stock/${symbolURL}/analyst`} style={{ textDecoration: 'none' }}>
                    Analysis
                </Link>

*/

