import React, { useState, useEffect} from 'react';
import { FiSearch } from "react-icons/fi";

import {
    BrowserRouter as Router,
    Link,
    useParams,
    useLocation,
    useHistory
  } from "react-router-dom"

import './Search.css'
import Answer from './Answer';

const Search = () => {
    const [input, setInput] = useState('')
    const [inputChild, setInputChild] = useState('')
    const [showAnswer, setShowAnswer] = useState(false);

    const _pressEnter = (event) => {
        if(event.key === 'Enter'){ 
            event.preventDefault()
            setShowAnswer(true)
            setInputChild(input)
        }
    }

    return(
        <div className='AI_Search_SearchBox'>
            <form className='Input'>
                <div className='Icon'><FiSearch/></div>
                <input
                    type='text'
                    placeholder='Ask me'
                    value={input}
                    onChange={(event) => setInput(event.target.value)}
                    onKeyDown={(event) => _pressEnter(event)}
                />
            </form>
            {(showAnswer == true) ? 
                <Answer input={inputChild}/>
                :
                <></>
            }
        </div>
    )
};

export default Search;