import React, { useContext, useState, useEffect } from 'react';

import './toc.css'

const TOC = () => {

    return (
        <div className='toc_layout'>
            
        </div>
    )
}

export default TOC;