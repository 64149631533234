import React, { useContext, useState, useEffect } from 'react';
import { AppContext } from '../../context/AppContext';
import {BsThreeDots} from "react-icons/bs";
import { v4 as uuidv4 } from 'uuid';
import { v1 as uuidv1 } from 'uuid';
import './BuySell.css'
import BuyStock from './BuyStock'
import SellStock from './SellStock'

const BuySell = (props) => {
	const { expenses, dispatch } = useContext(AppContext);

	const [buyDate, setBuyDate] = useState('');
    const [amount, setAmount] = useState('');
    const [type, setType] = useState('buy')

    const ClickBuy = (e) =>{
        e.preventDefault()
        setType('buy')
    }

    const ClickSell = (e) =>{
        e.preventDefault()
        setType('sell')
    }

    const ClickOption = (e) =>{
        e.preventDefault()
    }

	return (
            <div className='BuySell_Box'>
                <div className='BuySell_Header'>
                    <div className='Button'>
                        <button 
                        onClick={ClickBuy} 
                        style={{"borderBottom": (type == "buy") ? "2px solid black" : ''}}
                        >Buy</button>
                        {(Object.keys(props.Pos).includes(props.symbol.toUpperCase())) 
                        && 
                        <button 
                        onClick={ClickSell} 
                        style={{"borderBottom": (type == "sell") ? "2px solid black" : ''}}
                        >Sell</button>}
                    </div>
                    <div className='Option'>
                        <button disabled="true" onClick={ClickOption}><BsThreeDots/></button>
                    </div>
                </div>
                {(type == 'buy')
                ?
                <BuyStock Price={props.Price} Acc={props.Acc} symbol={props.symbol}/>
                :
                <SellStock Price={props.Price} Acc={props.Acc} symbol={props.symbol} AllAcc={props.AllAcc}/>
            }
            </div>
	);
};

export default BuySell;

